import { Component, ViewEncapsulation, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { GlobalState } from 'app/global.state';
import { FavoritePageStaffService } from 'app/shared/services/core/favorite-page-staff/favorite-page-staff.service';
import { BaseComponent } from 'app/shared/infrastructure';

@Component({
    selector: 'favorite-cmp',
    templateUrl: './favorite.html',
    styleUrls: ['./favorite.scss']
})

export class FavoriteComponent extends BaseComponent implements OnInit {
    busy: Subscription;
    favorites: any[] = [];
    isMenuCollapsed: boolean = false;
    constructor(
        private _favoritePageStaffService: FavoritePageStaffService,
        private _router: Router, 
        private _state: GlobalState,
        private _changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this._favoritePageStaffService.isOpen.subscribe(value => {
            if (value)
                this.get();
        });
    }
    
    ngAfterViewChecked(): void {
        this._changeDetectorRef.detectChanges();
    }

    get() {
        this.busy = this._favoritePageStaffService.get()
            .subscribe((data: any) => {
                this.favorites = data;
            },
                error => super.showError(error));
    }


    goto(item) {
        if (item.IsExternalLink) {
            this.openLinkInNewTab(item.Url);
        }
        else {
            this._router.navigateByUrl(item.Url);
            this.toggleMenu();
        }
    }

    toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
        return false;
    }

    onRemoveItem(item) {
        this.busy = this._favoritePageStaffService.delete(item.Id)
            .subscribe((data: any) => {
                this.showSuccess('Page has been removed successfully');
                this.get();
            },
                error => super.showError(error));
    }
}
