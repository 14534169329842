import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Log, User } from 'oidc-client';
import { AraxUser } from '../../models/auth/arax-user';
import { BaMenuService } from 'app/shared/services/baMenu/baMenu.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MockAuthService implements OnInit, OnDestroy {

    user: AraxUser = null;
    authHeaders: any;

    // An observable to fire when a user object changes
    // usually after signin/signout
    userChanged: Observable<AraxUser>;

    private userSubject: Subject<AraxUser> = new Subject<AraxUser>();

    constructor(private http: HttpClient, private menuService: BaMenuService) {
        // Attach logger only on dev
        Log.logger = console;
        Log.level = Log.ERROR;
        this.userChanged = this.userSubject.asObservable();
        this.setUser(this.mockUser);
    }

    mockUser: any = {
        id_token: '123456789',
        session_state: null,
        access_token: '123456789',
        token_type: 'bearer',
        scope: 'api',
        profile: {
            sub: '123456789',
            name: 'Captain Jack Sparrow',
            selected_facility: '1',
            staff_id: 70,
            permissions: []
        },
        expires_at: 123456789,
        state: null,
        toStorageString: null,
        expires_in: undefined,
        expired: false,
        scopes: ['api'],
    };


    ngOnInit() {


    }

    ngOnDestroy(): void {
        if (this.userSubject) {
            this.userSubject.complete();
        }
    }

    logout() {
        Log.warn("This is a mock, you can't log out");
    }

    signinSilentCallback() {
        Log.warn("this is a mock, you can't call signinSilentCallback");
    }

    signinSilent = (): Promise<boolean> => {
        return Promise.resolve(true);
    }

    signinRedirect() {
        Log.warn("this is a mock, you can't call signinRedirect");
    }

    private setUser(user?: User) {
        if (user) {
            this.setAuthHeaders(user);
            this.user = new AraxUser(user);
        } else {
            this.user = null;
            this.authHeaders = new Headers();
        }
        // send new user object to subscribers,like the one in BaseComponent
        this.userSubject.next(this.user);
        // since we're not streaming objects,it's a good practice to call complete
        this.userSubject.complete();
    }

    clearState() {
        Log.info('clearStateState success');
    }

    getUser() {
        const self = this;
        this.setUser(this.mockUser);
    }

    removeUser() {
        this.setUser();
        Log.info('user removed');

    }

    startSigninMainWindow() {
        Log.info('signinRedirect done');
    }

    signinRedirectCallback = (): Promise<boolean> => {
        const self = this;

        return new Promise((resolve) => {
            Log.info('signinRedirectCallback', '');
            window.history.replaceState({},
                window.document.title,
                window.location.origin + window.location.pathname);
            self.getUser();
            resolve(true);

        });
    }

    startSignoutMainWindow() {

        Log.info('signed out', '');

    }

    endSignoutMainWindow() {
        Log.info('signed out', '');
        this.setUser();
    }

    private setAuthHeaders(user: User) {
        this.authHeaders = new Headers();
        this.authHeaders.append('Authorization', `${user.token_type} ${user.access_token}`);
        this.authHeaders.append('Content-Type', 'application/json');
    }

    setRequestOptions(options?: any) {
        if (options) {
            if (!this.authHeaders) {
                this.setUser(this.mockUser);
            }
            const accessToken = this.authHeaders.values()[0].toString();
            options.headers.append(this.authHeaders.keys()[0], accessToken);
        } else {
            options = ({ headers: this.authHeaders, body: '' });
        }

        return options;
    }

    hasPermission = (permission: string): boolean => {
        return true;
    }
}

