import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { MedispanServiceApiConfiguration } from '../medispan-service-api-configuration';
import { Observable } from 'rxjs-compat/Observable';

import { Setting } from 'app/shared/models/medispan/setting';

@Injectable()
export class SettingService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getByGroupIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: MedispanServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.setting.url;
        this.getByGroupIdUrl = this.actionUrl + _configuration.setting.byGroupId;
    }

    getByGroupId = (groupId: number): Observable<Setting> => {
        const url = `${this.getByGroupIdUrl}?groupId=${groupId}`;
        return this._http.get<any>(url);
    }

    add(setting: Setting) {
        return this._http.post<any>(this.url, JSON.stringify(setting));
    }

    update(setting: Setting) {
        return this._http.put<any>(this.url, JSON.stringify(setting));
    }
}
