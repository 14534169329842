import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientInfectious } from 'app/shared/models/core/patient-infectious';

@Injectable()
export class PatientInfectiousService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byEncountersUrl: string;


    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientInfectious.url;
        this._byEncountersUrl = this.actionUrl + _configuration.patientInfectious.byEncounters;
    }

    getByEncounters = (encounterIds: string): Observable<PatientInfectious[]> => {
        return this._http.get<PatientInfectious[]>(`${this._byEncountersUrl}?encounterIds=${encounterIds}`);
    }
}