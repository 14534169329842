import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { Bank } from '../../../models/core/bank';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class BankService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;
    private _allUrl: string;
    private _activeUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.bank.url;
        this._allUrl = this.actionUrl + _configuration.bank.all;
        this._activeUrl = this.actionUrl + _configuration.bank.active;
    }

    getBanks = (): Observable<Bank[]> => {
        return this._http.get<any>(this._allUrl);
    }

    getActiveBanks = (): Observable<Bank[]> => {
        return this._http.get<any>(this._activeUrl);
    }

    insert = (data: Bank): Observable<Bank> => {
        return this._http.post<Bank>(this._url, data);
    }

    update = (data: Bank): Observable<Bank> => {
        return this._http.put<Bank>(this._url, data);
    }
    delete = (bankId: number): Observable<any> => {
        return this._http.delete(this._url + '?bank=' + bankId);
    }
}
