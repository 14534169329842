import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Staff } from 'app/shared/models/orders-and-billing/staff.model';
import { StaffType } from 'app/shared/models/control/enums/staff-type';
import { SalesPerson } from 'app/shared/models/core';
@Injectable()
export class MainStaffService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;
    private _getSalesPersonsUrl: string;
    private _doctorOnly: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.staff.url;
        this._getSalesPersonsUrl = this.actionUrl + _configuration.staff.getSalesPersonsUrl;
        this._doctorOnly = this.actionUrl + _configuration.staff.doctorOnly;
    }

    insert = (data: Staff): Observable<Staff> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: Staff): Observable<Staff> => {
        return this._http.put<any>(this._url, data);
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete<any>(`${this._url}?id=${id}`);
    }

    getByFilter = (isAdmissionAllowed: number, code: string, firstName: string, fatherName: string,
        lastName: string, apId: string, pageNo: number, rowNo: number, fullName: string = '', staffType: StaffType, cashApAccount: string): Observable<any> => {
        return this._http.get<any>(`${this._url}?isAdmissionAllowed=${isAdmissionAllowed}&&code=${code}&&firstName=${firstName}&&fatherName=${fatherName}&&lastName=${lastName}&&apId=${apId}&&pageNo=${pageNo}&&rowNo=${rowNo}&&fullName=${fullName}&&staffType=${staffType}&&cashApAccount=${cashApAccount}`);
    }

    getSalesPersons = (slpName: string): Observable<SalesPerson[]> => {
        return this._http.get<SalesPerson[]>(`${this._getSalesPersonsUrl}?slpName=${slpName}`);
    }

    getDoctorOnly = (filter): Observable<any> => {
        return this._http.get<any>(`${this._doctorOnly}?filter=${filter}`);
    }
}
