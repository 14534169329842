import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class TextResultsService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _changeIsCompleteStatus: string;
    private _changeIsReleaseStatus: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.textResult.url;
        this._changeIsCompleteStatus = this.actionUrl + _configuration.textResult.changeIsCompleteStatus;
        this._changeIsReleaseStatus = this.actionUrl + _configuration.textResult.changeIsReleaseStatus;
    }

    getBySearch = (orderId: number, specimenId: number, itemId: number): Observable<any> => {
        const url = `${this._url}?orderId=${orderId}&&specimenId=${specimenId}&&itemId=${itemId}`;
        return this._http.get<any>(url);
    }

    add = (data: any) => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    update = (id: number, data: any) => {
        const url = `${this._url}?id=${id}`;
        return this._http.put<any>(url, JSON.stringify(data));
    }

    changeIsCompleteStatus = (textResultId: number, isComplete: boolean): Observable<any> => {
        const url = `${this._changeIsCompleteStatus}?textResultId=${textResultId}&&isComplete=${isComplete}`;
        return this._http.put<any>(url, null);
    }

    changeIsReleaseStatus = (textResultId: number, isRelease: boolean): Observable<any> => {
        const url = `${this._changeIsReleaseStatus}?textResultId=${textResultId}&&isRelease=${isRelease}`;
        return this._http.put<any>(url, null);
    }
}
