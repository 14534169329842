import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CashBoxTransferHistory } from '../../../models/queryModel/cash-box-transfer-history';
import { FailedCashBoxTransfer } from '../../../models/queryModel/failed-cash-box-transfer';

@Injectable()
export class SearchCashBoxTransferService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.cashBoxTransfer.url;
    }

    getCashBoxTransferHistory = (page: number, rows: number, transactionDate?: Date, fromCashierId?: number, toCashierId?: number, fromFundId?: number, toFundId?: number, status?: number): Observable<CashBoxTransferHistory[]> => {
        const fCashierId = fromCashierId ? fromCashierId : null;
        const tCashierId = toCashierId ? toCashierId : null;
        const fFundId = fromFundId ? fromFundId : null;
        const tFundId = toFundId ? toFundId : null;
        const cstatus = status ? status : null;
        const isoTransactionDate = transactionDate ? transactionDate.toISOString() : null;


        const url = `${this.url}?transactionDate=${isoTransactionDate}&&fromCashierId=${fCashierId}&&toCashierId=${tCashierId}&&fromFundId=${fFundId}&&toFundId=${tFundId}&&status=${cstatus}&&page=${page}&&rows=${rows}`;

        return this._http.get<any>(url);
    }

    getFailedCashBoxTransfer = (page: number, rows: number, transactionDateFrom?: Date, transactionDateTo?: Date, fromCashierId?: number, toCashierId?: number, fromFundId?: number, toFundId?: number, sapErrorMsg?: string): Observable<FailedCashBoxTransfer[]> => {
        const fCashierId = fromCashierId ? fromCashierId : null;
        const tCashierId = toCashierId ? toCashierId : null;
        const fFundId = fromFundId ? fromFundId : null;
        const tFundId = toFundId ? toFundId : null;
        const isoTransactionDateFrom = transactionDateFrom ? transactionDateFrom.toISOString() : null;
        const isoTransactionDateTo = transactionDateTo ? transactionDateTo.toISOString() : null;
        sapErrorMsg = sapErrorMsg ? sapErrorMsg : '';

        const url = `${this.url}?transactionDateFrom=${isoTransactionDateFrom}&&transactionDateTo=${isoTransactionDateTo}&&fromCashierId=${fCashierId}&&toCashierId=${tCashierId}&&fromFundId=${fFundId}&&toFundId=${tFundId}&&sapErrorMsg=${sapErrorMsg}&&page=${page}&&rows=${rows}`;

        return this._http.get<any>(url);
    }
}
