import { Injectable } from '@angular/core';
import { SystemSetting } from 'app/shared/models/enum/system-setting';
import { DynamicForm } from 'app/shared/models/enum/dynamic-form';



@Injectable()
export class SystemSettingsService {

    private state;
    constructor() {
        this.state = JSON.parse(this.getDataSynchronous());
    }

    getAppConstantByName(input: SystemSetting) {
        return this.state[input.toString()];
    }

    getDynamicFormByName(input: DynamicForm) {
        const state = JSON.parse(this.getDynamicFormDataSynchronous())
        return state[input.toString()];
    }

    getDataSynchronous() {
        var request = new XMLHttpRequest();
        request.open('GET', 'assets/data/system.settings.json', false);  // `false` 

        request.send(null);
        return request.responseText;
    }

    getDynamicFormDataSynchronous() {
        var request = new XMLHttpRequest();
        request.open('GET', 'assets/data/dynamic.form.json', false);  // `false` 

        request.send(null);
        return request.responseText;
    }
}

