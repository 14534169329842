import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GlobalState } from './global.state';
import { BaThemePreloader, BaThemeSpinner, SignalrService } from './shared/services';
import { Notification } from 'app/shared/models/hubs/enums/notification';
import { PatientRelease } from 'app/shared/models/hubs/enums/patient-release';
import { BaseComponent } from 'app/shared/infrastructure';
import { LoaderService } from 'app/loader/_services/loader.service';
/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class App extends BaseComponent implements OnInit, AfterContentChecked {
    isMenuCollapsed: boolean = false;
    constructor(
        private _state: GlobalState,
        private ref: ChangeDetectorRef,
        public _loaderService: LoaderService,
        private _spinner: BaThemeSpinner,
        private _signalrService: SignalrService) {
        super();
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
    }
    ngAfterContentChecked() {
        this.ref.detectChanges();
    }
    public ngOnInit(): void {
        if (this.enableNotification()) {
            const notificationConfig = { subcribeToUser: true, notifyWhenReceived: true, notifyMessage: 'You have received a new notification' };
            this._signalrService.connect(Notification.Name, Notification.SubscribeToRoles, notificationConfig);
            this._signalrService.connect(PatientRelease.Name, PatientRelease.ReceiveReleaseResult);
        }
    }
    public ngAfterViewInit(): void {
        // hide spinner once all loaders are completed
        BaThemePreloader.load().then(() => {
            this._spinner.hide();
        });
    }
}