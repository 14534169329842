import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { MainFundDetailsViewModel } from 'app/shared/models/orders-and-billing/main-fund-details-view-model';
import { TransferRequest } from 'app/shared/models/orders-and-billing/transfer-request.model';
import { SapStatementTransaction } from 'app/shared/models/orders-and-billing/sap-statement-transaction.model';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { SettlementViewModel } from 'app/shared/models/orders-and-billing/settlement-view-model';
import { FilterStatementTransaction } from 'app/shared/models/orders-and-billing/filter-statement-transaction';
import { StatementCancelationInfo } from 'app/shared/models/orders-and-billing/statement-cancelation-info';
import { map } from 'rxjs/operators';
@Injectable()
export class StatementTransactionService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private downPayment: string;
    private payment: string;
    private refund: string;
    private _getStatementTransactions: string;
    private getFundStatementTransactionsUrl: string;
    private _downPaymentCancelation: string;
    private _discountCancelation: string;
    private _invoiceCancelation: string;
    private _mainFundTransactionDetails: string;
    private _mainFundReceiveTransaction: string;
    private getSapFailedIncomingPaymentsUrl: string;
    private retrySapFailedIncomingPaymentsUrl: string;
    private getSapFailedOutgoingPaymentsUrl: string;
    private retrySapFailedOutgoingPaymentsUrl: string;
    private retrySapFailedCancelDownPaymentsUrl: string;
    private retrySapFailedRefundPaymentsUrl: string;
    private settlePatientBalanceUrl: string;
    private _searchTransactionStatements: string;
    private _exportTransactionStatements: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.statementTransaction.url;
        this.downPayment = this.actionUrl + _configuration.statementTransaction.downPayment;
        this.payment = this.actionUrl + _configuration.statementTransaction.payment;
        this.refund = this.actionUrl + _configuration.statementTransaction.refund;
        this._getStatementTransactions = this.actionUrl + _configuration.statementTransaction.statementTransactions;
        this.getFundStatementTransactionsUrl = this.actionUrl + _configuration.statementTransaction.getFundStatementTransactionsUrl;
        this._downPaymentCancelation = this.actionUrl + _configuration.statementTransaction.downPaymentCancelation;
        this._discountCancelation = this.actionUrl + _configuration.statementTransaction.discountCancelation;
        this._invoiceCancelation = this.actionUrl + _configuration.statementTransaction.invoiceCancelation;
        this._mainFundTransactionDetails = this.actionUrl + _configuration.statementTransaction.mainFundTransactionDetails;
        this._mainFundReceiveTransaction = this.actionUrl + _configuration.statementTransaction.mainFundReceiveTransaction;
        this.getSapFailedIncomingPaymentsUrl = this.actionUrl + _configuration.statementTransaction.getSapFailedIncomingPaymentsUrl;
        this.retrySapFailedIncomingPaymentsUrl = this.actionUrl + _configuration.statementTransaction.retrySapFailedIncomingPaymentsUrl;

        this.getSapFailedOutgoingPaymentsUrl = this.actionUrl + _configuration.statementTransaction.getSapFailedOutgoingPaymentsUrl;
        this.retrySapFailedOutgoingPaymentsUrl = this.actionUrl + _configuration.statementTransaction.retrySapFailedOutgoingPaymentsUrl;
        this.retrySapFailedCancelDownPaymentsUrl = this.actionUrl + _configuration.statementTransaction.retrySapFailedCancelDownPaymentsUrl;
        this.retrySapFailedRefundPaymentsUrl = this.actionUrl + _configuration.statementTransaction.retrySapFailedRefundPaymentsUrl;

        this.settlePatientBalanceUrl = this.actionUrl + _configuration.statementTransaction.settlePatientBalanceUrl;

        this._searchTransactionStatements = this.actionUrl + _configuration.statementTransaction.searchTransactionStatements;
        this._exportTransactionStatements = this.actionUrl + _configuration.statementTransaction.exportTransactionStatements;
    }

    cancelInvoice = (statementCancelationInfo: StatementCancelationInfo): Observable<any> => {
        return this._http.put<any>(this._invoiceCancelation, JSON.stringify(statementCancelationInfo))


    }

    cancelDiscount = (statementCancelationInfo: StatementCancelationInfo): Observable<any> => {
        return this._http.post<any>(this._discountCancelation, JSON.stringify(statementCancelationInfo))


    }

    mainFundReceiveTransaction = (fundId, statementTransactions, fromFundUserId, fromFundId, actualAmount, calculatedAmount): Observable<any> => {
        return this._http.post<any>(this._mainFundReceiveTransaction + '?fundId=' + fundId + '&&fromFundUserId=' + fromFundUserId + '&&fromFundId=' + fromFundId + '&&actualAmount=' + actualAmount + '&&calculatedAmount=' + calculatedAmount, statementTransactions)


    }

    cancelDownPayment = (statementCancelationInfo: StatementCancelationInfo): Observable<any> => {
        return this._http.post<any>(this._downPaymentCancelation, JSON.stringify(statementCancelationInfo))


    }

    getStatementTransactions = (patientStatementId): Observable<any> => {
        return this._http.get<any>(this._getStatementTransactions + '?patientStatementId=' + patientStatementId)


    }

    getFundStatementTransactions = (fundId: number, userId: number, facilityId: number): Observable<TransferRequest> => {
        const url = `${this.getFundStatementTransactionsUrl}?fundId=${fundId}&userId=${userId}&facilityId=${facilityId}`;
        return this._http.get<any>(url)


    }

    public postDownPayment = (patientStatementId, data): Observable<any> => {
        return this._http.post<any>(this.downPayment + '?patientStatementId=' + patientStatementId, data);
    }
    public postPayment = (patientStatementId, data): Observable<any> => {
        return this._http.post<any>(this.payment + '?patientStatementId=' + patientStatementId, data);
    }

    public postRefund = (patientStatementId, data): Observable<any> => {
        return this._http.post<any>(this.refund + '?patientStatementId=' + patientStatementId, data);
    }

    getMainFundTransactionDetails = (fundUserId, fundId, fromDate, toDate): Observable<MainFundDetailsViewModel[]> => {
        return this._http.get<any>(this._mainFundTransactionDetails + '?fundUserId=' + fundUserId + '&&fundId=' + fundId +
            '&&fromDate=' + fromDate + '&&toDate=' + toDate);
    }

    getSapFailedIncomingPayments = (transactionDateFrom: Date, transactionDateTo: Date): Observable<SapStatementTransaction[]> => {
        const url = `${this.getSapFailedIncomingPaymentsUrl}?transactionDateFrom=${transactionDateFrom.toISOString()}&&transactionDateTo=${transactionDateTo.toISOString()}`;
        return this._http.get<any>(url);
    }

    retrySapFailedIncomingPayments = (incomingPaymentsIds: number[]) => {
        return this._http.post<any>(this.retrySapFailedIncomingPaymentsUrl, JSON.stringify(incomingPaymentsIds));
    }

    getSapFailedOutgoingPayments = (transactionDateFrom: Date, transactionDateTo: Date): Observable<SapStatementTransaction[]> => {
        const url = `${this.getSapFailedOutgoingPaymentsUrl}?transactionDateFrom=${transactionDateFrom.toISOString()}&&transactionDateTo=${transactionDateTo.toISOString()}`;
        return this._http.get<any>(url);
    }

    retrySapFailedOutgoingPayments(failedRefundStatementTransactionsIds: number[],
        failedCancelDownPaymentsIds: number[]) {

        return forkJoin(this.retrySapFailedCancelDownPayments(failedCancelDownPaymentsIds),
            this.retrySapFailedRefundPayments(failedRefundStatementTransactionsIds));
    }

    retrySapFailedCancelDownPayments(statementTransactionsIds: number[]) {
        return this._http.post<any>(this.retrySapFailedCancelDownPaymentsUrl, JSON.stringify(statementTransactionsIds));
    }

    retrySapFailedRefundPayments(statementTransactionsIds: number[]) {
        return this._http.post<any>(this.retrySapFailedRefundPaymentsUrl, JSON.stringify(statementTransactionsIds));
    }

    settlePatientBalance(patientStatmentId: number, fundId: number, notes: string) {
        let settlementViewModel = new SettlementViewModel();
        settlementViewModel.patientStatementId = patientStatmentId;
        settlementViewModel.fundId = fundId;
        settlementViewModel.notes = notes;

        return this._http.post<any>(this.settlePatientBalanceUrl, JSON.stringify(settlementViewModel));
    }

    searchTransactionStatements = (_filter: FilterStatementTransaction): Observable<any> => {
        let queryString: string = '';

        queryString += '?Mrn=';
        queryString += _filter.Mrn;

        queryString += '&PatientName=';
        queryString += _filter.PatientName;

        queryString += '&TransactionDateFrom=';
        queryString += _filter.TransactionDateFrom ? _filter.TransactionDateFrom.toISOString() : null;

        queryString += '&TransactionDateTo=';
        queryString += _filter.TransactionDateTo ? _filter.TransactionDateTo.toISOString() : null;

        queryString += '&PaymentCodeId=';
        queryString += _filter.PaymentCodeId ? _filter.PaymentCodeId : null;

        queryString += '&TransactionById=';
        queryString += _filter.TransactionById ? _filter.TransactionById : null;

        queryString += '&page=';
        queryString += _filter.Page ? _filter.Page : 0;

        return this._http.get<any>(this._searchTransactionStatements + queryString)
    }

    exportTransactionStatements = (_filter: FilterStatementTransaction): Observable<any> => {

        let queryString: string = '';

        queryString += '?Mrn=';
        queryString += _filter.Mrn;

        queryString += '&PatientName=';
        queryString += _filter.PatientName;

        queryString += '&TransactionDateFrom=';
        // this guided by ala'a abu khalil
        queryString += _filter.TransactionDateFrom !== undefined ? _filter.TransactionDateFrom.toISOString() : _filter.TransactionDateFrom;

        queryString += '&TransactionDateTo=';
        // this guided by ala'a abu khalil
        queryString += _filter.TransactionDateTo !== undefined ? _filter.TransactionDateTo.toISOString() : _filter.TransactionDateTo;

        queryString += '&PaymentCodeId=';
        queryString += _filter.PaymentCodeId ? _filter.PaymentCodeId : null;

        queryString += '&TransactionById=';
        queryString += _filter.TransactionById ? _filter.TransactionById : null;

        queryString += '&page=';
        queryString += _filter.Page ? _filter.Page : 1;

        return this._http.get<any>(this._exportTransactionStatements + queryString,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }
}