import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class NormalResultService extends ServiceBase {

    private _actionUrl: string;
    private _labResultsUrl: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._labResultsUrl = this._actionUrl + _configuration.normalResult.getLabResults
    }

    getLabResults = (patientId: number, encounterId: number): Observable<any[]> => {

        const url = `${this._labResultsUrl}/?patientId=${patientId}&encounterId=${encounterId}`;
        return this._http.get<any>(url);
    }
}
