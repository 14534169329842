import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from 'app/shared/services/eventbus/message';

@Injectable()
export class BusService {

    private _newMessages: Subject<Message>;

    constructor() {
        this._newMessages = new Subject<Message>();
    }

    publishMessage(newMessage: Message): void {
        this._newMessages.next(newMessage);
    }

    subscribe(handler: ((newPrice: Message) => void)) {
        this._newMessages.subscribe(handler);
    }

    get Messages() {
        return this._newMessages.asObservable();
    }
}