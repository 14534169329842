import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PriceCopyListService extends ServiceBase {

    private actionUrl: string;
    private copyUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.copyUrl = this.actionUrl + configuration.priceCopyList.copy;
    }

    public copy(data: any) {
        return this.http.post<any>(this.copyUrl, JSON.stringify(data));
    }
}