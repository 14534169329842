export class PatientStatementFilter {

    InvoiceId?: number = null;
    PatientCode: string = "";
    PatientName: string = "";
    PatientTypeID: number;
    PayerID?: number;
    FromIssueDate: Date;
    ToIssueDate: Date;
    PageNo: number;
    RowNo: number;
    constructor() { }
}
