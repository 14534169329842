import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SystemSetting } from 'app/shared/models/enum/system-setting';
import { AppModule } from 'app/app.module';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';


@Injectable()
export class CoreApiConfiguration {

    private _systemSettingsService: SystemSettingsService;
    server: string;
    coreImagePath: string;
    dynamicFormPath: string;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.Core);
        this.coreImagePath = this._systemSettingsService.getAppConstantByName(SystemSetting.CoreImagePath);
        this.dynamicFormPath = this._systemSettingsService.getAppConstantByName(SystemSetting.DynamicFormPath);
    }

    facility =
        {
            url: 'Facility',
        };

    patient = {
        url: 'Patient',
        getPatientInformationFromStaffByStaffId: 'Patient/GetPatientInformationFromStaffByStaffId',
        getSapFailedPatientRegistrationsUrl: 'Patient/SapFailedPatientRegistrations',
        retrySapFailedPatientRegistrationsUrl: 'Patient/RetrySapFailedPatientRegistrations',
        activePatientBasicInfoByName: 'Patient/ActivePatientBasicInfoByName',
        allPatientBySearch: 'Patient/AllPatientBySearch',
        mergePatient: 'Patient/MergePatient',
        searchPatient: 'Patient/SearchPatient',
        searchByPatientQuery: 'Patient/SearchPatient',
        addPatient: 'Patient/AddPatient',
        addPatientShortcut: 'Patient/AddPatientShortcut',
        patientMoreInfo: 'Patient/PatientMoreInfo',
    };

    name = {
        url: 'Name',
    };

    doctorClinic = {
        url: 'DoctorClinic',
        doctorsLookUpByClinic: 'DoctorClinic/DoctorsLookUpByClinic',
        doctorsByClinic: 'DoctorClinic/DoctorsByClinic'
    };

    patientVisit = {
        url: 'PatientVisit',
        walkInVisit: 'PatientVisit/WalkInVisit',
        erVisit: 'PatientVisit/ErVisit',
        openedVisitsForSearch: 'PatientVisit/OpenedVisitsForSearch',
        patientVisitByDoctorId: 'PatientVisit/DoctorPatient',
        patientVisitByNurseId: 'PatientVisit/NurseSectionsPatient',
        listAllErVisitsOfPatient: 'PatientVisit/ListAllErVisitsOfPatient',
        visitStatusById: 'PatientVisit/VisitStatusById',
        patientVisitBySearch: 'PatientVisit/PatientVisitBySearch',
        reOpenPatientVisit: 'PatientVisit/ReOpenPatientVisit',
        getVisitDate: 'PatientVisit/VisitDate',
        dischargePatient: 'PatientVisit/DischargePatient',
        notifyPyxisOnPatientUpdate: 'PatientVisit/NotifyPyxisOnPatientUpdate',
        publishVisitHistory: 'PatientVisit/VisitHistory',
    };

    lookup =
        {
            getFacility: 'LookUp/GetFacility',
            getCity: 'LookUp/GetCity',
            getCountry: 'LookUp/GetCountry',
            getCityByCountryId: 'LookUp/getCityByCountryId',
            getNationality: 'LookUp/GetNationality',
            getDiagnose: 'LookUp/GetDiagnose',
            filterDiagnose: 'LookUp/filterDiagnose',
            getCodesByGroupKey: 'LookUp/GetCodesByGroupKey',
            getStaff: 'LookUp/GetStaff',
            getSectionByMedicalCategory: 'LookUp/GetSectionByMedicalCategory',
            getNurseSectionByMedicalCategory: 'LookUp/GetNurseSectionByMedicalCategory',
            getGetOrganizationSection: 'LookUp/GetOrganizationSection',
            getOrganizationSectionById: 'LookUp/GetOrganizationSectionById',
            getDoctorStaff: 'LookUp/GetDoctorStaff',
            getFilteredDoctorStaff: 'LookUp/GetFilteredDoctorStaff',
            getFilteredDoctorStaffWithCode: 'LookUp/GetFilteredDoctorStaffWithCode',
            getFilteredStaff: 'LookUp/GetFilteredStaff',
            getActiveFilteredStaff: 'LookUp/GetActiveFilteredStaff',
            getOutPatientClinicsSection: 'LookUp/GetOutPatientClinicsSection',
            getOutPatientClinicsSectionByLoggedInNurse: 'LookUp/GetOutPatientClinicsSectionByLoggedInNurse',
            url: 'LookUp',
            getItem: 'LookUp/GetItem',
            getFilteredItem: 'Lookup/GetFilteredItem',
            getFilteredLeafItem: 'Lookup/GetLeafItems',
            getAllMedicalCategorySection: 'LookUp/GetAllMedicalCategorySection',
            getParentItems: 'LookUp/GetParentItems',
            getPriceList: 'LookUp/PriceLists',
            getFilteredAdmissionDoctorStaff: 'LookUp/GetFilteredAdmissionDoctorStaff',
            getStaffById: 'LookUp/GetStaffById',
            sectionForEncounter: 'LookUp/SectionForEncounter',
            getAllParentAndLeafMedicalCategorySections: 'LookUp/GetAllParentAndLeafMedicalCategorySections',
            getAllMedicalCategoryUserSections: 'LookUp/GetAllMedicalCategoryUserSections',
            getCashierStaff: 'LookUp/CashierStaff',
            getStaffExcludedRegistered: 'LookUp/StaffExcludedRegistered',
            getAllActiveStaffInfo: 'LookUp/GetAllActiveStaffInfo',
            getAllActiveNurses: 'Lookup/GetAllActiveNurses',
            getStaffsByStaffIds: 'Lookup/GetStaffsByStaffIds',
            getActiveMedicalCategorySection: 'Lookup/GetActiveMedicalCategorySection',
        };

    diagnose = {
        url: 'Diagnose',
        byTitle: 'Diagnose/ByTitle',
        bySearch: 'Diagnose/BySearch',
        bySearchWithLeafAndParent: 'Diagnose/BySearchWithLeafAndParent',

    };

    code = {
        getCodesByGroupKey: 'Code/GetCodesByGroupKey',
        getCodeGroups: 'Code/GetCodeGroups',
        url: 'Code',
        getActiveCodesByGroupKey: 'Code/GetActiveCodesByGroupKey',
    };

    patientEncounterProblem = {
        get: '/ProblemList',
        getActiveProblems: '/GetActiveProblems',
        getById: '/ById',
        url: 'PatientEncounterProblem',
    };

    medicalCategory = {
        url: 'MedicalCategory',

    };

    patientDocument = {
        url: 'PatientDocument',
    };

    patientKin = {
        url: 'PatientKin',
    };

    fileRequest = {
        url: 'FileRequest',
    };

    allergy = {
        url: 'Allergy',
    };

    store = {
        url: 'Store',
        getLookupUrl: 'Store/Lookup',
        getLookupByIdUrl: 'Store/LookupById',
    };

    OganizationSection = {
        url: 'OrganizationSection',
        all: 'OrganizationSection/All',
        inPatientSections: 'OrganizationSection/InPatientSections',
    };

    pblSetup = {
        url: 'PBLSetup',
    };

    allergyType = {
        url: 'AllergyType',
    };

    patientAllergy = {
        url: 'PatientAllergy',
        byPatientId: 'PatientAllergy/ByPatientId',
        activeByPatientId: 'PatientAllergy/ActiveByPatientId',
    };

    patientDiagnosis = {
        url: 'PatientDiagnosis',
        byPatientEncounter: 'PatientDiagnosis/ByPatientEncounter',
        allByPatientEncounter: 'PatientDiagnosis/AllByPatientEncounter',
    };

    erArea =
        {
            url: 'ErArea',
        };

    staff = {
        url: 'Staff',
        getCashiersUrl: 'Staff/Cashiers',
        getByQueryUrl: 'Staff/ByQuery',
        getSalesPersonsUrl: 'Staff/SalesPersons',
        doctorOnly: 'Staff/doctorOnly',
    };

    facilityUnit =
        {
            url: 'FacilityUnit',
            getFacilityUnitNurses: 'FacilityUnit/FacilityUnitNurses',
            getFacilityUnitSections: 'FacilityUnit/FacilityUnitSections',
            getAllNursesSections: 'FacilityUnit/GetAllNurseSections',
            insertFacilityUnitSection: 'FacilityUnit/FacilityUnitSection',
            updateFacilityUnitSection: 'FacilityUnit/FacilityUnitSection',
            insertFacilityUnitNurse: 'FacilityUnit/FacilityUnitNurse',
            updateFacilityUnitNurse: 'FacilityUnit/FacilityUnitNurse',
        };

    userSection = {
        url: 'UserSection',
        getByStaffId: 'UserSection/GetByStaffId',
    };

    diseaseCode =
        {
            url: 'DiseaseCode',
            Bytitle: 'DiseaseCode/DiseaseCodeByTitle',
        };

    speciality =
        {
            url: 'speciality',
            getAll: 'speciality/GetAll',
            getAllActive: 'speciality/GetAllActive',
        };

    patientInfectious =
    {
        url: 'PatientInfectious',
        byEncounters: 'PatientInfectious/ByEncounters',
    };

    bank =
        {
            url: 'Bank',
            getByBankId: 'Bank/GetById',
        };

    report = {
        url: 'Report',
        prefixes: 'Report/all',

    };

    favoritePageStaff = {
        url: 'FavoritePageStaff',
    };
    timelineHistory = {
        historyInformation: 'Patient/HistoryInfo',
        visitPatientHistory: 'PatientVisit/PatientHistory',
        admissionPatientHistory: 'Admission/PatientHistory',
        patientEncounterProblem: 'PatientEncounterProblem/ProblemListDetails'

    };

    patientVitalSign = {
        url: 'PatientVitalSign',
        latestThreeByEncounter: 'PatientVitalSign/LatestThreeByEncounter',
        patientVitalSignInfo: 'PatientVitalSign/PatientVitalSignInfo',
    }

    patientRiskFactor = {
        url: 'patientRiskFactor',
    }

    carePlanShortcut = {
        url: 'carePlanShortcut',
        search: 'carePlanShortcut/search',
        getAllActive: 'carePlanShortcut/getAllActive',
    }

    patientDiet = {
        url: 'PatientDiet',
    }

    systemAllergy = {
        url: 'SystemAllergy',
        getBySearch: 'SystemAllergy/BySearch',
    }

    medicationFileSetup = {
        url: 'CloseMedicalFileSetup',
    };   

    executeQuery = {
        url: 'ExecuteQuery',
    };   

    notificationRole = {
        url: 'NotificationRole',
    };

}
