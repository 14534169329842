import { Injectable } from '@angular/core'
import { Subject } from 'rxjs';


@Injectable()
export class CountService {
    public unreadCount: Subject<number> = new Subject<number>();

    constructor() {
    }
    
}

