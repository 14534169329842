import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PharmacyDailyWorkSearch } from 'app/shared/models/queryModel/pharmacy-daily-work-search';
import { PharmacyDailyWork } from 'app/shared/models/queryModel/pharmacy-daily-work';

@Injectable()
export class PharmacyOrderSearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.pharmacyOrderSearch.url;
    }

    public getPharmacyDailyWork = (pharmacyDailyWorkSearch: PharmacyDailyWorkSearch, page: number, rows: number): Observable<PharmacyDailyWork[]> => {
        const patientId = pharmacyDailyWorkSearch.PatientId ? pharmacyDailyWorkSearch.PatientId : null;
        const patientCode = pharmacyDailyWorkSearch.PatientCode ? pharmacyDailyWorkSearch.PatientCode : '';
        const drugId = pharmacyDailyWorkSearch.DrugId ? pharmacyDailyWorkSearch.DrugId : null;
        var fromOrderDateAsString = pharmacyDailyWorkSearch.FromOrderDate ? pharmacyDailyWorkSearch.FromOrderDate.toISOString() : null;
        var toOrderDateAsString = pharmacyDailyWorkSearch.ToOrderDate ? pharmacyDailyWorkSearch.ToOrderDate.toISOString() : null;
        const orderActionStatusId = pharmacyDailyWorkSearch.OrderActionStatusId ? pharmacyDailyWorkSearch.OrderActionStatusId : null;
        const pharmacyId = pharmacyDailyWorkSearch.PharmacyId ? pharmacyDailyWorkSearch.PharmacyId : null;
        const orderById = pharmacyDailyWorkSearch.OrderById ? pharmacyDailyWorkSearch.OrderById : null;
        const orderSectionId = pharmacyDailyWorkSearch.OrderSectionId ? pharmacyDailyWorkSearch.OrderSectionId : null;
        const unitId = pharmacyDailyWorkSearch.UnitId ? pharmacyDailyWorkSearch.UnitId : null;
        const quantity = pharmacyDailyWorkSearch.Quantity ? pharmacyDailyWorkSearch.Quantity : null;
        const returnedQuantity = pharmacyDailyWorkSearch.ReturnedQuantity ? pharmacyDailyWorkSearch.ReturnedQuantity : null;
        const returnedBy = pharmacyDailyWorkSearch.ReturnedBy ? pharmacyDailyWorkSearch.ReturnedBy : null;
        const orderId = pharmacyDailyWorkSearch.OrderId ? pharmacyDailyWorkSearch.OrderId : (pharmacyDailyWorkSearch.OrderId == 0 ? 0 : null);

        return this._http.get<any>(this.url
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&DrugId=' + drugId
            + '&&FromOrderDate=' + fromOrderDateAsString
            + '&&ToOrderDate=' + toOrderDateAsString
            + '&&OrderActionStatusId=' + orderActionStatusId
            + '&&PharmacyId=' + pharmacyId
            + '&&OrderById=' + orderById
            + '&&OrderSectionId=' + orderSectionId
            + '&&UnitId=' + unitId
            + '&&Quantity=' + quantity
            + '&&ReturnedQuantity=' + returnedQuantity
            + '&&ReturnedBy=' + returnedBy
            + '&&OrderId=' + orderId
            + '&&Page=' + page
            + '&&Rows=' + rows
        );
    }
}
