import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { CoreApiConfiguration } from '../coreApiConfiguration';

@Injectable()

export class ExecuteQueryService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.executeQuery.url;
    }
    
    executeQuery = (query:string , filter:any): Observable<any> => {
        return this._http.post<any>(this._url , filter);
    }
}
