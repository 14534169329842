import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { Patient } from '../../../models/core/patient.model';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { SapPatient } from 'app/shared/models/core/sap-patient';
import { PatientFilter } from 'app/shared/models/core/patient-filter';
import { PatientBasicInfo } from 'app/shared/models/core/patient-basic-info';
import { MobileAccountAvailability } from 'app/shared/models/core/MobileAccountAvailability'

import { PatientInfoDto } from 'app/shared/models/core/patient-info-dto';

@Injectable()
export class PatientService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getPatientInformationFromStaffByStaffId: string;
    private getSapFailedPatientRegistrationsUrl: string;
    private retrySapFailedPatientRegistrationsUrl: string;
    private activePatientBasicInfoByNameUrl: string;
    private allPatientBySearchUrl: string;
    private mergePatientUrl: string;
    private searchPatientUrl: string;
    private _addPatient: string;
    private _addPatientShortcut: string;
    private _patientMoreInfoUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patient.url;
        this._getPatientInformationFromStaffByStaffId = this.actionUrl + _configuration.patient.getPatientInformationFromStaffByStaffId;
        this.getSapFailedPatientRegistrationsUrl =
            this.actionUrl + _configuration.patient.getSapFailedPatientRegistrationsUrl;
        this.retrySapFailedPatientRegistrationsUrl =
            this.actionUrl + _configuration.patient.retrySapFailedPatientRegistrationsUrl;

        this.activePatientBasicInfoByNameUrl = this.actionUrl + _configuration.patient.activePatientBasicInfoByName;
        this.allPatientBySearchUrl = this.actionUrl + _configuration.patient.allPatientBySearch;
        this.mergePatientUrl = this.actionUrl + _configuration.patient.mergePatient;
        this.searchPatientUrl = this.actionUrl + _configuration.patient.searchPatient;

        this._addPatient = this.actionUrl + _configuration.patient.addPatient;
        this._addPatientShortcut = this.actionUrl + _configuration.patient.addPatientShortcut;

        this._patientMoreInfoUrl = this.actionUrl + _configuration.patient.patientMoreInfo;
    }

    getById = (patientId: number): Observable<Patient> => {
        return this._http.get<any>(this._url + '?id=' + patientId);
    }

    HasMobileAccount = (patientId: number): Observable<MobileAccountAvailability> => {

        return this._http.get<any>(`${this._url}/HasMobileAccount?patientId=${patientId}`);
    }

    createMobileAccount = (patientId: number): Observable<any> => {
        return this._http.post<any>(`${this._url}/mobileaccount?patientid=${patientId}`, "");
    }
    confirmMobileAccount = (patientId: number): Observable<any> => {
        return this._http.post<any>(`${this._url}/MobileAccountConfirmation?patientid=${patientId}`, "");
    }
    insert = (data: Patient): Observable<any> => {
        return this._http.post<any>(this._addPatient, data);
    }

    CheckIsBlocked = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/CheckIsBlocked?patientId=' + patientId);
    }

    update = (data: Patient): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    getPatientInformationFromStaffByStaffId = (staffId: number): Observable<Patient> => {
        return this._http.get<any>(this._getPatientInformationFromStaffByStaffId + '?staffId=' + staffId);
    }

    getPatientForPayerValidList = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    getDuplicationPatientTelephoneNumber = (telephoneNumber: string, patientId?: number): Observable<Patient[]> => {
        return this._http.get<any>(this._url + '?patientId=' + patientId + '&telephoneNumber=' + telephoneNumber);
    }

    getPatientByCode = (patientCode: string): Observable<Patient> => {
        return this._http.get<any>(this._url + '?patientCode=' + patientCode);
    }

    patientBasicInfo = (name?: string, mrn?: string): Observable<any> => {
        return this._http.get<any>(this._url + '/GetPatientBasicInfo?name=' + name + '&mrn=' + mrn);
    }

    // get count patients
    getTotalCountPatients = (): Observable<any> => {
        return this._http.get<any>(this._url + '/TotalPatients');
    }

    getSapFailedPatientRegistrations = (transactionDateFrom: Date, transactionDateTo: Date): Observable<SapPatient[]> => {
        const url = `${this.getSapFailedPatientRegistrationsUrl}?transactionDateFrom=${transactionDateFrom.toISOString()}&&transactionDateTo=${transactionDateTo.toISOString()}`;
        return this._http.get<any>(url);
    }

    retrySapFailedPatientRegistrations = (patientsIds: number[]): Observable<any> => {
        return this._http.post<any>(this.retrySapFailedPatientRegistrationsUrl, JSON.stringify(patientsIds));
    }

    filterPatientBasicInfo = (patientQuery: string): Observable<any> => {
        return this._http.get<any>(this._url + '/FilterBasicInfo?patientQuery=' + patientQuery);
    }

    GetActivePatientBasicInfoByName = (patientName: string, exceptedPatientId?: number): Observable<PatientBasicInfo[]> => {
        return this._http.get<any>(this.activePatientBasicInfoByNameUrl + '?patientName=' + patientName + '&&exceptedPatientId=' + exceptedPatientId);
    }

    GetAllPatientBySearch = (patientFilter: PatientFilter, page: number, rows: number): Observable<Patient[]> => {
        const id = patientFilter.Id ? patientFilter.Id : null;
        const code = patientFilter.Code ? patientFilter.Code : '';
        const name = patientFilter.Name ? patientFilter.Name : '';
        const firstName = patientFilter.FirstName ? patientFilter.FirstName : '';
        const fatherName = patientFilter.FatherName ? patientFilter.FatherName : '';
        const lastName = patientFilter.LastName ? patientFilter.LastName : '';
        const otherFirstName = patientFilter.OtherFirstName ? patientFilter.OtherFirstName : '';
        const otherFatherName = patientFilter.OtherFatherName ? patientFilter.OtherFatherName : '';
        const otherLastName = patientFilter.OtherLastName ? patientFilter.OtherLastName : '';
        const birthDate = patientFilter.BirthDate ? patientFilter.BirthDate.toDateString() : null;
        const telCode = patientFilter.TelCode ? patientFilter.TelCode : '';
        const documentCode = patientFilter.DocumentCode ? patientFilter.DocumentCode : '';
        const status = patientFilter.Status ? patientFilter.Status : null;
        const exceptedPatientId = patientFilter.ExceptedPatientId ? patientFilter.ExceptedPatientId : null;

        return this._http.get<any>(this.allPatientBySearchUrl
            + '?Id=' + id
            + '&&Code=' + code
            + '&&Name=' + name
            + '&&FirstName=' + firstName
            + '&&FatherName=' + fatherName
            + '&&LastName=' + lastName
            + '&&OtherFirstName=' + otherFirstName
            + '&&OtherFatherName=' + otherFatherName
            + '&&OtherLastName=' + otherLastName
            + '&&BirthDate=' + birthDate
            + '&&TelCode=' + telCode
            + '&&DocumentCode=' + documentCode
            + '&&Status=' + status
            + '&&ExceptedPatientId=' + exceptedPatientId
            + '&&PageIndex=' + page
            + '&&PageSize=' + rows
        );
    }

    mergePatient = (toPatientId, fromPatienIds): Observable<any> => {
        return this._http.post<any>(this.mergePatientUrl + '?toPatientId=' + toPatientId, fromPatienIds);
    }

    searchPatient = (patientQuery: string): Observable<any> => {

        return this._http.get<any>(`${this.searchPatientUrl}?patientQuery=${patientQuery}`);
    }

    getPatientBySearch(query: string = null): Observable<Patient[]> {
        const url = `${this._url}/CoreInfo/Search?patientQuery=${query}`;
        return this._http.get<any>(url);
    }

    addPatientShortcut = (data: Patient): Observable<any> => {
        return this._http.post<any>(this._addPatientShortcut, data);
    }

    getPatientMoreInfo = (patientId: number): Observable<PatientInfoDto> => {
        return this._http.get<any>(`${this._patientMoreInfoUrl}?patientId=${patientId}`
        );
    }
}
