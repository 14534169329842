export class AdmissionSearchFilter {

    patientCode: string;
    patientName: string;
    nationalID: string;
    phoneNumber: string;
    admissionDate: Date;
    admissionDoctor: number;
    section: number;
    hasAdmission:boolean;
    constructor() { }
}
