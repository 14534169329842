import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class GroupTestsService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _isSingleTestDuplicatedWithinSameOrder: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.groupTest.url;
        this._isSingleTestDuplicatedWithinSameOrder = this.actionUrl + _configuration.groupTest.isSingleTestDuplicatedWithinSameOrder;
    }

    getByTestId = (id): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.get<any>(url)
    }

    add = (data): Observable<any> => {
        return this._http.post<any>(this._url, data)
           
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data)
           
    }

    delete(id: number) {
        const url = `${this._url}/${id}`;
        return this._http.delete(url)

           
    }

    getDuplicatedSingleTestWithinGroupTest = (data): Observable<any> => {
        return this._http.post<any>(this._isSingleTestDuplicatedWithinSameOrder, data)
           
    }

}
