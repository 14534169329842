import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { NormalResult } from '../../../../shared/models/lab/normalResult';
import { SpecimenTransactionNormalResultHistory } from '../../../models/lab/specimen-transaction-normal-result-history';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class PreviousResultService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.previousResult.url;
    }

    getPreviousResults = (patientId: number, itemId: number, resultType: number): Observable<any> => {
        const url = `${this._url}?patientId=${patientId}&itemId=${itemId}&resultType=${resultType}`;
        return this._http.get<any>(url);
    }
}