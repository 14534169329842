import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Item } from '../../../models/orders-and-billing/item';
import { ItemRelation } from '../../../models/orders-and-billing/item-relation';
import { PackageItemSearchFilter } from '../../../models/orders-and-billing/package-item-search-filter';

@Injectable()
export class PackageItemService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private suggestedPackageItemUrl: string;
    private packageItemBySearchUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.packageItem.url;
        this.suggestedPackageItemUrl = this.actionUrl + _configuration.packageItem.suggestedPackageItem;
        this.packageItemBySearchUrl = this.actionUrl + _configuration.packageItem.packageItemBySearch;
    }

    public getSuggestedPackageItem = (query: string, packageId: number): Observable<Item[]> => {
        return this._http.get<any>(this.suggestedPackageItemUrl
            + '?query=' + query
            + '&&packageId=' + packageId
        )
    }

    public getPackageItemBySearch = (packageItemSearchFilter: PackageItemSearchFilter): Observable<ItemRelation[]> => {
        packageItemSearchFilter.Code = packageItemSearchFilter.Code ? packageItemSearchFilter.Code : "";
        packageItemSearchFilter.Description = packageItemSearchFilter.Description ? packageItemSearchFilter.Description : "";
        packageItemSearchFilter.ItemLimit = packageItemSearchFilter.ItemLimit ? packageItemSearchFilter.ItemLimit : null;

        return this._http.get<any>(this.packageItemBySearchUrl
            + '?PackageId=' + packageItemSearchFilter.PackageId
            + '&&Code=' + packageItemSearchFilter.Code
            + '&&Description=' + packageItemSearchFilter.Description
            + '&&IsOrderable=' + packageItemSearchFilter.IsOrderable
            + '&&IsChargable=' + packageItemSearchFilter.IsChargable
            + '&&IsVariablePrice=' + packageItemSearchFilter.IsVariablePrice
            + '&&ItemLimit=' + packageItemSearchFilter.ItemLimit
        )
    }
}