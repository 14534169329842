export class PatientInfoDto {
    
    PatientId: number;
    Address: string;
    BloodGroupId?: number;
    BloodGroupTitle: string;
    BloodGroupForeignTitle: string;

    constructor() { }
}
