import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map'
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { SentExternalLaboratoryDocument } from 'app/shared/models/lab/sent-external-laboratory-document';
import { ServiceBase } from 'app/shared/infrastructure';
import { map } from 'rxjs/operators';

@Injectable()
export class SentExternalLaboratoryService extends ServiceBase {

    private actionUrl: string;
    private newOrderUrl: string;
    private resendOrderUrl: string;
    private cancelOrderUrl: string;
    private uploadDocumentUrl: string;
    private getDocumentsUrl: string;
    private receiveOrderUrl: string;
    private downloadDocumentUrl: string;

    constructor(private _http: HttpClient, private configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = configuration.server;
        this.newOrderUrl = this.actionUrl + configuration.sentExternalLaboratory.newOrderUrl;
        this.resendOrderUrl = this.actionUrl + configuration.sentExternalLaboratory.resendOrderUrl;
        this.cancelOrderUrl = this.actionUrl + configuration.sentExternalLaboratory.cancelOrderUrl;
        this.uploadDocumentUrl = this.actionUrl + configuration.sentExternalLaboratory.uploadDocumentUrl;
        this.getDocumentsUrl = this.actionUrl + configuration.sentExternalLaboratory.getDocumentsUrl;
        this.receiveOrderUrl = this.actionUrl + configuration.sentExternalLaboratory.receiveOrderUrl;
        this.downloadDocumentUrl = this.actionUrl + this.configuration.sentExternalLaboratory.downloadDocumentUrl;
    }

    newOrder = (specimenTransId: number, externalLabId: number, cost: number): Observable<any> => {
        const url = `${this.newOrderUrl}?specimenTransId=${specimenTransId}&&externalLabId=${externalLabId}&&cost=${cost}`;
        return this._http.post<any>(url, null);
    }

    resendOrder = (specimenTransId: number, externalLabId: number): Observable<any> => {
        const url = `${this.resendOrderUrl}?specimenTransId=${specimenTransId}&&externalLabId=${externalLabId}`;
        return this._http.post<any>(url, null);
    }

    cancelOrder(specimenTransId: number) {
        const url = `${this.cancelOrderUrl}?specimenTransId=${specimenTransId}`;
        return this._http.post<any>(url, null);
    }

    uploadDocument(sentExternalLaboratoryDocument: SentExternalLaboratoryDocument) {
        return this._http.post<any>(this.uploadDocumentUrl, sentExternalLaboratoryDocument);
    }

    downloadFile = (documentId: number): Observable<any> => {
        const url = `${this.downloadDocumentUrl}?documentId=${documentId}`;

        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    getDocuments = (specimenTransId: number): Observable<any> => {
        const url = `${this.getDocumentsUrl}?specimenTransId=${specimenTransId}`;
        return this._http.get<any>(url);
    }

    receiveOrder(specimenTransId: number, receiveDate: Date) {
        const receiveDateAsString = receiveDate.toISOString();
        const url = `${this.receiveOrderUrl}?specimenTransId=${specimenTransId}&&receiveDate=${receiveDateAsString}`;
        return this._http.post<any>(url, null);
    }
}
