import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class ErAreaService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.erArea.url;

    }

    get = (isActive): Observable<any> => {
        return this._http.get<any>(this._url + '?isActive=' + isActive);
    }

    getById = (id): Observable<any> => {
        return this._http.get<any>(this._url + '/' + id);
    }


    add(data: any) {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    update(id: number, data: any) {
        return this._http.put<any>(this._url + '/' + id, JSON.stringify(data));
    }
}
