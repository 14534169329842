import { Component } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { BaMenuService } from '../shared';

@Component({
    selector: 'pages',
    template: `
    <ba-sidebar></ba-sidebar>
    <ba-page-top *ngIf="!isHide"></ba-page-top>
    <ba-notification-favorite></ba-notification-favorite>
    <div class="al-main">
      <div class="al-content">
        <!--<ba-content-top></ba-content-top>-->
        <router-outlet></router-outlet>
      </div>
    </div>
    `
})
export class Pages {
    isHide: boolean = false;
    constructor(private _menuService: BaMenuService, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        //this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
    }

    checkRouteChildrenForURL(route: ActivatedRoute) {
        console.log('route: ', route);
        if (route != null) {
            if (route.firstChild != null) {
                route.firstChild.url.subscribe((values: UrlSegment[]) => {
                    if (values.filter(r => r.path == 'settlementDetail').length > 0) {
                        this.isHide = true;
                    }
                    else {
                        if (route.children.length > 0) {
                            this.checkRouteChildrenForURL(route.children[0]);
                        }
                    }
                }).unsubscribe();
            }
            else { this.isHide = false; }
        } else { this.isHide = false; }
    }
    hide() {
        this.activatedRoute.url.subscribe((values: UrlSegment[]) => {
            console.log('log1:', this.activatedRoute);
            if (values.filter(r => r.path == 'settlementDetail').length > 0) {
                this.isHide = true;
            }
            else {
                if (this.activatedRoute.children.length > 0) {
                    this.checkRouteChildrenForURL(this.activatedRoute.children[0]);
                }
            }
        });
        return this.isHide;
    }
}
