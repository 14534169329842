import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { PharmacyLookup } from '../../../models/pharmacy/pharmacy-lookup';
import { PharmacyControl } from '../../../models/pharmacy/pharmacy-control';
import { PharmacyCategories } from '../../../models/pharmacy/enums/pharmacy-categories';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class PharmacyLookupService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getByCategoryId: string;
    private _getById: string;
    private _action: string;
    private _lookUpByCategoryIdAndQuery: string;
    private getLookupUrl: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.lookup.url;
        this._getByCategoryId = this.actionUrl + _configuration.lookup.getByCategoryId;
        this._getById = this.actionUrl + _configuration.lookup.getById;
        this._lookUpByCategoryIdAndQuery = this.actionUrl + _configuration.lookup.lookUpByCategoryIdAndQuery;
        this.getLookupUrl = this.actionUrl + _configuration.lookup.getLookupUrl;
    }

    // get by category and filter by status /active-inative/
    getByCategoryId = (categoryId: number, isActive: boolean): Observable<PharmacyControl[]> => {
        return this._http.get<any>(this._getByCategoryId + '?categoryId=' + categoryId + '&&isActive=' + isActive);
    }

    // get by category and query
    lookUpByCategoryIdAndQuery = (medicationDefinitionId: number, categoryId: PharmacyCategories, query: string): Observable<PharmacyControl[]> => {
        const url = `${this._lookUpByCategoryIdAndQuery}?medicationDefintionId=${medicationDefinitionId}&categoryId=${categoryId}&query=${query}`;
        return this._http.get<any>(url);
    }

    getLookup = (medicationDefinitionId: number, categoryId: PharmacyCategories): Observable<PharmacyControl[]> => {
        const url = `${this.getLookupUrl}?medicationDefinitionId=${medicationDefinitionId}&categoryId=${categoryId}`;
        return this._http.get<any>(url)

    }

    getById = (id: number): Observable<PharmacyLookup> => {
        return this._http.get<any>(this._getById + '?id=' + id)

    }

    post = (data: PharmacyLookup): Observable<number> => {
        return this._http.post<number>(this._url, data);

    }

    update = (data: PharmacyLookup): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
