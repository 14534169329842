import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { PBLSetup } from '../../../models/core/pbl-setup';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class ReportService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;
    private _prefixes: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.report.url;
        this._prefixes = this.actionUrl + _configuration.report.prefixes;
    }

    get = (prefix: string): Observable<PBLSetup> => {
        const url = `${this._url}?prefix=${prefix}`
        return this._http.get<any>(url);
    }

    getAll = (prefixes: string): Observable<PBLSetup> => {
        const url = `${this._prefixes}?prefixes=${prefixes}`
        return this._http.get<any>(url);
    }
}
