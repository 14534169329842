<div id="menus">
    <div *ngFor="let item of menus" [ngClass]="{'sub-menu-child': (!item.isMain && !item.lastChild), 'main-item': (item.isMain) }" class="{{item.path + item.id}}">
        <div class="menu-header" [ngClass]="{'main-menu-item': (item.isMain)}" >
            <span class="menu-panel-title">
                <a id="{{item.path + item.id}}" (click)="goto($event, item)">
                    <i *ngIf="item.icon" class="{{ item.icon }} menu-icon"></i>
                    <span>{{ item.title | translate }}</span>
                    <i *ngIf="!item.lastChild" class="arow-item"></i>
                </a>
            </span>
        </div>
        <div class="collapsed {{item.path + item.id}}">
            <div class="menu-panel-body">
                <div *ngIf="item.children && !item.lastChild" class="menu-panel-child">
                    <ba-main-menu-item [menuChildren]="item.children"></ba-main-menu-item>
                </div>
            </div>
        </div>
    </div>
</div>