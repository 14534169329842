import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { forkJoin } from 'rxjs';

@Injectable()
export class PatientEncounterProblemService extends ServiceBase {

    private actionUrl: string;
    private _get: string;
    private _getActiveProblems: string;
    private _getById: string;
    private _getCodesByGroupKey: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientEncounterProblem.url;
        this._get = this._url + _configuration.patientEncounterProblem.get;
        this._getActiveProblems = this._url + _configuration.patientEncounterProblem.getActiveProblems;
        this._getById = this._url + _configuration.patientEncounterProblem.getById;
        this._getCodesByGroupKey = this.actionUrl + _configuration.code.getCodesByGroupKey;
    }

    getDataAndCode = (patientId, encounterId, key): Observable<any> => {
        const queryString: string = `?patientId=${patientId}&encounterId=${encounterId}`;

        const url1 = this._http.get<any>(this._get + queryString).map((res: Response) => res);
        const url2 = this._http.get<any>(`${this._getCodesByGroupKey}/?Key=${key}`).map((res: Response) => res);

        return forkJoin([url1, url2]);
    }

    getActiveDataAndCode = (patientId, encounterId, key): Observable<any> => {
        const queryString: string = `?patientId=${patientId}&encounterId=${encounterId}`;

        const url1 = this._http.get<any>(this._getActiveProblems + queryString).map((res: Response) => res);
        const url2 = this._http.get<any>(`${this._getCodesByGroupKey}/?Key=${key}`).map((res: Response) => res);

        return forkJoin([url1, url2]);
    }

    get = (patientId, encounterId, patientTypeId): Observable<any> => {
        let queryString: string = '';

        queryString += '?patientId=';
        queryString += patientId;

        queryString += '&encounterId=';
        queryString += encounterId;

        queryString += '&patientTypeId=';
        queryString += patientTypeId;

        queryString += '&searchTypeId=';

        return this._http.get<any>(this._get + queryString);
    }

    getActiveProblems = (patientId, encounterId): Observable<any> => {
        let queryString: string = '';

        queryString += '?patientId=';
        queryString += patientId;

        queryString += '&encounterId=';
        queryString += encounterId;
        return this._http.get<any>(this._getActiveProblems + queryString);
    }
    
    getById = (id): Observable<any> => {
        let queryString: string = '';

        queryString += '?id=';
        queryString += id;

        return this._http.get<any>(this._getById + queryString);
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
