export enum SystemSetting {
    Admission = 'Admission',
    Core = 'Core',
    Lab = 'Lab',
    Pharmacy = 'Pharmacy',
    Nurse = 'Nurse',
    QueryModel = 'QueryModel',
    Order = 'Order',
    AuthorityService = 'AuthorityService',
    RedirectURI = 'RedirectURI',
    SilentRedirectURI = 'SilentRedirectURI',
    PostLogoutRedirectURI = 'PostLogoutRedirectURI',
    ReportViewer = 'ReportViewer',
    CrystalReportViewer = 'CrystalReportViewer',
    ReportViewerWithParams = 'ReportViewerWithParams',
    SpecimenBarcodeReportViewer = 'SpecimenBarcodeReportViewer',
    NotificationURI = 'NotificationURI',
    SendNotificationURI = 'SendNotificationURI',
    WebSocketRetry = 'WebSocketRetry',
    AutoRefreshSeconds = 'AutoRefreshSeconds',
    CoreImagePath = 'CoreImagePath',
    DynamicFormViewer = 'DynamicFormViewer',
    DynamicFormPath = 'DynamicFormPath',
    NursingManagement = 'NursingManagement',
    OperationManagement = 'OperationManagement',
    Dimension = 'Dimension',
    DimensionAttachement = 'DimensionAttachement',
    DimensionLicenseID = 'DimensionLicenseID',
    DimensionUsername = 'DimensionUsername',
    DimensionPassword = 'DimensionPassword',
    EClaimIntegration = 'EClaimIntegration',
    DimensionUrl = 'DimensionUrl',
    Appointment = 'Appointment',
    AppointmentURI = 'AppointmentURI',
    MediSpanIntegration = 'MediSpanIntegration',
    MobileIntegrationEnabled = 'MobileIntegrationEnabled',
    MediSpanUrl = 'MediSpanUrl',
    CPOEManagement = 'CPOEManagement',
    QueryBuilder = 'QueryBuilder',
    SqlBuilderEngine = "SqlBuilderEngine",
    WorksheetTemplate = "WorksheetTemplate",
    Notification = "Notification",
    EnableNotification = "EnableNotification"
}
