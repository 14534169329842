<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)"
    [ngClass]="{scrolled: isScrolled}">
    <div class="multiLingual" *ngIf="!validateMobile()">
        <div class="dropdown">
            <button class="dropdown-button dropdown-toggle" data-toggle="dropdown">
                {{selectedLanguage}}
                <i class="fa fa-caret-down"></i>
            </button>
            <ul class="dropdown-menu">
                <li *ngFor="let lang of translate.getLangs()" (click)="setLanguage(lang)">
                    {{lang == 'en' ? 'English' : 'العربية'}}</li>
            </ul>
        </div>
    </div>

    <div class="mobile-multiLingual" *ngIf="validateMobile()">
        <div class="dropdown">
            <button class="dropdown-button dropdown-toggle" data-toggle="dropdown">
                <i class="fa fa-globe"></i>
            </button>
            <ul class="dropdown-menu">
                <li *ngFor="let lang of translate.getLangs()" (click)="setLanguage(lang)">
                    {{lang == 'en' ? 'English' : 'العربية'}}</li>
            </ul>
        </div>
    </div>

    <div class="brand-icon" *ngIf="!validateMobile()">
        <a routerLink='/pages/dashboard/'>
            <img [src]="setIcon('logoIcon') | baDomSanitizer" class="logo" />
        </a>
    </div>
    <div class="brand-icon" [ngClass]="{'mobile-width': validateMobile()}" *ngIf="validateMobile()">
        <a routerLink='/pages/dashboard/'>
            <img class="mobile-image" [src]="mobilePicture">
        </a>
    </div>
    <div (click)="toggleMenu()" id="sidebar-icon" *ngIf="!validateMobile()">
        <img [src]="setIcon('sidebar-icon') | baDomSanitizer" />
        <span></span>
        <span class="hamburger">
            <span></span>
            <span class="line"></span>
        </span>
    </div>

    <div (click)="toggleMenu()" class="mobile-sidebar-icon" *ngIf="validateMobile()">
        <a><i class="fa fa-bars"></i></a>
    </div>

</div>