export class PatientTransferSearchFilter {

    patientCode: string;
    patientName: string;
    admissionDoctor: number;
    section: number;
    room: number;

    constructor() { }
}
