<h3>Lab Results</h3>
<div class="form-group row form-group-default">
    <p-table #dtLabResults [value]="labResults" [rows]="5" [paginator]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th><span>{{'Test Name' | translate}}</span></th>
                <th><span>{{'Results' | translate}}</span></th>
                <th><span>{{'Release Date' | translate}}</span></th>
                <th><span>{{'Release By' | translate}}</span></th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td><span>{{rowData.TestName}}</span></td>
                <td><span>{{ rowData.Result}}</span></td>
                <td><span>{{ rowData.ReleaseDate | date: 'dd/MM/yyyy'}}</span></td>
                <td><span>{{rowData.StaffName}}</span></td>
            </tr>
        </ng-template>
    </p-table>
</div>