<form (ngSubmit)="onSubmit()">
    <div class="search-title">
        <span>{{ 'Patient Search' | translate }}</span>
    </div>
    <div class="row">
        <!--Search Element-->
        <div class="col-sm-8">
            <div class="form-horizontal">
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label">{{ 'Patient Code' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="patientCode" class="form-control" id="patientCode"
                            placeholder="{{ 'Patient Code' | translate }}"
                            [(ngModel)]="patientBasicSearchFilter.patientCode">
                    </div>
                    <label class="col-sm-2 form-control-label">{{ 'Patient Name' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="patientName" class="form-control" id="patientName"
                            placeholder="{{ 'Patient Name' | translate }}"
                            [(ngModel)]="patientBasicSearchFilter.patientName">
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label">{{ 'National ID' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="nationalID" class="form-control" id="nationalID"
                            placeholder="{{ 'National ID' | translate }}" maxlength="10"
                            [(ngModel)]="patientBasicSearchFilter.nationalID">
                    </div>
                    <label class="col-sm-2 form-control-label">{{ 'Phone Number' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="phoneNumber" class="form-control" id="phoneNumber"
                            placeholder="{{ 'Phone Number' | translate }}"
                            [(ngModel)]="patientBasicSearchFilter.phoneNumber">
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label">{{ 'Registration Date' | translate }}</label>
                    <div class="col-sm-10 absolute-calendar-icon">
                        <p-calendar name="admissionDate" dateFormat="dd/mm/yy"
                            placeholder="{{ 'Registration Date' | translate }}"
                            [(ngModel)]="patientBasicSearchFilter.registrationDate"></p-calendar>
                    </div>
                </div>
            </div>
        </div>
        <!--Other-->
        <div class="col-sm-4 text-center">
            <img src="{{ ( 'FingerPrint-transparent' | baSystemPicture ) }}" class="finger-print row-image-header">
            <div class="col-sm-12">
                <button type="submit" class="btn btn-outline-primary btn-block">{{ 'Search Button' | translate
                    }}</button>
            </div>
        </div>
    </div>
</form>