import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
@Injectable()
export class MockUpsService {

    public results: any;

    testCodes: SelectItem[];
    constructor(private _http: HttpClient, private _router: Router) {

        this.testCodes = [];
        this.testCodes.push({ label: 'CBC', value: 'CBC' });
        this.testCodes.push({ label: 'BA1', value: 'BA1' });
        this.testCodes.push({ label: 'BA2', value: 'BA2' });
        this.testCodes.push({ label: 'BE1', value: 'BE1' });
        this.testCodes.push({ label: 'WBC', value: 'WBC' });
        this.testCodes.push({ label: 'RBC', value: 'RBC' });
        this.testCodes.push({ label: 'BW0', value: 'BW0' });
        this.testCodes.push({ label: 'BW1', value: 'BX0' });
        this.testCodes.push({ label: 'C00', value: 'C00' });
        this.testCodes.push({ label: 'BE0', value: 'BE0' });
    }
}