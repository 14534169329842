import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class RoomService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getByClassOrBoth: string;
    private _getByClassAndSection: string;
    private _getRoomsAvailabilityByClass: string;
    private _getRoomAvailabilityBySection: string;
    private _getRoomsAvailability: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.room.url;
        this._getByClassOrBoth = this.actionUrl + _configuration.room.getByClassOrBoth;
        this._getByClassAndSection = this.actionUrl + _configuration.room.getByClassAndSection;
        this._getRoomsAvailabilityByClass = this.actionUrl + _configuration.room.RoomAvailabilityByClass;
        this._getRoomAvailabilityBySection = this.actionUrl + _configuration.room.RoomAvailabilityBySection;
    }

    public getAll = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    public getClassDetails = (genderId: string): Observable<any> => {
        return this._http.get<any>(this._url + '?genderId=' + genderId);
    }

    public getRoomBySection = (sectionId: number, genderId: number): Observable<any> => {
        return this._http.get<any>(this._url + '?sectionId=' + sectionId + '&&genderId=' + genderId);
    }

    public getRoomByClass = (classId: string, genderId: string): Observable<any> => {
        return this._http.get<any>(this._url + '?classId=' + classId + '&&genderId=' + genderId);
    }

    public getByClassAndBoth = (classId: number, sectionId: number, genderId: number): Observable<any> => {
        return this._http.get<any>(this._getByClassOrBoth + '?classId=' + classId + '&&sectionId=' + sectionId + '&&genderId=' + genderId);
    }

    public GetByClassAndSection = (classId: number, sectionId: number, genderId: number): Observable<any> => {
        return this._http.get<any>(this._getByClassAndSection + '?classId=' + classId + '&&sectionId=' + sectionId + '&&genderId=' + genderId);
    }

    public insert = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update = (data: any): Observable<any> => {

        return this._http.put<any>(this._url, JSON.stringify(data));

    }

    public getClassesRoomsAvailability = (): Observable<any> => {
        return this._http.get<any>(this._getRoomsAvailabilityByClass);
    }

    public getSectionsRoomAvailability = (): Observable<any> => {
        return this._http.get<any>(this._getRoomAvailabilityBySection);
    }
}