<div class="notification-hidden-icon dropdown">
    <button class="dropdown-button dropdown-toggle" data-toggle="dropdown">
        <i class="hm-icon zmdi zmdi-more-vert"></i>
    </button>
    <ul class="dropdown-menu dropdown-notification-icon">
        <li><a><i class="hm-icon zmdi zmdi-favorite"></i>{{ 'Favorites' | translate }}</a></li>
        <li><a><i class="hm-icon zmdi zmdi-notifications"></i>{{ 'Notifications' | translate }}</a></li>
    </ul>
</div>

<div class="dropdown top-page-dropdown" id="sql-builder-item" *ngIf="!validateMobile() && canViewSqlBuilder">
    <a (click)="onToggleSqlBuilder()">
        <i class="hm-icon fas fa-database"></i>
    </a>
</div>

<div class="dropdown top-page-dropdown" id="notification-item" *ngIf="!validateMobile()">
    <div class="p-relative">
        <a data-toggle="dropdown" aria-expanded="false">
            <small *ngIf="unReadNotificationCount > 0" id="count-notification">
                {{unReadNotificationCount}}
            </small>
            <i class="hm-icon icon zmdi zmdi-notifications"></i>
        </a>
        <div class="dropdown-menu p-0">        
                <notifications-cmp></notifications-cmp>
        </div>
    </div>
</div>

<div class="dropdown top-page-dropdown" id="favorite-item" *ngIf="!validateMobile()">
    <a data-toggle="dropdown" aria-expanded="false" (click)="openFavorite()">
        <i class="hm-icon zmdi zmdi-favorite"></i>
    </a>
    <div class="dropdown-menu">
        <div class="dropdown-content-header">
            <span>{{ 'Favorites' | translate }}</span>
        </div>
        <div class="dropdown-content">
            <favorite-cmp></favorite-cmp>
        </div>
    </div>
</div>


<!-- Mobile -->

<div class="dropdown top-page-dropdown" id="mobile-favorite-item" *ngIf="validateMobile()">
    <a data-toggle="dropdown" aria-expanded="false" (click)="openFavorite()">
        <i class="hm-icon zmdi zmdi-favorite"></i>
    </a>
    <div class="dropdown-menu">
        <div class="dropdown-content-header">
            <span>{{ 'Favorites' | translate }}</span>
        </div>
        <div class="dropdown-content">
            <favorite-cmp></favorite-cmp>
        </div>
    </div>
</div>

<div class="dropdown top-page-dropdown p-relative" id="mobile-notification-item" *ngIf="validateMobile()">
    <a data-toggle="dropdown" aria-expanded="false">
        <small [ngClass]="{'hide-count-notification': countNotification == 0}" id="count-notification">{{
            countNotification }}</small>
        <i class="hm-icon icon zmdi zmdi-notifications"></i>
    </a>
    <div class="dropdown-menu">
        <div class="dropdown-content-header">
            <span>{{ 'Notifications' | translate }}</span>
        </div>
        <div class="dropdown-content">
            <notifications-cmp></notifications-cmp>
        </div>
    </div>
</div>

<div class="dropdown top-page-dropdown" id="mobile-sql-builder-item" *ngIf="validateMobile() && canViewSqlBuilder">
    <a (click)="onToggleSqlBuilder()">
        <i class="hm-icon fas fa-database"></i>
    </a>
</div>

<!-- Mobile -->