<div class="home-expand" [style.height.px]="menuHeight + 40">

    <div class="user-Profile">
        <div id="user-image">
            <img [src]="setIcon('Avatar') | baDomSanitizer" />
        </div>
        <div class="user-name dropdown">
            <button class="dropdown-button dropdown-toggle" data-toggle="dropdown">
                <!--{{ 'UserAvatar' | translate }}-->
                {{user?.name}}
                <i class="fa fa-caret-down"></i>
            </button>
            <ul class="dropdown-menu user-menu">
                <li><a [routerLink]="['/pages/control/changePassword']"><i class="zmdi zmdi-key"></i>{{ 'ChangePassword'
                        | translate }}</a></li>
                <li class="dropdown-item"><a href='{{authorityServer()}}/account/logout' class="signout"><i
                            class="fa fa-power-off"></i>{{ 'SignOut' | translate }}</a></li>
            </ul>
        </div>
    </div>
    <ba-main-menu-item></ba-main-menu-item>
</div>