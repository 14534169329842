import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { PBLSetup } from '../../../models/core/pbl-setup';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PBLSetupService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.pblSetup.url;
    }

    getCurrentFacilityPBL = (): Observable<PBLSetup> => {
        return this._http.get<any>(this._url);
    }

    insert = (data: PBLSetup): Observable<any> => {
        data.IsAdmitUnknown = +data.IsAdmitUnknown;
        data.IsActiveMultiStatement = +data.IsActiveMultiStatement;
        data.AllowRefundForOpenAndHaltStatement = +data.AllowRefundForOpenAndHaltStatement;
        data.SendDrfeesToDrfeesFund = +data.SendDrfeesToDrfeesFund;
        return this._http.post<any>(this._url, data);
    }

    update = (data: PBLSetup): Observable<any> => {
        data.IsAdmitUnknown = +data.IsAdmitUnknown;
        data.IsActiveMultiStatement = +data.IsActiveMultiStatement;
        data.AllowRefundForOpenAndHaltStatement = +data.AllowRefundForOpenAndHaltStatement;
        data.SendDrfeesToDrfeesFund = +data.SendDrfeesToDrfeesFund;
        return this._http.put<any>(this._url, data);
    }
}
