export class VisitLookup {
    Id: number = 0;
    Desc: string = "";
   
    constructor(init?: VisitLookup) {
        for (const p in this) {
            this[p.toString()] = (p in init ? init : this)[p.toString()];
        }
        return this;
    }

}