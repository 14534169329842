import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { DoctorClinic } from './DoctorClinic';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class DoctorClinicService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _doctorsLookUpByClinic: string;
    private _doctorsByClinic: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.doctorClinic.url;
        this._doctorsLookUpByClinic = this.actionUrl + _configuration.doctorClinic.doctorsLookUpByClinic;
        this._doctorsByClinic = this.actionUrl + _configuration.doctorClinic.doctorsByClinic;
    }

    public getAll = (): Observable<DoctorClinic[]> => {
        return this._http.get<any>(this._url);
    }

    public getById = (id): Observable<DoctorClinic[]> => {
        return this._http.get<any>(this._url + '/?id=' + id);
    }

    public insert(data: DoctorClinic) {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update(data: DoctorClinic) {
        return this._http.put<any>(this._url, JSON.stringify(data));
    }

    public getAllDoctors = (): Observable<any> => {
        return this._http.get<any>(this._url + '?doctor=0&&id=0');
    }

    public getAllClinics = (): Observable<any> => {
        return this._http.get<any>(this._url + '?clinic=0&&id=0');
    }

    public getDoctorsBySectionId = (clinicId): Observable<any> => {
        return this._http.get<any>(this._url + '?clinicId=' + clinicId);
    }

    public getGoctorsLookUpByClinic = (clinicId): Observable<any> => {
        return this._http.get<any>(this._doctorsLookUpByClinic + '?clinicId=' + clinicId);
    }

    public getSearchDoctorsBySectionId = (clinicId, query): Observable<any> => {
        return this._http.get<any>(this._doctorsByClinic + '?clinicId=' + clinicId + '&query=' + query);
    }
}