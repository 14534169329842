import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { RevenueSectionDefinition } from '../../../models/orders-and-billing/revenue-section-definition';


@Injectable()
export class RevenueSectionDefinitionService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byCategoryAndOrderSectionUrl: string;
    private _pagedResultsUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.revenueSectionDefinition.url;
        this._byCategoryAndOrderSectionUrl = this.actionUrl + _configuration.revenueSectionDefinition.byCategoryAndOrderSection;
        this._pagedResultsUrl = this.actionUrl + _configuration.revenueSectionDefinition.pagedResults;
    }

    getPagedResult = (itemCategoryId: number, orderSectionId: number, revenueSectionCode: string,
        pageIndex: number, pageSize: number): Observable<any> => {
        return this._http.get<any>(`${this._pagedResultsUrl}?itemCategoryId=${itemCategoryId}&&orderSectionId=${orderSectionId}&&revenueSectionCode=${revenueSectionCode}&&pageIndex=${pageIndex}&&pageSize=${pageSize}`);
    }

    add = (data: RevenueSectionDefinition): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    update = (id: number, data: RevenueSectionDefinition): Observable<any> => {
        return this._http.put<any>(`${this._url}?id=${id}`, JSON.stringify(data));
    }
}
