import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { PriceListProfitMarginDetails } from 'app/shared/models/orders-and-billing/price-list-profit-margin-details';
@Injectable()
export class PriceListProfitMarginDetailService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private putUrl: string;
    private postUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.getUrl = this.actionUrl + configuration.profitMarginDetail.url;
        this.putUrl = this.actionUrl + configuration.profitMarginDetail.url;
        this.postUrl = this.actionUrl + configuration.profitMarginDetail.url;
    }


    public put(data: PriceListProfitMarginDetails) {
        return this.http.put<any>(this.putUrl, JSON.stringify(data));
    }

    public post(data: PriceListProfitMarginDetails) {
        return this.http.post<any>(this.postUrl, JSON.stringify(data));
    }
}