import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { PriceListProfitMargin } from 'app/shared/models/orders-and-billing/price-list-profit-margin';
@Injectable()
export class PriceListProfitMarginService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private putUrl: string;
    private postUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.getUrl = this.actionUrl + configuration.profitMargin.url;
        this.putUrl = this.actionUrl + configuration.profitMargin.url;
        this.postUrl = this.actionUrl + configuration.profitMargin.url;
    }

    get = (): Observable<PriceListProfitMargin[]> => {
        return this.http.get<any>(this.getUrl)
    }

    getWithDeatails = (): Observable<PriceListProfitMargin[]> => {
        return this.http.get<any>(this.getUrl + '/ProfitMarginDependencies')
    }

    public put(data: PriceListProfitMargin) {
        return this.http.put<any>(this.putUrl, JSON.stringify(data));
    }

    public post(data: PriceListProfitMargin) {
        return this.http.post<any>(this.postUrl, JSON.stringify(data));
    }
}