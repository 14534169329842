import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { CashierFund } from '../../models/orders-and-billing/cashier-fund';
@Injectable()
export class CashierFundsService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;
    private _getFundsForPermittedUser: string;
    private _getCashierStaffId: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.cahierfunds.url;
        this.postUrl = this.actionUrl + _configuration.cahierfunds.url;
        this.putUrl = this.actionUrl + _configuration.cahierfunds.url;
        this.deleteUrl = this.actionUrl + _configuration.cahierfunds.url;
        this._getFundsForPermittedUser = this.actionUrl + _configuration.cahierfunds.getFundsForPermittedUser;
        this._getCashierStaffId = this.actionUrl + _configuration.cahierfunds.getCashierStaffId;
    }

    get = (): Observable<CashierFund[]> => {
        return this._http.get<CashierFund[]>(this.getUrl);
    }

    getById = (id): Observable<any> => {
        return this._http.get<any>(this.getUrl + '/FundsById?id=' + id);
    }

    getFundsForPermittedUser = (): Observable<any> => {
        return this._http.get<any>(this._getFundsForPermittedUser);
    }

    post = (data: CashierFund): Observable<any> => {
        return this._http.post<any>(this.postUrl, JSON.stringify(data));
    }

    updated = (data: CashierFund): Observable<any> => {
        return this._http.put<any>(this.putUrl, JSON.stringify(data));
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete(this.deleteUrl + '/' + id);
    }

    getCashierStaffId = (): Observable<any> => {
        return this._http.get<any>(this._getCashierStaffId);
    }
}