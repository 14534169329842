import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

import { DoctorSettlementDetail } from 'app/shared/models/orders-and-billing/doctor-settlement-detail';

@Injectable()
export class DoctorSettlementDetailService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private byDoctorSettlementUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.doctorSettlement.url;
        this.byDoctorSettlementUrl = this.actionUrl + _configuration.doctorSettlementDetail.byDoctorSettlement;
    }

    getByDoctorSettlement = (doctorSettlementId: number): Observable<DoctorSettlementDetail[]> => {
        return this._http.get<any>(this.byDoctorSettlementUrl
            + '?doctorSettlementId=' + doctorSettlementId);
    }
}
