import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { PayerTemplate } from 'app/shared/models/orders-and-billing/payer-template';
import { OrderApiConfiguration } from '../order-api-configuration';

@Injectable()

export class PayerTemplateService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _allActiveUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.payerTemplate.url;

        this._allActiveUrl = this.actionUrl + _configuration.payerTemplate.getAllActivePayerTemplate;

    }

    update = (data: PayerTemplate): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    get = (): Observable<PayerTemplate[]> => {
        return this._http.get<PayerTemplate[]>(this._url);
    }       

    add = (data: PayerTemplate): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    getAllActive = (): Observable<PayerTemplate[]> => {
        return this._http.get<any>(this._allActiveUrl);
    }
}
