import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { SensitiveResult } from '../../../models/lab/sensitiveResult';
import { ServiceBase } from "app/shared/infrastructure";

@Injectable()
export class SensitiveResultService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _insertAndGetSensitiveResultsFromWorkbensh: string;
    private _changeIsCompleteStatus: string;
    private _changeIsReleaseStatus: string;
    private _getPhysicianCultureResult: string;
    private _getPhysicianCultureResultDetails: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._insertAndGetSensitiveResultsFromWorkbensh = this.actionUrl + _configuration.sensitiveResult.insertAndGetSensitiveResultsFromWorkbensh;
        this._changeIsCompleteStatus = this.actionUrl + _configuration.sensitiveResult.changeIsCompleteStatus;
        this._changeIsReleaseStatus = this.actionUrl + _configuration.sensitiveResult.changeIsReleaseStatus;
        this._url = this.actionUrl + _configuration.sensitiveResult.url;
        this._getPhysicianCultureResult = this.actionUrl + _configuration.sensitiveResult.getPhysicianCultureResult;
        this._getPhysicianCultureResultDetails = this.actionUrl + _configuration.sensitiveResult.getPhysicianCultureResultDetails;
    }

    insertAndGetSensitiveResultsFromWorkbensh = (orderId: number): Observable<SensitiveResult[]> => {
        const url = `${this._insertAndGetSensitiveResultsFromWorkbensh}?orderId=${orderId}`;
        return this._http.get<any>(url);
    }

    getSensitiveResults = (orderId: number, specimenNo: number, itemNo: number): Observable<SensitiveResult> => {
        const url = `${this._url}?orderId=${orderId}&&specimenNo=${specimenNo}&&itemNo=${itemNo}`;
        return this._http.get<any>(url);
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, JSON.stringify(data));
    }

    insert = (data: SensitiveResult): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    changeIsCompleteStatus = (sensitiveResultId: number, isComplete: boolean): Observable<any> => {
        const url = `${this._changeIsCompleteStatus}?sensitiveResultId=${sensitiveResultId}&&isComplete=${isComplete}`;
        return this._http.put<any>(url, null);
    }

    getPhysicianCultureResult = (encounterId, patientId, patientType, page, rows): Observable<any> => {
        let queryString: string = '';
        queryString += '?encounterId=';
        queryString += encounterId;

        queryString += '&&patientId=';
        queryString += patientId;

        queryString += '&&patientTypeId=';
        queryString += patientType;

        queryString += '&&page=';
        queryString += page ? page : 1;

        queryString += '&&rows=';
        queryString += rows ? rows : 10;

        return this._http.get<any>(this._getPhysicianCultureResult + queryString);
    }

    GetPhysicianCultureResultDetails = (id): Observable<any> => {
        let queryString: string = '';
        queryString += '?specimenId=';
        queryString += id;

        return this._http.get<any>(this._getPhysicianCultureResultDetails + queryString);
    }

    changeIsReleaseStatus = (sensitiveResultId: number, isRelease: boolean): Observable<any> => {
        const url = `${this._changeIsReleaseStatus}?sensitiveResultId=${sensitiveResultId}&&isRelease=${isRelease}`;
        return this._http.put<any>(url, null);
    }
}
