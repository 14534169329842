export class HospitalRooms {
    pageNumber: number = 1;
    pageSize: number = 10;
    sectionId: number = 0;
    roomId: number = 0;
    classId: number = 0;
    patientName: string = "";
    admissionStatus: number = 0;
    patientCode: string = "";
    doctorId: number = 0;
    constructor() { }
}