import {
    AdmissionServiceApiConfiguration,
    ConsultationTypeService,
    AdmissionReasonService,
    RoomService,
    BedService,
    AdtClassService,
    ReservationService,
    CompanionService,
    CompanionPeriodsService,
    AdmissionService,
    TransferService,
    BedStatusService,
    InPatientTimelineHistoryService,
    BedAvailabilityService,
    AdmissionReportSetupService,
    ConsultationTypesDetailService,
    ConsultingDoctorService

} from './services/admissionService';

export const NGA_ADMISSION_SERVICES = [
    AdmissionServiceApiConfiguration,
    ConsultationTypeService,
    AdtClassService,
    AdmissionReasonService,
    RoomService,
    BedService,
    CompanionService,
    ReservationService,
    CompanionPeriodsService,
    AdmissionService,
    TransferService,
    BedStatusService,
    InPatientTimelineHistoryService,
    BedAvailabilityService,
    AdmissionReportSetupService,
    ConsultationTypesDetailService,
    ConsultingDoctorService
];
