import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class ExcludedDiagnosisService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.excludedDiagnosis.url;
    }

    getExcludedDiagnosis= (contractId: number, classId: number): Observable<any[]> => {
        return this._http.get<any>(`${this._url}/AllExcludedDiagnosis?contractId=${contractId}&&classId=${classId}`)
    }

    add(data: any) {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
 
}