import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ItemCategory } from '../../../models/orders-and-billing/item-category';


@Injectable()
export class ItemCategoryService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _activeCategoriesUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.itemCategory.url;
        this._activeCategoriesUrl = this.actionUrl + _configuration.itemCategory.activeCategories;
    }

    getAllActive = (): Observable<any> => {
        return this._http.get<any>(`${this._activeCategoriesUrl}`)
    }
}
