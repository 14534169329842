import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { EncounterOrderCategory } from '../../../models/orders-and-billing/encounter-order-category';
import { OrderActionViewModel } from '../../../models/orders-and-billing/order-action-view-model';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ReceiveOrderStatus } from 'app/shared/models/orders-and-billing/enums/receive-order-status';
import { OrderPrioritySearchFilter } from 'app/shared/models/orders-and-billing/order-priority-search-filter';
import { ChangeOrderStatusDto } from 'app/shared/models/orders-and-billing/change-order-status-dto';

@Injectable()
export class OrderDetailService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _cancelOrderDetail: string;
    private _cancelMultiOrder: string;
    private orderIndex: string;
    private _orderActionElementUrl: string;
    private _multiOrderActionElement: string;
    private _execludedOrderActionUrl: string;
    private _orderByCategoryUrl: string;
    private _receivedOrderDetailUrl: string;
    private _completeOrderDetailUrl: string;
    private _changeOrderStatus: string;
    private _orderPriorityUrl: string;
    private _changeOrderReceiveStatus: string;
    private _getReceivedOrderLog: string;
    private _doctorNoteUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.orderDetail.url;
        this._cancelOrderDetail = this.actionUrl + _configuration.orderDetail.cancelOrderDetail;
        this._cancelMultiOrder = this.actionUrl + _configuration.orderDetail.cancelMultiOrder;
        this.orderIndex = this.actionUrl + _configuration.orderDetail.orderIndex;
        this._orderActionElementUrl = this.actionUrl + _configuration.orderDetail.orderActionElement;
        this._multiOrderActionElement = this.actionUrl + _configuration.orderDetail.multiOrderActionElement;
        this._execludedOrderActionUrl = this.actionUrl + _configuration.orderDetail.execludeOrderAction;
        this._orderByCategoryUrl = this.actionUrl + _configuration.orderDetail.orderByCategory;
        this._receivedOrderDetailUrl = this.actionUrl + _configuration.orderDetail.receivedOrderDetail;
        this._completeOrderDetailUrl = this.actionUrl + _configuration.orderDetail.completeOrderDetail;
        this._orderPriorityUrl = this.actionUrl + _configuration.orderDetail.orderPriority;
        this._changeOrderReceiveStatus = this.actionUrl + _configuration.orderDetail.changeOrderReceiveStatus;
        this._getReceivedOrderLog = this.actionUrl + _configuration.orderDetail.receivedOrderLog;
        this._doctorNoteUrl = this.actionUrl + _configuration.orderDetail.doctorNote;
    }

    getPatientEncounterOrderCategory = (patientId: number, encounterId: number, patientTypeId: number): Observable<EncounterOrderCategory[]> => {
        return this._http.get<any>(this.url + '?patientId=' + patientId + '&encounterId=' + encounterId + '&patientTypeId=' + patientTypeId)
    }

    getOrderStatus = (orderIds: string): Observable<any> => {
        return this._http.get<any>(this.url + '?orderIds=' + orderIds);
    }

    cancelOrderDetail = (orderActionViewModel: OrderActionViewModel) => {
        return this._http.put<any>(this._cancelOrderDetail, orderActionViewModel);
    }

    cancelMultiOrder = (data: OrderActionViewModel[]) => {
        return this._http.put<any>(this._cancelMultiOrder, data);
    }

    getOrderIndex = (patientId, patientType, encounterId, rowNo, pageIndex, itemCode, itemTitle, orderBy, orderDate, quantity, unitId, orderId, doctorId): Observable<any> => {

        return this._http.get<any>(`${this.orderIndex}?patientId=${patientId}&&patientType=${patientType}&&encounterId=${encounterId}&&rowNo=${rowNo}&&pageIndex=${pageIndex}
        &&itemCode=${itemCode}&&itemTitle=${itemTitle}&&orderBy=${orderBy}&&orderDate=${orderDate}&&quantity=${quantity}&&unitId=${unitId}&&orderId=${orderId}&&doctorId=${doctorId}`);
    }

    changeOrderActionElement = (orderActionViewModel: OrderActionViewModel) => {
        return this._http.put<any>(this._orderActionElementUrl, orderActionViewModel);
    }

    multiOrderActionElement = (data: any[]) => {
        return this._http.put<any>(this._multiOrderActionElement, data);
    }

    changeIsExecludedOrderAction = (orderActionViewModel: OrderActionViewModel) => {
        return this._http.put<any>(this._execludedOrderActionUrl, orderActionViewModel);
    }

    getOrderByCategory = (patientId: number, patientType: number, encounterId: number, categoriesId: string): Observable<any> => {
        return this._http.get<any>(`${this._orderByCategoryUrl}?patientId=${patientId}&&patientType=${patientType}&&encounterId=${encounterId}&&categoriesId=${categoriesId}`);
    }

    receivedOrderDetail = (orderDetailId: number): Observable<any> => {
        const query = `${this._receivedOrderDetailUrl}?orderDetailId=${orderDetailId}`;

        return this._http.put<any>(query, null);
    }

    completeOrderDetail = (orderDetailId: number): Observable<any> => {
        const query = `${this._completeOrderDetailUrl}?orderDetailId=${orderDetailId}`;

        return this._http.put<any>(query, null);
    }

    changeOrderStatus = (data: ChangeOrderStatusDto): Observable<any> => {
        return this._http.put<any>(this._changeOrderReceiveStatus, data);
    }

    getOrderPriority = (orderPrioritySearchFilter: OrderPrioritySearchFilter[]) => {
        return this._http.post<any>(this._orderPriorityUrl, orderPrioritySearchFilter);
    }

    getReceivedOrderLog = (orderActionId: number): Observable<any> => {
        let url = `${this._getReceivedOrderLog}?orderActionId=${orderActionId}`;
        return this._http.get<any>(url);
    }

    getdoctorNote = (itemId: number, orderId: number): Observable<any> => {
        let url = `${this._doctorNoteUrl}?itemId=${itemId}&&orderId=${orderId}`;
        return this._http.get<any>(url);
    }
}
