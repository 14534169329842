import {
    MedispanServiceApiConfiguration,
    RouteService,
    MediSpanService,
    SettingService,
    AllergySeverityService,
} from './services/medispan';

export const NGA_MEDISPAN_SERVICES = [
    MedispanServiceApiConfiguration,
    RouteService,
    MediSpanService,
    SettingService,
    AllergySeverityService
];
