<form (ngSubmit)="onSubmit()">
    <div class="search-title">
        <span>{{ 'Search Button' | translate }}</span>
    </div>
    <div class="row">
        <div class="col-sm-8">
            <div class="form-horizontal">
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label">{{ 'Patient Code' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="patientCode" class="form-control" id="patientCode"
                            placeholder="{{ 'Patient Code' | translate }}"
                            [(ngModel)]="admissionSearchFilter.patientCode">
                    </div>
                    <label class="col-sm-2 form-control-label">{{ 'Patient Name' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="patientName" class="form-control" id="patientName"
                            placeholder="{{ 'Patient Name' | translate }}"
                            [(ngModel)]="admissionSearchFilter.patientName">
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label">{{ 'National ID' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="number" name="nationalID" class="form-control" id="nationalID"
                            placeholder="{{ 'National ID' | translate }}" maxlength="10"
                            [(ngModel)]="admissionSearchFilter.nationalID">
                    </div>
                    <label class="col-sm-2 form-control-label">{{ 'Phone Number' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="phoneNumber" class="form-control" id="phoneNumber"
                            placeholder="{{ 'Phone Number' | translate }}"
                            [(ngModel)]="admissionSearchFilter.phoneNumber">
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label">{{ 'Has Admission' | translate }}</label>
                    <div class="col-sm-10">
                        <ui-switch size="small" name="hasAdmission" [(ngModel)]="admissionSearchFilter.hasAdmission">
                        </ui-switch>
                    </div>
                </div>
                <div class="form-group row form-group-default" [hidden]="!admissionSearchFilter.hasAdmission">
                    <label class="col-sm-2 form-control-label">{{ 'Admission Date' | translate }}</label>
                    <div class="col-sm-4">
                        <p-calendar name="admissionDate" dateFormat="dd/mm/yy"
                            placeholder="{{ 'Admission Date' | translate }}"
                            [(ngModel)]="admissionSearchFilter.admissionDate"></p-calendar>
                    </div>
                    <label class="col-sm-2 form-control-label">{{ 'Admission Doctor' | translate }}</label>
                    <div class="col-sm-4">
                        <p-dropdown  [autoDisplayFirst]="false"  [virtualScroll]="true" itemSize="30" name="admissionDoctor"
                            [options]="admissionDoctors" filter="filter"
                            placeholder="{{ 'Admission Doctor' | translate }}"
                            [(ngModel)]="admissionSearchFilter.admissionDoctor"></p-dropdown>
                    </div>
                </div>
                <div class="form-group row form-group-default" [hidden]="!admissionSearchFilter.hasAdmission">
                    <label class="col-sm-2 form-control-label">{{ 'Section' | translate }}</label>
                    <div class="col-sm-10">
                        <p-dropdown  [autoDisplayFirst]="false"  [virtualScroll]="true" itemSize="30" name="section" [options]="sections"
                            filter="filter" placeholder="{{ 'Section' | translate }}"
                            [(ngModel)]="admissionSearchFilter.section">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <!--Other-->
        <div class="col-sm-4 text-center">
            <img src="{{ ( 'FingerPrint-transparent' | baSystemPicture ) }}" class="finger-print row-image-header">
            <div class="col-sm-12">
                <button type="submit" class="btn btn-outline-primary btn-block">{{ 'Search Button' | translate
                    }}</button>
            </div>
        </div>
    </div>
</form>