import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CoverageService } from "app/shared/models/orders-and-billing/coverage-service";

@Injectable()
export class CoverageServiceService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _servicesByClassId: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.coverageService.url;
        this._servicesByClassId = this._actionUrl + _configuration.coverageService.getServicesByClassId;
    }

    public getServicesByClassId = (classId: number): Observable<CoverageService[]> => {
        return this._http.get<any>(this._servicesByClassId + '?classId=' + classId)
    }

    public add(data: CoverageService) {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update = (data: CoverageService): Observable<any> => {
        return this._http.put<any>(this._url, data)
    }
}
