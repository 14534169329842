import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { UnitConversion } from 'app/shared/models/orders-and-billing/unit-conversion';

@Injectable()
export class UnitConversionService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();

        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.unitConversion.url;
    }

    getByGroupId = (unitGroupId: number): Observable<UnitConversion[]> => {
        return this._http.get<any>(this.getUrl + '?unitGroupId=' + unitGroupId)
    }
}
