import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { PatientDischargeSearchFilter } from '../../../models/queryModel';
/// import filter 
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class PatientDischargeSearchService extends ServiceBase {

    private actionUrl: string;
    private _get: string;
    private _action: string;
    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._get = this.actionUrl + _configuration.patientDischargeSearch.url;
    }

    public getSearchResult = (data: PatientDischargeSearchFilter): Observable<any> => {

        let queryString: string = '';
        queryString += '?patientCode=';
        if (data.patientCode)
            queryString += data.patientCode;
        queryString += '&patientName=';
        if (data.patientName)
            queryString += data.patientName;
        queryString += '&nationalID=';
        if (data.nationalID)
            queryString += data.nationalID;
        queryString += '&phoneNumber=';
        if (data.phoneNumber)
            queryString += data.phoneNumber;
        queryString += '&admissionDate=';
        if (data.admissionDate)
            queryString += data.admissionDate.toLocaleDateString();
        queryString += '&admissionDoctor=';
        if (data.admissionDoctor)
            queryString += data.admissionDoctor;
        queryString += '&section=';
        if (data.section)
            queryString += data.section;

        return this._http.get<any>(this._get + queryString);
    }
}