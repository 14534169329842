import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';

@Injectable()
export class ConsultingDoctorService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getConsultingDoctorURL: string;
    private _post: string;
    private _put;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.consultationDoctor.url;
        this._getConsultingDoctorURL = this.actionUrl + _configuration.consultationDoctor.getConsultingDoctorByAdmissionId;
        this._post = this.actionUrl + _configuration.consultationDoctor.postConsultingDoctor;
        this._put = this.actionUrl + _configuration.consultationDoctor.updateConsultingDoctor
    }
    public insert = (data: any): Observable<any> => {
        return this._http.post<any>(this._post, JSON.stringify(data));
    }

    public update(id: Number, data: any) {
        return this._http.put<any>(this._put + '?id=' + id, JSON.stringify(data));
    }

    public getConsultingDoctors = (admissionDoctorId): Observable<any> => {
        return this._http.get<any>(this._getConsultingDoctorURL + '?admissionId=' + admissionDoctorId);
    }

}
