import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { concatMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { PatientTypes } from 'app/shared/models/core/enums/patient-types';
import { PhysicianDesktopPatientFilter, PhysicianDesktopPatientInfo, PagedResult } from 'app/shared/models/queryModel';
import { LabApiConfiguration } from 'app/shared/services/lab';
import { PhysicianDesktopLabProcedure } from 'app/shared/models/lab';
import { empty } from 'rxjs';

@Injectable()
export class PhysicianDesktopSearchService extends ServiceBase {

    private actionUrl: string;
    private _physicianDesktopUrl: string;
    private _getInPatient: string;
    private _getOutPatient: string;
    private _getAllInPatient: string;
    private _getAllOutPatient: string;
    private _action: string;
    private _getLapProceduresUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration, private _labApiConfiguration: LabApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._physicianDesktopUrl = _configuration.PhysicianDesktopSearch.url;
        this._getInPatient = this.actionUrl + this._physicianDesktopUrl + _configuration.PhysicianDesktopSearch.inPatient;
        this._getOutPatient = this.actionUrl + this._physicianDesktopUrl + _configuration.PhysicianDesktopSearch.outPatient;
        this._getAllInPatient = this.actionUrl + this._physicianDesktopUrl + _configuration.PhysicianDesktopSearch.allInPatient;
        this._getAllOutPatient = this.actionUrl + this._physicianDesktopUrl + _configuration.PhysicianDesktopSearch.allOutPatient;
        this._getLapProceduresUrl = _labApiConfiguration.server + _labApiConfiguration.specimenTransaction.labProceduresUrl;
    }

    get = (data: PhysicianDesktopPatientFilter, isAllPatient: boolean): Observable<any> => {

        let queryString: string = '';


        queryString += '?patientCode=';
        queryString += data.patientCode;

        if (data.patientType === PatientTypes.inPatient) {

            queryString += '&admissionDate=';
            queryString += data.encounterDate ? data.encounterDate.toLocaleDateString() : null;

            queryString += '&isActiveEncounter=';
            queryString += data.isActive;

        } else {

            queryString += '&visitDate=';
            queryString += data.encounterDate ? data.encounterDate.toLocaleDateString() : null;

            queryString += '&visitType=';
            queryString += data.patientSource;

            queryString += '&visitStatus=';
            queryString += data.isActive;

        }
        queryString += '&patientLocation=';
        queryString += data.patientLocation;

        queryString += '&patientFullName=';
        queryString += data.patientFullName;

        queryString += '&pageIndex=';
        queryString += data.pageIndex ? data.pageIndex : 0;

        let patientsInfoPagedResult: PagedResult<PhysicianDesktopPatientInfo>;
        let encountersIds: string;

        let requestUrl = '';
        if (isAllPatient) {
            requestUrl = data.patientType === PatientTypes.inPatient ? this._getAllInPatient : this._getAllOutPatient;
        }

        if (!isAllPatient) {
            requestUrl = data.patientType === PatientTypes.inPatient ? this._getInPatient : this._getOutPatient;
        }

        return this._http.get<any>(requestUrl + queryString)
            .pipe(
                map((res: any) => {
                    patientsInfoPagedResult = res as PagedResult<PhysicianDesktopPatientInfo>;

                    if (patientsInfoPagedResult && patientsInfoPagedResult && patientsInfoPagedResult.Results.length > 0) {
                        encountersIds = patientsInfoPagedResult.Results.map(r => r.EncounterId).join(',');
                    }

                }),
                concatMap((c) => encountersIds ?
                    this._http.get<any>(`${this._getLapProceduresUrl}?patientType=${data.patientType}&encountersIds=${encountersIds}`)
                        .map((response: any) => {
                            const patientsInfoLabProcedures: PhysicianDesktopLabProcedure[] = response as PhysicianDesktopLabProcedure[];

                            if (patientsInfoLabProcedures) {
                                // Mapping
                                patientsInfoPagedResult.Results.map(patientInfo => {
                                    const patientInfoLabProcedure = patientsInfoLabProcedures.find(labProcedure =>
                                        labProcedure.EncounterId === patientInfo.EncounterId);

                                    if (patientInfoLabProcedure) {
                                        patientInfo.IsInfictious = patientInfoLabProcedure.HasAnyInfeciousLabResult;
                                        patientInfo.HasAnyReleasedLabResult = patientInfoLabProcedure.HasAnyReleasedLabResult;
                                    }
                                });
                            }

                            return patientsInfoPagedResult;
                        }) : empty())
            )

    }
}
