import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { AdmissionReason } from 'app/shared/models/admission/admission-reason';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';
import { AdmissionReasonStatus } from 'app/shared/models/admission/enums/admission-reason-status';
@Injectable()
export class AdmissionReasonService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getByIdUrl: string;
    private _getBySearchUrl: string;
    private _getBySearchPagedUrl: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.admissionReason.url;
        this._getByIdUrl = this.actionUrl + _configuration.admissionReason.byId;
        this._getBySearchUrl = this.actionUrl + _configuration.admissionReason.bySearch;
        this._getBySearchPagedUrl = this.actionUrl + _configuration.admissionReason.bySearchPaged;
    }

    public getById = (id: Number): Observable<AdmissionReason> => {
        return this._http.get<any>(
            this._getByIdUrl
            + '?id=' + id
        );
    }

    public getBySearchPaged = (filterAdmissionReason: AdmissionReason, pageIndex: number, pageSize: Number): Observable<AdmissionReason[]> => {
        const code = filterAdmissionReason.Code ? filterAdmissionReason.Code : '';
        const title = filterAdmissionReason.Title ? filterAdmissionReason.Title : '';
        const foreignTitle = filterAdmissionReason.ForeignTitle ? filterAdmissionReason.ForeignTitle : '';
        const status = filterAdmissionReason.NumericStatus == AdmissionReasonStatus.Active ? true
            : (filterAdmissionReason.NumericStatus == AdmissionReasonStatus.InActive ? false
                : null);
        return this._http.get<any>(
            this._getBySearchPagedUrl
            + '?code=' + code
            + '&&title=' + title
            + '&&foreignTitle=' + foreignTitle
            + '&&status=' + status
            + '&&pageIndex=' + pageIndex
            + '&&pageSize=' + pageSize
        );
    }

    public add = (data: AdmissionReason): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update = (data: AdmissionReason): Observable<any> => {
        return this._http.put<any>(this._url, JSON.stringify(data));
    }

    public getBySearch = (query: string = null): Observable<AdmissionReason[]> => {
        return this._http.get<any>(this._getBySearchUrl + '?query=' + (query ? query : ''));
    }
}