import { Directive, Input, ElementRef, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure/base.component';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective extends BaseComponent {
  private userPermissions = [];

  constructor(private _templateRef: TemplateRef<any>, private _viewContainer: ViewContainerRef) {
    super();
  }

  @Input() set hasPermission(val) {
    this.userPermissions = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }

  private checkPermission() {
    for (const checkPermission of this.userPermissions) {
      const permissionFound = super.validatePermission(checkPermission);
      if (permissionFound) {
        return true;
      }
    }
    return false;
  }

  checkPermissionByParameter(permission: string) {
    return super.validatePermission(permission);
  }
}
