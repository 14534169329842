import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { InvoiceSettlementFilter } from 'app/shared/models/orders-and-billing/invoice-settlement-filter';
import { SettlementInvoice } from 'app/shared/models/orders-and-billing/settlement-invoice';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SettllementInvoiceService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _all: string;
    private _subset: string;
    private _changeStatus: string;
    private _taxAmountUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.settlementInvoice.url;
        this._all = this.actionUrl + _configuration.settlementInvoice.all;
        this._subset = this.actionUrl + _configuration.settlementInvoice.subset;
        this._changeStatus = `${this.actionUrl}${_configuration.payerSettlement.changeStatus}`;
        this._taxAmountUrl= this.actionUrl + _configuration.settlementInvoice.taxAmount;
    }

    deleteAll = (id: number) => {
        const url = `${this._all}?settlementId=${id}`;
        return this._http.delete(url);
    }

    deleteSubset = (id: number, selectedIds: number[]) => {
        const url = `${this._subset}?settlementId=${id}&claimDetailIds=${selectedIds.map(String)}`;
        return this._http.delete(url);
    }

    addSubset = (data: InvoiceSettlementFilter) => {
        return this._http.post<any>(this._subset, data);
    }

    addAll = (data: InvoiceSettlementFilter) => {
        return this._http.post<any>(this._all, data);
    }

    getTotalSettlementAmount = (settlementId: number): Observable<number> => {
        return this._http.get<number>(`${this._url}/TotalSettlementAmount?settlementId=${settlementId}`);
    }

    updateSettlementInvoices = (settlementInvoices: SettlementInvoice[], settlementId: number): Observable<number> => {
        return this._http.patch<number>(`${this._url}?settlementId=${settlementId}`, settlementInvoices);
    }

    changeToComplete = (settlementId: number, payerId: number, receiptNo: string,
        payerSettlementId: number, oldStatus: number, newStatus: number) => {
        const url = `${this._url}/IsEligibleChangeToComplete?settlementId=${settlementId}&&payerId=${payerId}&&receiptNo=${receiptNo}`;
        return this._http.get(url).pipe(
            switchMap(isEligible => {
                if (isEligible) {
                    const data = {
                        PayerSettlementId: payerSettlementId,
                        OldStatus: oldStatus,
                        NewStatus: newStatus
                    }
                    return this._http.post<any>(this._changeStatus, data)
                } else {
                    throw new Error("Check RECEIPT AMOUNT should >= PAID AMOUNT");
                }
            })
        )
    }

    getTaxAmount = (settlementId: number): Observable<number> => {
        return this._http.get<number>(`${this._taxAmountUrl}?settlementId=${settlementId}`);
    }
}
