export enum OperationManagementLink {
    EquipmentDefinition = '/EquipmentDefinition',
    RoomDefinition = '/RoomDefinition',
    OperationProcedureDefinition = '/OperationProcedureDefinition',
    OperationTracking = '/OperationTracking',
    OperationSlate = '/OperationSlate',
    Booking = '/Booking',
    OperationInbox = '/OperationInbox',
    OperationDashboard = '/OperationDashboard',
}
