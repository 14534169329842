import { SelectItem } from 'primeng/api';
import { PatientPaymentCodes } from '../enums/patient-payment-codes';

export class PatientPaymentCodesLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Payment', value: PatientPaymentCodes.Payment },
            { label: 'Refund', value: PatientPaymentCodes.Refund },
            { label: 'Discount', value: PatientPaymentCodes.Discount },
            { label: 'Down Payment', value: PatientPaymentCodes.DownPayment },
            { label: 'Invoice', value: PatientPaymentCodes.Invoice },
            { label: 'Cancel Discount', value: PatientPaymentCodes.CancelDiscount },
            { label: 'Cancel Down Payment', value: PatientPaymentCodes.CancelDownPayment },
            { label: 'Cancel Invoice', value: PatientPaymentCodes.CancelInvoice }];
    }

    static getIncomingPaymentsLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Payment', value: PatientPaymentCodes.Payment },
            { label: 'Down Payment', value: PatientPaymentCodes.DownPayment }];
    }

    static getOutgoingPaymentsLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Refund', value: PatientPaymentCodes.Refund },
            { label: 'Cancel Down Payment', value: PatientPaymentCodes.CancelDownPayment }];
    }

    static getStatementTransactionLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Payment', value: PatientPaymentCodes.Payment },
            { label: 'Down Payment', value: PatientPaymentCodes.DownPayment },
            { label: 'Refund', value: PatientPaymentCodes.Refund },
            { label: 'Settlement', value: PatientPaymentCodes.Settlement },
            { label: 'Discount', value: PatientPaymentCodes.Discount },
            { label: 'Cancel Down Payment', value: PatientPaymentCodes.CancelDownPayment }];
    }
}
