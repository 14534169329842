import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { SettlementInvoiceElementDetails } from 'app/shared/models/orders-and-billing/settlement-invoice-element-details';
import { SettlementInvoiceItemDetails } from 'app/shared/models/orders-and-billing/settlement-invoice-item-details';
import { SettlementInvoice } from 'app/shared/models/orders-and-billing/settlement-invoice';
import { SettlementDoctorFees } from 'app/shared/models/orders-and-billing/settlement-doctor-fees';
import { PagedResult } from 'app/shared/models/orders-and-billing/paged-result';
import { StampSubscription } from 'app/shared/models/orders-and-billing/stamp-subscription';
import { map } from 'rxjs/operators';

@Injectable()
export class SettllementInvoiceDetailsService extends ServiceBase {

    private _url: string;


    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._url = `${_configuration.server}SettlementInvoiceDetails`;
    }

    getElements = (settlementInvoiceId: number): Observable<SettlementInvoiceElementDetails[]> => {
        return this._http.get<any>(`${this._url}/Elements?settlementInvoiceId=${settlementInvoiceId}`)

    }

    getItems = (settlementInvoiceId: number, itemCode: string, pageIndex: number, pageSize: number): Observable<PagedResult<SettlementInvoiceItemDetails>> => {
        return this._http.get<any>(`${this._url}/Items?settlementInvoiceId=${settlementInvoiceId}` +
            `&itemCode=${itemCode}&pageIndex=${pageIndex}&pageSize=${pageSize}`)

    }

    getElementItems = (settlementInvoiceId: number, elementId: number, pageIndex: number, pageSize: number): Observable<PagedResult<SettlementInvoiceItemDetails>> => {
        return this._http.get<any>(`${this._url}/Element/Items?settlementInvoiceId=${settlementInvoiceId}` +
            `&elementId=${elementId}&pageIndex=${pageIndex}&pageSize=${pageSize}`
        )

    }

    getElementsTotalPercentage = (settlementInvoiceId: number): Observable<number> => {
        return this._http.get<any>(`${this._url}/Elements/Total/Percentage?settlementInvoiceId=${settlementInvoiceId}`)

    }

    getElementsTotalSettlementAmount = (settlementInvoiceId: number): Observable<number> => {
        return this._http.get<any>(`${this._url}/Elements/Total/SettlementAmount?settlementInvoiceId=${settlementInvoiceId}`)

    }

    getItemsTotalSettlementAmount = (settlementInvoiceId: number): Observable<number> => {
        return this._http.get<any>(`${this._url}/Items/Total/SettlementAmount?settlementInvoiceId=${settlementInvoiceId}`)

    }

    getDoctorFeesTotalSettlementAmount = (settlementId: number): Observable<number> => {
        return this._http.get<any>(`${this._url}/All/DoctorFees/Total/SettlementAmount?settlementId=${settlementId}`)

    }

    updateSettlementInvoiceElements = (settlementInvoice: SettlementInvoice, elementDetails: SettlementInvoiceElementDetails[], settlementId: number, computationType: number): Observable<number> => {
        settlementInvoice.SettlementInvoiceElementDetails = elementDetails;
        return this._http.patch<number>(`${this._url}/Elements?settlementId=${settlementId}&computationType=${computationType}`, settlementInvoice);
    }

    updateSettlementInvoiceItems = (settlementInvoice: SettlementInvoice, itemDetails: SettlementInvoiceItemDetails[], settlementId: number): Observable<number> => {
        settlementInvoice.SettlementInvoiceItemDetails = itemDetails;
        return this._http.patch<number>(`${this._url}/Items?settlementId=${settlementId}`, settlementInvoice)

    }

    updateSettlementDoctorFees = (doctorFees: SettlementDoctorFees[], settlementId: number, calculateTax: boolean): Observable<number> => {
        return this._http.patch<number>(`${this._url}/All/DoctorFees?settlementId=${settlementId}&calculateTax=${calculateTax}`, doctorFees)

    }

    getDoctorFees = (settlementId: number, drName: string, invoiceNo: string, claimNumber: string, patientName: string): Observable<SettlementDoctorFees[]> => {

        const url = `${this._url}/All/DoctorFees?settlementId=${settlementId}` +
            `&drName=${drName ? drName : ''}` +
            `&invoiceNo=${invoiceNo ? invoiceNo : ''}` +
            `&claimNumber=${claimNumber ? claimNumber : ''}` +
            `&patientName=${patientName ? patientName : ''}`

        return this._http.get<any>(url);
    }

    getDefaultStampSubscriptionValue = (): Observable<StampSubscription> => {
        return this._http.get<any>(`${this._url}/DefaultStampSubscriptionValue`
        )
    }

    importDoctorFees = (file, settlementId, calculateTax: boolean): Observable<any> => {
        return this._http.post<number>(`${this._url}/ImportDoctorFees?settlementId=${settlementId}&calculateTax=${calculateTax}`, JSON.stringify(file));
    }

    getSettlementDoctorFeesForExport = (settlementId): Observable<any> => {
        return this._http.get<any>(`${this._url}/SettlementDoctorFeesForExport?settlementId=${settlementId}`);
    }
}
