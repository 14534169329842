import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { OrganizationSectionFilter } from 'app/shared/models/core/organization-section-filter';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { OrganizationSection } from '../../../models/core/organization-section';
@Injectable()

export class OrganizationSectionService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;
    private _allUrl: string;
    private _inPatientSections: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.OganizationSection.url;
        this._allUrl = this.actionUrl + _configuration.OganizationSection.all;
        this._inPatientSections = this.actionUrl + _configuration.OganizationSection.inPatientSections;
    }

    get = (): Observable<OrganizationSectionFilter[]> => {
        return this._http.get<any>(this._allUrl);
    }

    getInPatientSections = (): Observable<OrganizationSectionFilter[]> => {
        return this._http.get<any>(this._inPatientSections);
    }

    insert = (data: OrganizationSectionFilter): Observable<OrganizationSectionFilter> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: OrganizationSectionFilter): Observable<OrganizationSectionFilter> => {
        return this._http.put<any>(this._url, data);
    }
}
