import {Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'baDomSanitizer' })
export class BaDomSanitizerPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) { }
    transform(icon: string) {
        return this._sanitizer.bypassSecurityTrustResourceUrl(icon);
    }
}
