import { Routes, RouterModule, LoadChildrenCallback } from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from 'app/shared/services';
export type LoadChildren = string | LoadChildrenCallback;

// noinspection TypeScriptValidateTypes

// export function loadChildren(path) { return System.import(path); };

export const routes: Routes = [
    {
        path: 'notAuthorized',
        loadChildren: 'app/pages/not-authorized/not-authorized.module#NotAuthorizedModule',
    },
    {
        path: 'pages',
        component: Pages, canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'home', loadChildren: 'app/pages/home/home.module#HomeModule' },
            { path: 'callback', loadChildren: 'app/pages/callback/callback.module#CallbackModule' },
            { path: 'silent', loadChildren: 'app/pages/silent/silent.module#SilentModule' },
            { path: 'dashboard', loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule' },
            { path: 'control', loadChildren: 'app/pages/control/control.module#ControlModule' },
            { path: 'administrative', loadChildren: 'app/pages/administrative/administrative.module#AdministrativeModule' },
            { path: 'patientAccounting', loadChildren: 'app/pages/patientAccounting/patientAccounting.module#PatientAccountingModule' },
            { path: 'clinical', loadChildren: 'app/pages/clinical/clinical.module#ClinicalModule' },
            { path: 'order', loadChildren: 'app/pages/order/order.module#OrderModule' },
            { path: 'insurance', loadChildren: 'app/pages/insurance/insurance.module#InsurnaceModule' },
            { path: 'itemStructure', loadChildren: 'app/pages/item-structure/item-structure.module#ItemStructureModule' },
            { path: 'nursing', loadChildren: 'app/pages/nursing/nursing.module#NursingModule' },
            { path: 'admissionReports', loadChildren: 'app/pages/admission-reports/admission-reports.module#AdmissionReportsModule' },
            { path: 'laboratoryReports', loadChildren: 'app/pages/laboratory-reports/laboratory-reports.module#LaboratoryReportsModule' },
            { path: 'claimManagement', loadChildren: 'app/pages/claim-management/claim-management.module#ClaimManagementModule' },
            { path: 'hospitalPerformanceDashboard', loadChildren: 'app/pages/dashboard-hospital-performance/dashboard-hospital-performance.module#DashboardHospitalPerformanceModule' },
            { path: 'patientOrderManagement', loadChildren: 'app/pages/patient-order-management/patient-order-management.module#PatientOrderManagementModule' },
            { path: 'radiologyReports', loadChildren: 'app/pages/radiology-reports/radiology-reports.module#RadiologyReportsModule' },
            { path: 'userReports', loadChildren: 'app/pages/user-reports/user-reports.module#UserReportsModule' },
            { path: 'pharmacyOrderManagement', loadChildren: 'app/pages/pharmacy-order-management/pharmacy-order-management.module#PharmacyOrderManagementModule' },
            { path: 'labOrderManagement', loadChildren: 'app/pages/lab-order-management/lab-order-management.module#LabOrderManagementModule' },
            { path: 'doctorSettlement', loadChildren: 'app/pages/doctor-settlement/doctor-settlement.module#DoctorSettlementModule' },
            { path: 'outPatientOrderManagement', loadChildren: 'app/pages/outpatient-order-management/outpatient-order-management.module#OutPatientOrderManagementModule' },
            { path: 'labMonitor', loadChildren: 'app/pages/lab-monitor/lab-monitor.module#LabMonitorModule' },
        ],
    },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
