import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable';
import { MedispanServiceApiConfiguration } from '../medispan-service-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { MediSpanViewModel } from 'app/shared/models/medispan/medispan-view-model';
import { MediSpanOutput } from 'app/shared/models/medispan/medispan-output';
import { MediSpanDto } from 'app/shared/models/medispan/medispan-dto';

@Injectable()
export class MediSpanService extends ServiceBase {

    private actionUrl: string;
    private _mediSpanResultUrl: string;
    private _mediSpanLogUrl: string;

    constructor(private _http: HttpClient, private _configuration: MedispanServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._mediSpanResultUrl = this.actionUrl + _configuration.mediSpan.mediSpanResult;
        this._mediSpanLogUrl = this.actionUrl + _configuration.mediSpan.mediSpanLog;
    }

    getMediSpanResultUrl = (mediSpanViewModel: MediSpanViewModel): Observable<MediSpanOutput[]> => {
        return this._http.post<any>(this._mediSpanResultUrl, mediSpanViewModel)
    }

    postMediSpanLog = (mediSpanDto: MediSpanDto): Observable<MediSpanOutput[]> => {
        return this._http.post<any>(this._mediSpanLogUrl, mediSpanDto)
    }

}
