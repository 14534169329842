import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { PriceList } from 'app/shared/models/orders-and-billing/price-list';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PriceListService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;
    private getAllActive: string;
    private getEmptyPriceListItemsUrl: string;
    private getNonEmptyPriceListItemsUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.getUrl = this.actionUrl + configuration.priceList.url;
        this.postUrl = this.actionUrl + configuration.priceList.url;
        this.putUrl = this.actionUrl + configuration.priceList.url;
        this.deleteUrl = this.actionUrl + configuration.priceList.url;
        this.getAllActive = this.actionUrl + configuration.priceList.getAllActivePriceList;
        this.getEmptyPriceListItemsUrl = this.actionUrl + configuration.priceList.getEmptyPriceListItems;
        this.getNonEmptyPriceListItemsUrl = this.actionUrl + configuration.priceList.getNonEmptyPriceListItems;
    }

    get = (): Observable<PriceList[]> => {
        return this.http.get<any>(this.getUrl)
    }
    getEmptyPriceListItems = (): Observable<PriceList[]> => {
        return this.http.get<any>(this.getEmptyPriceListItemsUrl)
    }
    getNonEmptyPriceListItems = (): Observable<PriceList[]> => {
        return this.http.get<any>(this.getNonEmptyPriceListItemsUrl)
    }

    post(data: PriceList) {
        return this.http.post<any>(this.postUrl, JSON.stringify(data));
    }

    put(data: PriceList) {
        return this.http.put<any>(this.putUrl, JSON.stringify(data));
    }

    delete(id: number) {
        return this.http.delete(this.deleteUrl + "/" + id);
    }

    getAllActivePriceList = (): Observable<PriceList[]> => {
        return this.http.get<any>(this.getAllActive)
    }
}
