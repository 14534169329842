import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class ApprovalInsurance extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.insurance.url;
        this.postUrl = this.actionUrl + _configuration.insurance.url;
        this.putUrl = this.actionUrl + _configuration.insurance.url;
        this.deleteUrl = this.actionUrl + _configuration.insurance.url;
    }

    get = (): Observable<any> => {
        return null;
    }
    // handel Coverage-letter 
    newApproval = (data: any): Observable<any> => {
        return this._http.post<any>(this.postUrl, JSON.stringify(data));
    }
    getApprovalById = (id): Observable<any> => {
        return this._http.get<any>(this.getUrl + '/?id=' + id);
    }

    /// handel Approval follow up functionality

    getAllApprovals = (): Observable<any> => {
        return this._http.get<any>(this.getUrl);
    }

    approvalsbyFilter = (pageNo, rowNo, status?: number, patientCode?: string, startDate?: Date, endDate?: Date): Observable<any> => {
        const start = startDate ? startDate.toISOString() : '';
        const end = endDate ? endDate.toISOString() : '';
        patientCode = patientCode ? patientCode : '';
        const strStatus = status ? status : '';
        const url = `${this.getUrl}/ApprovalsByFilter?status=${strStatus}&patientCode=${patientCode}` +
            `&startDate=${start}&endDate=${end}&pageNo=${pageNo}&rowNo=${rowNo}`;
        return this._http.get<any>(url);
    }

    // handel change status from pending to approved
    ApprovedApproval = (id): Observable<any> => {
        return this._http.put<any>(this.putUrl + '/ApprovedApprovalById?id=' + id, null);
    }

    // handel reject approve 
    RejectApproval = (id): Observable<any> => {
        return this._http.put<any>(this.putUrl + '/RejectApprovalById?id=' + id, null);
    }

    // handel re open 
    // handel reject approve 
    ReOpenApproval = (id): Observable<any> => {
        return this._http.put<any>(this.putUrl + '/ReOpenApprovalById?id=' + id, null);
    }

    // handel update approve 
    UpdateApproval = (approval): Observable<any> => {
        return this._http.put<any>(this.putUrl, approval);
    }
}
