import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { PatientStatement } from '../../../models/orders-and-billing/patient-statement';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientStatmentSearch } from '../../../models/queryModel/patient-statment-search';
import { PatientStatementFilter } from 'app/shared/models/queryModel/patient-statement-filter';
import { map } from 'rxjs/operators';
@Injectable()
export class PatientStatmentSearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientStatmentSearch.url;
    }

    getPatientStatment = (patientStatmentSearch: PatientStatmentSearch, page: number, rows: number): Observable<PatientStatement[]> => {

        const patientId = patientStatmentSearch.PatientId ? patientStatmentSearch.PatientId : null;
        const patientCode = patientStatmentSearch.PatientCode ? patientStatmentSearch.PatientCode : '';
        const patientName = patientStatmentSearch.PatientName ? patientStatmentSearch.PatientName : '';
        const patientTypeId = patientStatmentSearch.PatientTypeId ? patientStatmentSearch.PatientTypeId : null;
        const dealingTypeId = patientStatmentSearch.DealingTypeId ? patientStatmentSearch.DealingTypeId : null;
        const payerId = patientStatmentSearch.PayerId ? patientStatmentSearch.PayerId : null;
        const providerName = patientStatmentSearch.ProviderName ? patientStatmentSearch.ProviderName : '';
        const encounterStatusId = patientStatmentSearch.EncounterStatusId ? patientStatmentSearch.EncounterStatusId : null;
        const encounterSectionId = patientStatmentSearch.EncounterSectionId ? patientStatmentSearch.EncounterSectionId : null;
        var encounterFromString = patientStatmentSearch.EncounterFromDate ? patientStatmentSearch.EncounterFromDate.toISOString() : null;
        var encounterToString = patientStatmentSearch.EncounterToDate ? patientStatmentSearch.EncounterToDate.toISOString() : null;
        const invoiceStatusId = patientStatmentSearch.InvoiceStatusId ? patientStatmentSearch.InvoiceStatusId : null;

        const statmentStatusId = patientStatmentSearch.StatmentStatusId ? patientStatmentSearch.StatmentStatusId : null;
        const invoiceId = patientStatmentSearch.InvoiceId ? patientStatmentSearch.InvoiceId : null;
        const issuedById = patientStatmentSearch.IssuedById ? patientStatmentSearch.IssuedById : null;

        const balanceNet = patientStatmentSearch.BalanceNet != null ? patientStatmentSearch.BalanceNet : null;
        const doctorId = patientStatmentSearch.DoctorId != null ? patientStatmentSearch.DoctorId : null;

        return this._http.get<any>(this.url
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&PatientTypeId=' + patientTypeId
            + '&&PatientName=' + patientName
            + '&&DealingTypeId=' + dealingTypeId
            + '&&PayerId=' + payerId
            + '&&providerName=' + providerName
            + '&&EncounterStatusId=' + encounterStatusId
            + '&&EncounterSectionId=' + encounterSectionId
            + '&&EncounterFromDate=' + encounterFromString
            + '&&EncounterToDate=' + encounterToString
            + '&&StatmentStatusId=' + statmentStatusId
            + '&&InvoiceId=' + invoiceId
            + '&&IssuedById=' + issuedById
            + '&&BalanceNet=' + balanceNet
            + '&&Page=' + page
            + '&&Rows=' + rows
            + '&&DoctorId=' + doctorId
            + '&&InvoiceStatusId=' + invoiceStatusId
        );
    }

    public ExportPatientStatement = (patientStatmentSearch: PatientStatmentSearch): Observable<any> => {

        const patientId = patientStatmentSearch.PatientId ? patientStatmentSearch.PatientId : null;
        const patientCode = patientStatmentSearch.PatientCode ? patientStatmentSearch.PatientCode : '';
        const patientName = patientStatmentSearch.PatientName ? patientStatmentSearch.PatientName : '';
        const patientTypeId = patientStatmentSearch.PatientTypeId ? patientStatmentSearch.PatientTypeId : null;
        const dealingTypeId = patientStatmentSearch.DealingTypeId ? patientStatmentSearch.DealingTypeId : null;
        const payerId = patientStatmentSearch.PayerId ? patientStatmentSearch.PayerId : null;
        const encounterStatusId = patientStatmentSearch.EncounterStatusId ? patientStatmentSearch.EncounterStatusId : null;
        const encounterSectionId = patientStatmentSearch.EncounterSectionId ? patientStatmentSearch.EncounterSectionId : null;
        var encounterFromString = patientStatmentSearch.EncounterFromDate ? patientStatmentSearch.EncounterFromDate.toISOString() : null;
        var encounterToString = patientStatmentSearch.EncounterToDate ? patientStatmentSearch.EncounterToDate.toISOString() : null;

        const statmentStatusId = patientStatmentSearch.StatmentStatusId ? patientStatmentSearch.StatmentStatusId : null;
        const invoiceId = patientStatmentSearch.InvoiceId ? patientStatmentSearch.InvoiceId : null;
        const issuedById = patientStatmentSearch.IssuedById ? patientStatmentSearch.IssuedById : null;

        const balanceNet = patientStatmentSearch.BalanceNet ? patientStatmentSearch.BalanceNet : null;
        const doctorId = patientStatmentSearch.DoctorId ? patientStatmentSearch.DoctorId : null;
        const invoiceStatusId = patientStatmentSearch.InvoiceStatusId ? patientStatmentSearch.InvoiceStatusId : null;

        const url = this.url + '/CsvPatientStatment'
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&PatientName=' + patientName
            + '&&PatientTypeId=' + patientTypeId
            + '&&DealingTypeId=' + dealingTypeId
            + '&&PayerId=' + payerId
            + '&&EncounterStatusId=' + encounterStatusId
            + '&&EncounterSectionId=' + encounterSectionId
            + '&&EncounterFromDate=' + encounterFromString
            + '&&EncounterToDate=' + encounterToString
            + '&&StatmentStatusId=' + statmentStatusId
            + '&&InvoiceId=' + invoiceId
            + '&&IssuedById=' + issuedById
            + '&&BalanceNet=' + balanceNet
            + '&&DoctorId=' + doctorId
            + '&&InvoiceStatusId=' + invoiceStatusId;
        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    getCreditInvoicesSearch = (pageNo, rowNo, filter: PatientStatementFilter): Observable<PatientStatement[]> => {
        let queryString: string = '';
        queryString += '?invoiceId=';
        queryString += filter.InvoiceId ? filter.InvoiceId : '';

        queryString += '&&patientCode=';
        queryString += filter.PatientCode ? filter.PatientCode : '';

        queryString += '&&patientName=';
        queryString += filter.PatientName ? filter.PatientName : '';

        queryString += '&&patientTypeId=';
        queryString += filter.PatientTypeID ? filter.PatientTypeID : '';

        queryString += '&&payerId=';
        queryString += filter.PayerID;

        queryString += '&&fromIssueDate=';
        queryString += filter.FromIssueDate.toDateString();

        queryString += '&&toIssueDate=';
        queryString += filter.ToIssueDate.toDateString();

        queryString += '&&pageNo=';
        queryString += pageNo;

        queryString += '&&rowNo=';
        queryString += rowNo;

        return this._http.get<any>(this.url + '/CreditInvoicesSearch' + queryString);
    }

    public exportCreditInvoices = (patientStatementFilter: PatientStatementFilter): Observable<any> => {
        let queryString: string = '';
        queryString += '?invoiceId=';
        queryString += patientStatementFilter.InvoiceId;

        queryString += '&&patientCode=';
        queryString += patientStatementFilter.PatientCode;

        queryString += '&&patientName=';
        queryString += patientStatementFilter.PatientName;

        queryString += '&&patientTypeId=';
        queryString += patientStatementFilter.PatientTypeID;

        queryString += '&&payerId=';
        queryString += patientStatementFilter.PayerID;

        queryString += '&&fromIssueDate=';
        queryString += patientStatementFilter.FromIssueDate.toDateString();

        queryString += '&&toIssueDate=';
        queryString += patientStatementFilter.ToIssueDate.toDateString();
        const url = this.url + '/CsvCreditInvoice' + queryString;
        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    ClaimAmount = (filter: PatientStatementFilter): Observable<PatientStatement[]> => {

        let queryString: string = '';
        queryString += '?InvoiceId=';
        queryString += filter.InvoiceId;

        queryString += '&&PatientCode=';
        queryString += filter.PatientCode;

        queryString += '&&PatientName=';
        queryString += filter.PatientName;

        queryString += '&&PatientTypeId=';
        queryString += filter.PatientTypeID;

        queryString += '&&PayerId=';
        queryString += filter.PayerID;

        queryString += '&&FromIssueDate=';
        queryString += filter.FromIssueDate.toLocaleDateString();

        queryString += '&&ToIssueDate=';
        queryString += filter.ToIssueDate.toLocaleDateString();

        return this._http.get<any>(this.url + '/ClaimAmount' + queryString
        );
    }
}
