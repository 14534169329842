import { NGA_ADMISSION_SERVICES } from './admission.service.module';
import { NGA_PHARMACY_SERVICES } from './pharmacy.service.module';
import { NGA_LAB_SERVICES } from './lab.service.module';
import { NGA_QUERYMODEL_SERVICES } from './query-model.service.module';
import { NGA_CONTROL_SERVICES } from './control.service.module';
import { NGA_ORDER_SERVICES } from './order-and-billing.service.module';
import { NGA_CORE_SERVICES } from './core.service.module';
import { NGA_NURSE_SERVICES } from './nurse.service.module';
import { NGA_MEDISPAN_SERVICES } from './medispan.service.module';

import { NGA_SYSTEM_COMPONENTS } from './system.component.module';
import { NGA_BUSINESS_COMPONENTS } from './business.component.module';
import { NGA_APPOINTMENT_SERVICES } from './appointment.service.module';
import { NGA_QUERY_BUILDER_SERVICES } from './query-builder.service.module';
import { NGA_HI_NOTIFICATION_SERVICES } from './his-notification.service.module';


import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from 'app/shared/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../../environments/environment';

import {
    BaThemeConfig,
} from './theme.config';

import {
    BaThemeConfigProvider,
} from './theme.configProvider';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TableModule } from 'primeng/table';
import { UiSwitchModule } from 'ngx-ui-switch';

// Primeng Libraries

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TreeModule } from 'primeng/tree';
import { CarouselModule } from 'primeng/carousel';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmationService } from 'primeng/api';
import { FieldsetModule } from 'primeng/fieldset';
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';

import { DividerModule } from 'primeng/divider';

import { BaCardBlur } from './components/baCard/baCardBlur.directive';

import {
    BaScrollPosition,
    BaSlimScroll,
    BaThemeRun,
    HasPermissionDirective,
} from './directives';

import {
    BaAppPicturePipe,
    BaKameleonPicturePipe,
    BaProfilePicturePipe,
    BaSystemPicturePipe,
    BaSystemSvgPipe,
    BaDomSanitizerPipe,
    BaSystemSettingsPipe,
    ToNumberPipe,
} from './pipes';

import {
    BaImageLoaderService,
    BaMenuService,
    BaThemePreloader,
    BaThemeSpinner,
    AuthService,
    MockAuthService,
    // AuthGuard,
    MockUpsService,
    SystemSettingsService,
    ScannerService,
    RemittanceTransactionsService,
    DimensionServiceApiConfiguration,
    ItemMappingService,
    PayerMappingService,
    DoctorMappingService,
    SubmissionTransactionsService
} from './services';

import {
    SignalRConfiguration,
    SpecimentTransactionSignalRService,
} from './signalr';

import {
    CountService,
    SignalrService, 

} from './services/notificationService';

import {
    SharedService,
} from './services/shared';
import {
    EmailValidator,
    EqualPasswordsValidator,
    DateRangeValidator,
} from './validators';

import {
    BaseComponent,
    CheckPasswordComplexity,
} from './infrastructure';

import { BusService } from './services/eventbus/bus.service';

import { DynamicFormService } from './services/dynamicform/dynamicform.service';
import { SmartConfigService } from './services/core/smartconfig/smartconfig.service';

const NGA_DIRECTIVES = [
    BaScrollPosition,
    BaSlimScroll,
    BaThemeRun,
    HasPermissionDirective,
    BaCardBlur,
];

const NGA_PIPES = [
    BaAppPicturePipe,
    BaKameleonPicturePipe,
    BaProfilePicturePipe,
    BaSystemPicturePipe,
    BaSystemSvgPipe,
    BaDomSanitizerPipe,
    BaSystemSettingsPipe,
    ToNumberPipe,
];

const NGA_SERVICES = [
    BaImageLoaderService,
    BaThemePreloader,
    BaThemeSpinner,
    BaMenuService,
    {
        provide: AuthService,
        useClass: environment.useAuthService
            ? AuthService
            : MockAuthService
    },
    // AuthGuard,
    MockUpsService,
    ConfirmationService,
    CountService,
    ScannerService,
    SharedService,
    BusService,
    SystemSettingsService,
    DynamicFormService,
    RemittanceTransactionsService,
    DimensionServiceApiConfiguration,
    ItemMappingService,
    PayerMappingService,
    DoctorMappingService,
    SubmissionTransactionsService,
    SmartConfigService,
    SignalrService
];

const NGA_SIGNALR = [
    SignalRConfiguration,
    SpecimentTransactionSignalRService,
];

const NGA_VALIDATORS = [
    EmailValidator,
    EqualPasswordsValidator,
    DateRangeValidator,
];

const NGA_INFRASTRUCTURE = [
    BaseComponent,
    CheckPasswordComplexity,
];

@NgModule({
    declarations: [
        ...NGA_PIPES,
        ...NGA_DIRECTIVES,
        ...NGA_SYSTEM_COMPONENTS,
        ...NGA_BUSINESS_COMPONENTS,
    ],
    imports: [
        UiSwitchModule,
        DropdownModule,
        InputTextModule,
        DialogModule,
        OverlayPanelModule,
        ModalModule.forRoot(),
        TranslateModule,
        MultiSelectModule,
        TooltipModule,
        CalendarModule,
        CheckboxModule,
        AutoCompleteModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        CarouselModule,
        ConfirmDialogModule,
        EditorModule,
        ButtonModule,
        PanelModule,
        MessagesModule,
        InputTextareaModule,
        TabViewModule,
        FieldsetModule,
        RadioButtonModule,
        SelectButtonModule,
        TreeModule,
        HttpClientModule,
        TableModule,
        DividerModule,
        InputSwitchModule

    ],
    exports: [
        TranslateModule,
        DividerModule,
        DialogModule,
        InputSwitchModule,
        ...NGA_PIPES,
        ...NGA_DIRECTIVES,
        ...NGA_SYSTEM_COMPONENTS,
        ...NGA_BUSINESS_COMPONENTS,
    ],

    entryComponents: [NGA_SYSTEM_COMPONENTS, NGA_BUSINESS_COMPONENTS],
})

export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                BaThemeConfigProvider,
                BaThemeConfig,
                ...NGA_VALIDATORS,
                ...NGA_INFRASTRUCTURE,
                ...NGA_SERVICES,
                ...NGA_LAB_SERVICES,
                ...NGA_CORE_SERVICES,
                ...NGA_NURSE_SERVICES,
                ...NGA_ADMISSION_SERVICES,
                ...NGA_QUERYMODEL_SERVICES,
                ...NGA_ORDER_SERVICES,
                ...NGA_PHARMACY_SERVICES,
                ...NGA_SIGNALR,
                ...NGA_CONTROL_SERVICES,
                ...NGA_APPOINTMENT_SERVICES,
                ...NGA_MEDISPAN_SERVICES,
                ...NGA_QUERY_BUILDER_SERVICES,
                ...NGA_HI_NOTIFICATION_SERVICES
            ],
        };
    }
}

