import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { MicrobiologyPanel } from '../../../models/lab/microbiology-panel';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class MicrobiologyPanelService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.microbiologyPanel.url;
    }

    get = (): Observable<MicrobiologyPanel[]> => {
        return this._http.get<any>(this._url);
    }

    getById = (id): Observable<MicrobiologyPanel> => {
        const url = `${this._url}/${id}`;
        return this._http.get<any>(url);
    }

    add = (data: MicrobiologyPanel): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    put = (id: number, data: MicrobiologyPanel): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.put<any>(url, JSON.stringify(data));
    }

    delete = (id: number): Observable<any> => {
        const url = `${this._url}/${id}`;
        return this._http.delete(url);
    }

    getIncludeMicrobiologyAntibiotic = (microbiologyPanelId: number): Observable<MicrobiologyPanel> => {
        const url = `${this._url}?microbiologyPanelId=${microbiologyPanelId}`;
        return this._http.get<any>(url);
    }

}
