import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { AppModule } from 'app/app.module';
import { SystemSetting } from 'app/shared/models/enum/system-setting';

@Injectable()
export class DimensionServiceApiConfiguration {
    private _systemSettingsService: SystemSettingsService;
    serverUrl: string;
    server: string;
    dimensionAttachementServer: string;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.Dimension);
        this.dimensionAttachementServer = this._systemSettingsService.getAppConstantByName(SystemSetting.DimensionAttachement);
        this.serverUrl = this._systemSettingsService.getAppConstantByName(SystemSetting.DimensionUrl);
    }

    public remittance = {
        getNewRemittanceAdvice: 'Claim/GetNew',
        getRemittanceAdviceDetails: 'Claim/View',
        setRemittanceAdviceDownloaded: 'Claim/SetDownloaded',
        remittanceClaim: 'remittanceClaim',
        setRemittanceDownloadedLocal: 'RemittanceClaim/SetRemittanceDownloaded',
        getDownloadedClaims: 'RemittanceClaim/GetDownloadedClaims',
        getByClaimId: 'RemittanceClaim/GetByClaimId',
        setRemittanceStatusAsPending: 'RemittanceClaim/SetRemittanceStatusAsPending',
        setRemittanceStatusAsClose: 'RemittanceClaim/SetRemittanceStatusAsClose',
    }

    remittanceAttachement = {
        uploadAttachment: 'Attachments/UploadAttachment'
    }

    public itemMapping = {
        url: 'Item',
        getBySearch: 'Item/BySearch',
    }

    public payerMapping = {
        url: 'Payer',
        getBySearch: 'Payer/BySearch',
    }

    public doctorMapping = {
        url: 'Doctor',
        getBySearch: 'Doctor/BySearch',
    }

    submissionClaim = {
        url: 'SubmissionClaim',
        resubmission: 'SubmissionClaim/Resubmission',
        submissionByClaim: 'SubmissionClaim/SubmissionByClaim',
        submissionByStatment: 'SubmissionClaim/SubmissionByStatment',
        cancelCalimInvoice: 'SubmissionClaim/cancelCalimInvoice',
    };

}
