import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { Antibiotic } from '../../../models/lab/antibiotic';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class AntibioticService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getIngredient: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.antibiotic.url;
        this._getIngredient = this.actionUrl + _configuration.antibiotic.ingredient;
    }

    get = (): Observable<Antibiotic[]> => {
        return this._http.get<any>(this._url);
    }

    add = (data: Antibiotic): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete<any>(this._url + '?id=' + id);
    }

    getIngredient = (): Observable<Antibiotic[]> => {
        return this._http.get<Antibiotic[]>(this._getIngredient);
    }

    getAntibioticForMicrobiologyPanel = (microbiologyPanelId: number, search: string): Observable<Antibiotic[]> => {
        const url = `${this._url}?microbiologyPanelId=${microbiologyPanelId}&&search=${search}`;
        return this._http.get<any>(url);
    }

}
