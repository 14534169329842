import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { BillService } from '../../../models/orders-and-billing/bill-service';
import { BillServicesSearchFilter } from 'app/shared/models/orders-and-billing/billServicesSearchFilter';
import { StatementTransactionDiscountDto } from 'app/shared/models/orders-and-billing/statement-transaction-discount-dto';
import { AdmissionServiceApiConfiguration } from '../../admissionService/admissionServiceApiConfiguration';



@Injectable()
export class BillServicesService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getByFillter: string;
    private _getNotCanceledByFillter: string;
    private _getDiscountableElement: string;
    private _getDiscountableItemDetail: string;
    private _discount: string;
    private _invoiceIndex: string;
    private _invoiceIndexDetail: string;
    private _orderActionInvoiceDetail: string;
    private _netAmountByStatmentId: string;
    private _getLastActionDateByStatementId: string;
    private _getDischargeDate: string;
    private _getReleaseDate: string;
    private _checkIfPaid: string;


    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration, private _admissionServiceApiConfiguration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.billServices.url;
        this._getByFillter = this.actionUrl + _configuration.billServices.getByFillter;
        this._getNotCanceledByFillter = this.actionUrl + _configuration.billServices.getNotCanceledByFillter;
        this._getDiscountableElement = this.actionUrl + _configuration.billServices.getDiscountableElement;
        this._discount = this.actionUrl + _configuration.billServices.discount;
        this._invoiceIndex = this.actionUrl + _configuration.billServices.invoiceIndex;
        this._invoiceIndexDetail = this.actionUrl + _configuration.billServices.invoiceIndexDetail;
        this._getDiscountableItemDetail = this.actionUrl + _configuration.billServices.getDiscountableItemDetail;
        this._orderActionInvoiceDetail = this.actionUrl + _configuration.billServices.orderActionInvoiceDetail;
        this._netAmountByStatmentId = this.actionUrl + _configuration.billServices.netAmountByStatmentId;
        this._getLastActionDateByStatementId = this.actionUrl + _configuration.billServices.getLastActionDateByStatementId;
        this._getDischargeDate = this.actionUrl + _configuration.patientStatement.getDischargeDate;
        this._getReleaseDate = `${this._admissionServiceApiConfiguration.server}${this._admissionServiceApiConfiguration.admission.getReleaseDate}`;
        this._checkIfPaid = this.actionUrl + _configuration.billServices.checkIfPaid;
    }

    getNotCanceledByFillter = (patientId, encounterId, patientStatmentId, pageNo, rowNo, filter: BillServicesSearchFilter): Observable<any> => {
        let queryString: string = '';
        queryString += '?patientId=';
        queryString += patientId;

        queryString += '&encounterId=';
        queryString += encounterId;

        queryString += '&patientStatmentId=';
        queryString += patientStatmentId;

        queryString += '&pageNo=';
        queryString += pageNo;

        queryString += '&rowNo=';
        queryString += rowNo;

        queryString += '&itemCode=';
        if (filter.itemCode)
            queryString += filter.itemCode;

        queryString += '&serviceName=';
        if (filter.serviceName)
            queryString += filter.serviceName;

        queryString += '&quantity=';
        if (filter.quantity)
            queryString += filter.quantity;

        queryString += '&unit=';
        if (filter.unit)
            queryString += filter.unit;

        queryString += '&price=';
        if (filter.price)
            queryString += filter.price;

        queryString += '&netDiscount=';
        if (filter.netDiscount)
            queryString += filter.netDiscount;

        queryString += '&netAmount=';
        if (filter.netAmount)
            queryString += filter.netAmount;

        queryString += '&actionDate=';
        if (filter.actionDate)
            queryString += filter.actionDate.toLocaleDateString();

        return this._http.get<any>(this._getNotCanceledByFillter + queryString)
            .map(response => {
                let objects = response;
                let items: BillService[] = [];
                for (let index in objects.Results) {
                    items.push(new BillService(objects.Results[index]));
                }
                return { Results: items, TotalRecords: objects.TotalRecords } || {};
            });
    }
    getByFillter = (patientId, encounterId, patientStatmentId, pageNo, rowNo, filter: BillServicesSearchFilter): Observable<any> => {
        let queryString: string = '';
        queryString += '?patientId=';
        queryString += patientId;

        queryString += '&encounterId=';
        queryString += encounterId;

        queryString += '&patientStatmentId=';
        queryString += patientStatmentId;

        queryString += '&pageNo=';
        queryString += pageNo;

        queryString += '&rowNo=';
        queryString += rowNo;

        queryString += '&itemCode=';
        if (filter.itemCode)
            queryString += filter.itemCode;

        queryString += '&serviceName=';
        if (filter.serviceName)
            queryString += filter.serviceName;

        queryString += '&quantity=';
        if (filter.quantity)
            queryString += filter.quantity;

        queryString += '&unit=';
        if (filter.unit)
            queryString += filter.unit;

        queryString += '&price=';
        if (filter.price)
            queryString += filter.price;

        queryString += '&netDiscount=';
        if (filter.netDiscount)
            queryString += filter.netDiscount;

        queryString += '&netAmount=';
        if (filter.netAmount)
            queryString += filter.netAmount;

        queryString += '&actionDate=';
        if (filter.actionDate)
            queryString += filter.actionDate.toLocaleDateString();

        return this._http.get<any>(this._getByFillter + queryString)
            .map(response => {
                let objects = response;
                let items: BillService[] = [];
                for (let index in objects.Results) {
                    items.push(new BillService(objects.Results[index]));
                }
                return { Results: items, TotalRecords: objects.TotalRecords } || {};
            });
    }

    getDiscountableElement = (patientId, encounterId, patientStatmentId): Observable<BillService[]> => {
        return this._http.get<any>(this._getDiscountableElement + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientStatmentId=' + patientStatmentId);
    }

    getDiscountableItemDetail = (patientId, encounterId, patientStatmentId, elements): Observable<BillService[]> => {
        return this._http.get<any>(this._getDiscountableItemDetail + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientStatmentId=' + patientStatmentId +
            '&&elements=' + elements)
            .map((response: Response) => {
                let objects = response;
                let items: BillService[] = [];
                for (let index in objects) {
                    items.push(new BillService(objects[index]));
                }
                return items || [];
            });
    }


    discount = (statementTransactionDiscountDto: StatementTransactionDiscountDto): Observable<any> => {
        return this._http.post<any>(this._discount, JSON.stringify(statementTransactionDiscountDto));
    }

    getInvoiceIndex = (statementId: number, fromActionDate?: Date, toActionDate?: Date): Observable<BillService[]> => {
        const fromActionDateSrting = fromActionDate ? fromActionDate.toLocaleDateString() : null;
        const toActionDateString = toActionDate ? toActionDate.toLocaleDateString() : null;

        const url = `${this._invoiceIndex}?statementId=${statementId}&&fromActionDate=${fromActionDateSrting}&&toActionDate=${toActionDateString}`;
        return this._http.get<any>(url);
    }

    getInvoiceIndexDetail = (statementId: number, elementId: number, fromActionDate?: Date, toActionDate?: Date): Observable<BillService[]> => {
        const fromActionDateSrting = fromActionDate ? fromActionDate.toLocaleDateString() : null;
        const toActionDateString = toActionDate ? toActionDate.toLocaleDateString() : null;

        const url = `${this._invoiceIndexDetail}?statementId=${statementId}&&elementId=${elementId}&&fromActionDate=${fromActionDateSrting}&&toActionDate=${toActionDateString}`;
        return this._http.get<any>(url);
    }

    getByEncounterId = (encounterId): Observable<any> => {
        return this._http.get<any>(this.url + `/BillServicesEncounter?encounterId=${encounterId}`);
    }

    cancelServiceById = (serviceId): Observable<any> => {
        return this._http.put<any>(this.url + `/CancelBillService?serviceId=${serviceId}`, null);
    }

    getOrderActionInvoiceDetail = (orderActionId: number, actionSourceId: number): Observable<BillService[]> => {

        const url = `${this._orderActionInvoiceDetail}?orderActionId=${orderActionId}&&actionSourceId=${actionSourceId}`;

        return this._http.get<any>(url);
    }

    getNetAmountByStatmentId = (patientStatmentId): Observable<any> => {
        return this._http.get<any>(this._netAmountByStatmentId + `?patientStatmentId=${patientStatmentId}`);
    }

    getLastActionDateByStatementId = (statmentId: number): Observable<any> => {
        return this._http.get<any>(`${this._getLastActionDateByStatementId}/${statmentId}`);
    }

    getLastActionDateAndDischargeDate = (statementId: number, patientId: number, encounterId: number, patientTypeId: number): Observable<any> => {
        const dischargeDateUrl = `${this._getDischargeDate}?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`;
        const releaseDateUrl = `${this._getReleaseDate}?patientId=${patientId}&encounterId=${encounterId}`;

        let lastActionRequest = this._http.get<any>(`${this._getLastActionDateByStatementId}/${statementId}`).map(res => res);
        let dischargeDat = this._http.get<any>(dischargeDateUrl).map(res => res);
        let releaseDate = this._http.get<any>(releaseDateUrl).map(res => res);
        return forkJoin([lastActionRequest, releaseDate, dischargeDat]);
    }

    checkIfPaid = (statmentId: number): Observable<any> => {
        return this._http.get<any>(`${this._checkIfPaid}/${statmentId}`);
    }
}
