import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { QueryBuilderApiConfiguration } from '../query-builder-configuration';
import { Observable } from 'rxjs-compat/Observable';
@Injectable()
export class QueryGeneratorService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _queryGeneratorUrl: string;
    constructor(private _http: HttpClient, private _configuration: QueryBuilderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.executeQuery.live;
        this._queryGeneratorUrl = this.actionUrl + _configuration.queryGenerator.url;

    }

    get = (): Observable<any> => {
        return this._http.get<any>(`${this._queryGeneratorUrl}`);
    }

    executeQuery = (id: number): Observable<any> => {
        return this._http.get<any>(`${this._url}/${id}`);
    }
}