import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { SensitiveMicrobiology } from '../../../models/lab/sensitiveMicroBiology';
import { ServiceBase } from "app/shared/infrastructure";

@Injectable()
export class SensitiveMicroBiologyService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _changeIsCompleteStatus: string;
    private _changeIsReleaseStatus: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.sensitiveMicrobiology.url;
        this._changeIsCompleteStatus = this.actionUrl + _configuration.sensitiveMicrobiology.changeIsCompleteStatus;
        this._changeIsReleaseStatus = this.actionUrl + _configuration.sensitiveMicrobiology.changeIsReleaseStatus;
    }

    getSensitiveMicrobiology = (sensetiveId): Observable<any> => {
        const url = `${this._url}?sensetiveId=${sensetiveId}`;
        return this._http.get<any>(url);
    }

    removeMicrobiology = (id: number): Observable<any> => {
        const url = `${this._url}?id=${id}`;
        return this._http.delete(url);
    }

    insert = (data: SensitiveMicrobiology): Observable<SensitiveMicrobiology> => {
        return this._http.post<SensitiveMicrobiology>(this._url, JSON.stringify(data));
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    changeIsCompleteStatus = (sensitiveMicroBiologyId: number, isComplete: boolean): Observable<any> => {
        const url = `${this._changeIsCompleteStatus}?sensitiveMicroBiologyId=${sensitiveMicroBiologyId}&&isComplete=${isComplete}`;
        return this._http.put<any>(url, null);
    }

    changeIsReleaseStatus = (sensitiveMicroBiologyId: number, isRelease: boolean): Observable<any> => {
        const url = `${this._changeIsReleaseStatus}?sensitiveMicroBiologyId=${sensitiveMicroBiologyId}&&isRelease=${isRelease}`;
        return this._http.put<any>(url, null);
    }

}
