import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { AdmissionSearchFilter } from '../../../models/queryModel';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { TodayCashBox } from 'app/shared/models/queryModel/today-cash-box';
@Injectable()
export class TrackingSettlementService extends ServiceBase {

    private actionUrl: string;
    private _trackingSettlement: string;
    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._trackingSettlement = this.actionUrl + this._configuration.trackingSettlement.getTrackingSettlements;

    }

    getTrackingSettlements = (settlementId: any): Observable<any> => {

        const querystring = `${this._trackingSettlement}?settlementId=${settlementId}`;
           
        return this._http.get<any>(querystring);
    }
}
