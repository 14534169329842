import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { ElementConfigration } from '../../models/orders-and-billing/element-configuration';
@Injectable()
export class ElementConfigurationService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.elementsConfiguration.url;
        this.postUrl = this.actionUrl + _configuration.elementsConfiguration.url;
        this.putUrl = this.actionUrl + _configuration.elementsConfiguration.url;
        this.deleteUrl = this.actionUrl + _configuration.elementsConfiguration.url;
    }

    public get = (): Observable<ElementConfigration[]> => {
        return this._http.get<any>(this.getUrl);
    }

    public post(data: ElementConfigration) {
        return this._http.post<any>(this.postUrl, JSON.stringify(data));
    }

    public updated(data: ElementConfigration) {
        return this._http.put<any>(this.putUrl, JSON.stringify(data));
    }

    public delete(id: number) {
        return this._http.delete(this.deleteUrl + '/' + id);
    }
}