import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { OrderApiConfiguration } from 'app/shared/services/order-and-billing';
import { ActiveAntibiotic } from 'app/shared/models/queryModel/active-antibiotic';

@Injectable()
export class PharmacyAntibioticSearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration, private _orderConfiguration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.pharmacyAntibioticSearch.url;
    }

    getActiveAntibioticsByPatientId = (patientId: number): Observable<ActiveAntibiotic[]> => {
        const url = `${this.url}?patientId=` + patientId;
        return this._http.get<any>(url);
    }
}
