import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class NameService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.name.url;
    }

    public get = (name: string): Observable<string> => {
        return this._http.get<any>(this._url + '?name=' + name);
    }
}