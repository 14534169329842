import { Injectable } from '@angular/core';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { AppModule } from 'app/app.module';
import { SystemSetting } from 'app/shared/models/enum/system-setting';

@Injectable()
export class SignalRConfiguration {
    private _systemSettingsService: SystemSettingsService;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        let apiServer = this._systemSettingsService.getAppConstantByName(SystemSetting.Lab);
        this.labApiServer = apiServer.replace('/api/', '');
    }

    labApiServer: string
}
