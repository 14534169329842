import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { ControlApiConfiguration } from '../apiConfiguration';
import { Facility } from '../../../models/control/facility';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class SecurityFacilityService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getAllUrl: string;
    results: any;

    constructor(private http: HttpClient, private configuration: ControlApiConfiguration, private router: Router) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.facility.url;
        this.getAllUrl = this.actionUrl + configuration.facility.getAll;
    }

    getAll = (): Observable<Facility[]> => {
        return this.http.get<any>(this.getAllUrl);
    }

    insert = (data: any): Observable<any> => {
        return this.http.post<any>(this.url, data);
    }
    
    update = (data: any): Observable<any> => {
        return this.http.put<any>(this.url, data);
    }
}
