export interface IPatientStatementMetaData {
    Location: string;
    CreditAmount: number;
    TotalAmount: number;
    Net: number;
    GuarantorsName: string;
    GuarantorsOtherName: string;
    NetAmount: number;
    ProviderName: string;
}

export class PatientStatement implements IPatientStatementMetaData {

    Id: number;
    PatientId: number;
    EncounterId: number;
    PatientTypeId: number;
    IssueDate?: Date;
    Status: number;
    Deposit: number;
    Total: number;
    CloseDate?: Date;
    HaltReason: string;
    InvoiceId?: number;
    PayerId?: number;
    PatientFullName: string;
    PatientOtherFullName: string;
    GuarantorFullName: string;
    GuarantorOtherFullName: string;
    PatientCode: string;
    EncounterDate: Date;
    TotalDiscount: number;
    TotalPackageDiscount: number;
    PackageCoverageDiscount: number;
    PatientLocation: string;
    Remarks? : string;

    Location: string;
    CreditAmount: number;
    TotalAmount: number;
    Net: number;
    GuarantorsName: string;
    GuarantorsOtherName: string;
    SponsorName: string;
    InvoiceNet: number;
    DownPayment: number;
    EncounterStatus: number;

    CashPriceListId?: number;
    NetAmount: number;
    ProviderName: string;
    constructor() {

    }
}