export class PatientReservationSearchFilter {

    patientCode: string;
    patientName: string;
    nationalID: string;
    phoneNumber: string;
    reservationNumber: number;
    requiredDate: Date;

    constructor() { }
}
