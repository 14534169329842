import {
    AssignTaskService,
    AssignTaskDetailsService,
    NurseApiConfiguration

} from './services/nurse';

export const NGA_NURSE_SERVICES = [
    NurseApiConfiguration,
    AssignTaskDetailsService,
    AssignTaskService
];