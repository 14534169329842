import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { ControlApiConfiguration } from '../apiConfiguration';
import { UserViewModel } from '../../../models/control/user-view-model';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ChangePassword } from 'app/shared/models/control/change-password.model';
import { Control } from 'app/pages/control/control';

@Injectable()
export class UserService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getAll: string;
    private _register: string;
    private _update: string;
    private _byQuery: string;
    private _resetPassword: string;
    private changePasswordUrl: string;
    private _getUserByStaffId: string;
    private _getUserByCode: string;
    private _validateUser : string;
    private _getUserByUserName: string;
    results: any;

    constructor(private _http: HttpClient, private _configuration: ControlApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.user.url;
        this._getAll = this.actionUrl + _configuration.user.getAll;
        this._register = this.actionUrl + _configuration.user.register;
        this._update = this.actionUrl + _configuration.user.update;
        this._byQuery = this.actionUrl + _configuration.user.byQuery;
        this._resetPassword = this.actionUrl + _configuration.user.resetPassword;
        this.changePasswordUrl = this.actionUrl + _configuration.user.changePasswordUrl;
        this._getUserByStaffId = this.actionUrl + _configuration.user.getUserByStaffId;
        this._getUserByCode = `${this.actionUrl}User/ByCode`;
        this._validateUser = this.actionUrl + _configuration.user.validateUser;
        this._getUserByUserName = this.actionUrl + _configuration.user.getByUserName;
    }

    getAll = (): Observable<UserViewModel[]> => {
        return this._http.get<any>(this._getAll);
    }


    getByQuery = (query): Observable<UserViewModel[]> => {
        return this._http.get<any>(this._byQuery + '?query=' + query)
            ;
    }

    getByCode = (code): Observable<UserViewModel> => {
        return this._http.get<any>(`${this._getUserByCode}?code=${code}`)
            ;
    }

    register = (data: UserViewModel): Observable<UserViewModel> => {
        return this._http.post<UserViewModel>(this._register, JSON.stringify(data))
            ;
    }

    resetPassword = (data: UserViewModel): Observable<UserViewModel> => {
        return this._http.post<UserViewModel>(this._resetPassword, JSON.stringify(data));
    }

    update = (data: UserViewModel): Observable<UserViewModel> => {
        return this._http.put<UserViewModel>(this._update, data);
    }

    getUsersCount = (): Observable<any> => {
        return this._http.get<any>(this._url + "/UserCount");
    }

    changePassword = (data: ChangePassword): Observable<any> => {
        return this._http.post<any>(this.changePasswordUrl, JSON.stringify(data));
    }

    getUserByStaffId = (staffIds: string): Observable<UserViewModel[]> => {
        return this._http.get<any>(this._getUserByStaffId + "?staffIds=" + staffIds);
    }
    
     confirmPassword(user) {
        const response =  this._http.post<any>(this._validateUser, user);
        return response;
    }

    getUserByUserName = (username: string): Observable<any> => {
        return this._http.get<any>(this._getUserByUserName + "?username=" + username);
        
    }
}
