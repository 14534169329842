<form>
    <div class="search-title">
        <span>{{ 'Search Button' | translate }}</span>
    </div>
    <div class="row">
        <div class="col-sm-8">
            <div class="form-horizontal">
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label" for="patientCode">{{ 'Patient Code' | translate
                        }}</label>
                    <div class="col-sm-10">
                        <input type="text" name="patientCode" class="form-control"
                            placeholder="{{ 'Patient Code' | translate }}" [(ngModel)]="patientVisitSearch.PatientCode"
                            [disabled]="disablePatientCode()" maxlength="50" />
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label" for="patientName">{{ 'Patient Name' | translate
                        }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="patientName" class="form-control"
                            placeholder="{{ 'Patient Name' | translate }}" [(ngModel)]="patientVisitSearch.PatientName"
                            [disabled]="disablePatientName()" maxlength="200" />
                    </div>
                    <label class="col-sm-2 form-control-label" for="phoneNumber">{{ 'Telephone' | translate }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="phoneNumber" class="form-control"
                            placeholder="{{ 'Telephone' | translate }}" [(ngModel)]="patientVisitSearch.TelCode"
                            maxlength="50" />
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label" for="nationalCode">{{ 'National ID' | translate
                        }}</label>
                    <div class="col-sm-4">
                        <input type="text" name="nationalCode" class="form-control"
                            placeholder="{{ 'National ID' | translate }}" [(ngModel)]="patientVisitSearch.NationalCode"
                            maxlength="50" />
                    </div>
                    <label class="col-sm-2 form-control-label" for="visitDate">{{ 'Visit Date' | translate }}</label>
                    <div class="col-sm-4">
                        <p-calendar name="visitDate" dateFormat="dd/mm/yy" placeholder="{{ 'Visit Date' | translate }}"
                            [(ngModel)]="patientVisitSearch.VisitDate" [maxDate]="maxDate"></p-calendar>
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-2 form-control-label" for="sectionId">{{ 'Clinic' | translate }}</label>
                    <div class="col-sm-4">
                        <p-dropdown  [autoDisplayFirst]="false"  [virtualScroll]="true" itemSize="30" name="sectionId" [options]="clinics"
                            filter="filter" placeholder="{{ 'Clinic' | translate }}"
                            [(ngModel)]="patientVisitSearch.SectionId">
                        </p-dropdown>
                    </div>
                    <label class="col-sm-2 form-control-label" for="doctorId">{{ 'Doctor' | translate }}</label>
                    <div class="col-sm-4">
                        <p-dropdown  [autoDisplayFirst]="false"  [virtualScroll]="true" itemSize="30" name="doctorId" [options]="doctors"
                            filter="filter" placeholder="{{ 'Doctor' | translate }}"
                            [(ngModel)]="patientVisitSearch.DoctorId">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <!--Other-->
        <div class="col-sm-4 text-center">
            <img src="{{ ( 'FingerPrint-transparent' | baSystemPicture ) }}" class="finger-print row-image-header">
            <div class="row offset-sm-1 col-sm-10">
                <button type="submit" class="btn btn-outline-primary btn-block" (click)="onSubmit()">{{ 'Search
                    Button' |
                    translate }}</button>
            </div>
        </div>
    </div>
</form>