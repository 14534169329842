import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { NurseApiConfiguration } from '../apiConfiguration';
import { DatePipe } from '@angular/common';
import { ServiceBase } from 'app/shared/infrastructure';
import { Observable } from 'rxjs-compat/Observable'

@Injectable()
export class AssignTaskService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _action: string;
    private _calculateTimePerDay: string;
    private _insertTaskById: string;

    constructor(private _http: HttpClient, private _configuration: NurseApiConfiguration, private datePipe: DatePipe) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.assignTask.url;
        this._calculateTimePerDay = this.actionUrl + _configuration.assignTask.calculateTimePerDay;
        this._insertTaskById = this.actionUrl + _configuration.assignTask.insertTaskById;
    }

    get = (patientId: number, encounterId: number, patientType: number): Observable<any> => {
        return this._http.get<any>(`${this._url}/${patientId}/${encounterId}/${patientType}`);
    }

    calculateTimePerDay = (frequency: number, startDate: Date): Observable<Date[]> => {
        return this._http.get<any>(`${this._calculateTimePerDay}?frequency=${frequency}&startDate=${this.datePipe.transform(startDate, 'YYYY-MM-DDTHH:mm')}`);
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }


    insertTaskById = (data): Observable<any> => {
        return this._http.post<any>(this._insertTaskById, JSON.stringify(data));
    }
}
