import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class ApprovalInsuranceService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.approvalservices.url;
    }

    // handel approval services
    public getServicesByApprovalId = (id): Observable<any> => {

        return this._http.get<any>(this.url + '/GetServicesByApprovalId?id=' + id);
    }

    public newService = (data: any): Observable<any> => {
        return this._http.post<any>(this.url, JSON.stringify(data));
    }

    public update = (data: any, id): Observable<any> => {
        return this._http.put<any>(`${this.url}/${id}`, data);
    }

    public deleteservice = (id): Observable<any> => {
        return this._http.delete(this.url + '/deleteServicesByServiceId?id=' + id);
    }
}