import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { LabResult } from 'app/shared/models/lab/lab-result';
import { NormalResultService } from '../../services';

@Component({
    selector: 'lab-result-cmp',
    styleUrls: ['./lab-result.scss'],
    templateUrl: './lab-result.html'
})


export class LabResultComponent extends BaseComponent implements OnInit {  

    labResults: LabResult[] = [];

    ngOnInit() {
    }

    constructor(private normalResultService: NormalResultService) {
        super();

    }  

    getLabNormalResults(patientId: number, encounterId: number) {
        this.labResults = [];
        this.normalResultService.getLabResults(patientId, encounterId)
            .subscribe((data: LabResult[]) => {
                if (data && data.length > 0) {
                    this.labResults = data;
                }
                else {
                    this.labResults = [];
                }
            }, error => this.showError(error));
    }

}
