import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { Section } from '../../../models/lab/section';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class LabSectionService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;

    private _orderUrl: string;
    private _orderAction: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.section.url;
    }

    getAll = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
