import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { pagesMenuPermissions } from 'app/pages/pages.menu.permissions';
import { AuthorizeService } from '../control/authorization';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private authorizeService: AuthorizeService, private router: Router) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const componentPath = childRoute.url[0] ? childRoute.url[0].path : "";
        const requiredPermission = pagesMenuPermissions.find(pmp => pmp.path === componentPath);

         if (requiredPermission) {
            // route requires a permission, check if logged in user 
            // has permission to access it

            const isUserHasPermission = this.authorizeService.hasPermission(requiredPermission.permissionName);

            if (!isUserHasPermission) {
                this.router.navigateByUrl('/notAuthorized');
                return false;
            }
         }
        return true;
    }

    /**
     * Use it to check if user is logged in and has permission
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const url: string = state.url;
        if (url.startsWith('/pages/callback') || url.startsWith('/pages/silent') || this.authService.user) {
            // Bypass activation check
            return Promise.resolve(true);
        } else {
            var componentPath = state.url.split('/');
            const requiredPermission = pagesMenuPermissions.find(pmp => pmp.path === componentPath[componentPath.length - 1]);
            if (requiredPermission) {
                // route requires a permission, check if logged in user 
                // has permission to access it

                const isUserHasPermission = this.authorizeService.hasPermission(requiredPermission.permissionName);

                if (!isUserHasPermission) {
                    this.router.navigateByUrl('/notAuthorized');
                    return;
                }
            }
            return this.authService.signinSilent();
        }


    }
}
