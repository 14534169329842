import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable';
import { ControlApiConfiguration } from '../apiConfiguration';
import { PermissionViewModel } from '../../../models/control/permission-view-model';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PermissionService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private byQuery: string;

    results: any;

    constructor(private http: HttpClient, private configuration: ControlApiConfiguration, private router: Router) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.permission.url;
        this.byQuery = this.actionUrl + configuration.permission.byQuery;
    }

    getByQuery = (query): Observable<PermissionViewModel[]> => {
        return this.http.get<any>(`${this.byQuery}?query=${query}`);
    }
}
