import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { OrganizationSection } from '../../../models/core/organization-section';
import { StaffInfo } from '../../../models/core/staff-info';
// Models
import { Control } from 'app/pages/control/control';
import { forkJoin } from 'rxjs';
// Enums 
import { GroupCode } from 'app/shared/models/core/enums/group-code';
@Injectable()
export class LookUpService extends ServiceBase {

    private actionUrl: string;
    private _getCity: string;
    private _getFacility: string;
    private _getCountry: string;
    private _getCityByCountryId: string;
    private _getNationality: string;
    private _getDiagnose: string;
    private _getCodesByGroupKey: string;
    private _getStaff: string;
    private _getOrganizationSection: string;
    private _getOrganizationSectionById: string;
    private _getSectionByMedicalCategory: string;
    private _getNurseSectionByMedicalCategory: string;
    private _getDoctorStaff: string;
    private _getFilteredDoctorStaff: string;
    private _getFilteredStaff: string;
    private _getActiveFilteredStaff: string;
    private _getStaffById: string;
    private _url: string;
    private _getOutPatientClinicsSection: string;
    private _getOutPatientClinicsSectionByLoggedInNurse: string;
    private _getSectionForEncounter: string;
    private _getAllMedicalCategorySection: string;
    private _getFilteredAdmissionDoctorStaff: string;
    private _getAllParentAndLeafMedicalCategorySections: string;
    private _getAllMedicalCategoryUserSections: string;
    private _getCashierStaff: string;
    private _getStaffExcludedRegistered: string;
    private _getAllActiveStaffInfo: string;
    private _getAllActiveNurses: string;
    private _getStaffsByStaffIds: string;
    private _getFilteredDoctorStaffWithCode: string;
    private _getActiveMedicalCategorySection: string;

    private filter: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._getCity = this.actionUrl + _configuration.lookup.getCity;
        this._getFacility = this.actionUrl + _configuration.lookup.getFacility;
        this._getCountry = this.actionUrl + _configuration.lookup.getCountry;
        this._getSectionByMedicalCategory = this.actionUrl + _configuration.lookup.getSectionByMedicalCategory;
        this._getNurseSectionByMedicalCategory = this.actionUrl + _configuration.lookup.getNurseSectionByMedicalCategory;
        this._getCityByCountryId = this.actionUrl + _configuration.lookup.getCityByCountryId;
        this._getNationality = this.actionUrl + _configuration.lookup.getNationality;
        this._getDiagnose = this.actionUrl + _configuration.lookup.filterDiagnose;
        this._getCodesByGroupKey = this.actionUrl + _configuration.lookup.getCodesByGroupKey;
        this._getStaff = this.actionUrl + _configuration.lookup.getStaff;
        this._getOrganizationSection = this.actionUrl + _configuration.lookup.getGetOrganizationSection;
        this._getOrganizationSectionById = this.actionUrl + _configuration.lookup.getOrganizationSectionById;
        this._getDoctorStaff = this.actionUrl + _configuration.lookup.getDoctorStaff;
        this._getFilteredDoctorStaff = this.actionUrl + _configuration.lookup.getFilteredDoctorStaff;
        this._getFilteredDoctorStaffWithCode = this.actionUrl + _configuration.lookup.getFilteredDoctorStaffWithCode;
        this._getFilteredStaff = this.actionUrl + _configuration.lookup.getFilteredStaff;
        this._getActiveFilteredStaff = this.actionUrl + _configuration.lookup.getActiveFilteredStaff;
        this._url = this.actionUrl + _configuration.lookup.url;
        this._getStaffById = this.actionUrl + _configuration.lookup.getStaffById;
        this._getOutPatientClinicsSection = this.actionUrl + _configuration.lookup.getOutPatientClinicsSection;
        this._getOutPatientClinicsSectionByLoggedInNurse = this.actionUrl + _configuration.lookup.getOutPatientClinicsSectionByLoggedInNurse;
        this._getAllMedicalCategorySection = this.actionUrl + this._configuration.lookup.getAllMedicalCategorySection;
        this._getFilteredAdmissionDoctorStaff = this.actionUrl + _configuration.lookup.getFilteredAdmissionDoctorStaff;
        this._getSectionForEncounter = this.actionUrl + _configuration.lookup.sectionForEncounter;
        this._getAllParentAndLeafMedicalCategorySections =
            this.actionUrl + _configuration.lookup.getAllParentAndLeafMedicalCategorySections;
        this._getAllMedicalCategoryUserSections =
            this.actionUrl + _configuration.lookup.getAllMedicalCategoryUserSections;
        this._getCashierStaff = this.actionUrl + _configuration.lookup.getCashierStaff;
        this._getStaffExcludedRegistered = this.actionUrl + _configuration.lookup.getStaffExcludedRegistered;
        this._getAllActiveStaffInfo = this.actionUrl + _configuration.lookup.getAllActiveStaffInfo;
        this._getAllActiveNurses = this.actionUrl + this._configuration.lookup.getAllActiveNurses;
        this._getStaffsByStaffIds = this.actionUrl + this._configuration.lookup.getStaffsByStaffIds;
        this._getActiveMedicalCategorySection = this.actionUrl + this._configuration.lookup.getActiveMedicalCategorySection;
    }

    getCity = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCity);
    }

    getFacility = (): Observable<Control[]> => {
        return this._http.get<any>(this._getFacility);
    }

    getCountry = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCountry);
    }

    getSectionByMedicalCategory = (code): Observable<Control[]> => {
        return this._http.get<any>(this._getSectionByMedicalCategory + '?MedicalCategoryCode=' + code);
    }

    getNurseSectionByMedicalCategory = (code): Observable<Control[]> => {
        return this._http.get<any>(this._getNurseSectionByMedicalCategory + '?MedicalCategoryCode=' + code);
    }

    getCityByCountryId = (id: number): Observable<Control[]> => {
        return this._http.get<any>(this._getCityByCountryId + '/' + id)
    }

    getNationality = (): Observable<Control[]> => {
        return this._http.get<any>(this._getNationality);
    }

    getDiagnose = (): Observable<Control[]> => {
        return this._http.get<any>(this._getDiagnose + '/?title=');
    }

    filterDiagnose = (query: string): Observable<Control[]> => {
        return this._http.get<any>(this._getDiagnose + '/?title=' + query);
    }

    getBloodType = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "BLOOD_TYPE");
    }

    getAppointmentCancelReason = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + GroupCode.AppointmentCancelReason);
    }

    getPatientProblem = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + GroupCode.PatientProblem);
    }

    getReligion = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "RELIGION")
    }

    getDocumentType = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "DOCUMENT_TYPE")


    }

    getTelephoneType = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "TELEPHONE_TYPE")


    }

    getCurrency = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "CURRENCY_TYPE")


    }

    getGender = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "GENDER_TYPE")


    }

    getRoomStatus = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "ROOM_STATUS")


    }
    getBedStatus = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "BED_STATUS")


    }

    getDiscountType = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "DISCOUNT_TYPE")


    }

    getDealingType = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "DEALING_TYPE")


    }

    getTransferReason = (): Observable<Control[]> => {
        return this._http.get<any>(this._getCodesByGroupKey + '/?Key=' + "TRANSFER_REASON")


    }

    getStaff = (): Observable<Control[]> => {
        return this._http.get<any>(this._getStaff)


    }

    getOrganizationSection = (): Observable<Control[]> => {
        return this._http.get<any>(this._getOrganizationSection)


    }

    getOrganizationSectionById = (id): Observable<Control[]> => {
        return this._http.get<any>(this._getOrganizationSectionById + '/?id=' + id)


    }

    getAllMedicalCategorySection = (query: string = null): Observable<Control[]> => {
        let queryString: string = '';
        queryString += '?query=';
        if (query)
            queryString += query;

        return this._http.get<any>(this._getAllMedicalCategorySection + queryString)


    }

    getAllParentAndLeafMedicalCategorySections = (): Observable<Control[]> => {
        return this._http.get<any>(this._getAllParentAndLeafMedicalCategorySections)


    }

    getAllMedicalCategoryUserSections = (): Observable<OrganizationSection[]> => {
        return this._http.get<any>(this._getAllMedicalCategoryUserSections)


    }

    getStaffByType = (isAdmission: boolean, isDoctor: boolean, isActive: number): Observable<Control[]> => {
        return this._http.get<any>(this._url + '?isAdmission=' + isAdmission + '&&isDoctor=' + isDoctor + '&&isActive=' + isActive);


    }

    getDoctorStaff = (): Observable<Control[]> => {
        return this._http.get<any>(this._getDoctorStaff)


    }

    getFilteredDoctorStaff = (query, id = null): Observable<Control[]> => {
        return this._http.get<any>(this._getFilteredDoctorStaff + "?query=" + query + "&&id=" + id)


    }

    getFilteredDoctorStaffWithCode = (query): Observable<Control[]> => {
        return this._http.get<any>(this._getFilteredDoctorStaffWithCode + "?query=" + query)


    }
    getFilteredAdmissionDoctorStaff = (query): Observable<Control[]> => {
        return this._http.get<any>(this._getFilteredAdmissionDoctorStaff + "?query=" + encodeURI(query))


    }


    getFilteredStaff = (query): Observable<Control[]> => {
        return this._http.get<any>(this._getFilteredStaff + "?query=" + query)


    }

    getActiveFilteredStaff = (query): Observable<Control[]> => {
        return this._http.get<any>(this._getActiveFilteredStaff + "?query=" + query)


    }

    getOutPatientClinicsSection = (): Observable<Control[]> => {
        return this._http.get<any>(this._getOutPatientClinicsSection)


    }

    getActiveMedicalCategorySection = (): Observable<Control[]> => {
        return this._http.get<any>(this._getActiveMedicalCategorySection)


    }

    getOutPatientClinicsSectionByLoggedInNurse = (medicalCategoryCode: string = null): Observable<Control[]> => {
        medicalCategoryCode = medicalCategoryCode ? medicalCategoryCode : '';
        return this._http.get<any>(this._getOutPatientClinicsSectionByLoggedInNurse + '?medicalCategoryCode=' + medicalCategoryCode)


    }

    getStaffById = (id): Observable<Control> => {
        return this._http.get<any>(this._getStaffById + "?id=" + id)


    }

    getSectionForEncounter = (query): Observable<OrganizationSection[]> => {
        return this._http.get<any>(this._getSectionForEncounter + "?query=" + query)


    }

    getCashierStaff = (staffIds: string): Observable<Control[]> => {
        return this._http.get<any>(this._getCashierStaff + "?staffIds=" + staffIds)


    }

    getStaffExcludedRegistered = (): Observable<Control[]> => {
        return this._http.get<any>(this._getStaffExcludedRegistered)


    }

    getAllActiveStaffInfo = (): Observable<any> => {
        return this._http.get<any>(this._getAllActiveStaffInfo)


    }

    getAllActiveNurses = (): Observable<Control[]> => {
        return this._http.get<any>(this._getAllActiveNurses)


    }

    getStaffsByStaffIds = (staffIds: string): Observable<StaffInfo[]> => {
        return this._http.get<any>(this._getStaffsByStaffIds + "?staffIds=" + staffIds)


    }

    getDoctorStaffAndMedicalsections = (): Observable<any> => {
        let url1 = this._http.get<any>(this._getDoctorStaff).map(res => res);
        let url2 = this._http.get<any>(this._getAllMedicalCategoryUserSections).map(res => res);

        return forkJoin([url1, url2]);
    }

}
