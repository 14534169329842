import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { DiseaseCode } from 'app/shared/models/core/disease-code';
@Injectable()
export class DiseaseCodeService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.diseaseCode.url;
    }

    get = (pageNo, rowNo, code_Id?: string, title?: string, foreignTitle?: string, parentTitle?: string): Observable<any> => {

        code_Id = code_Id ? code_Id : '';
        title = title ? title : '';
        foreignTitle = foreignTitle ? foreignTitle : '';
        parentTitle = parentTitle ? parentTitle : '';
        const url = `${this._url}?code_Id=${code_Id}&title=${title}` +
            `&foreignTitle=${foreignTitle}&parentTitle=${parentTitle}&pageNo=${pageNo}&rowNo=${rowNo}`;
        return this._http.get<any>(url);
    }

    getByTitle = (title: string): Observable<DiseaseCode[]> => {
        return this._http.get<any>(this._url + `/DiseaseCodeByTitle?title=${title}`);
    }

    update(data: DiseaseCode) {
        return this._http.put<any>(this._url, data);
    }

    Add(data: DiseaseCode) {
        return this._http.post<any>(this._url, data);
    }

    getById = (id: number): Observable<DiseaseCode> => {
        return this._http.get<any>(this._url + `/ById?id=${id}`);
    }
}
