import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { MicrobiologyPanelAntibiotic } from '../../../models/lab/microbiology-panel-antibiotic';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class LabMicrobiologyPanelAntibioticService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.microbiologyPanelAntibiotic.url;
    }

    getMicrobiologyPanelAntibioticsByMicrobiologyPanelId = (id): Observable<MicrobiologyPanelAntibiotic[]> => {
        const url = `${this._url}?microbiologyPanelId=${id}`;
        return this._http.get<any>(url)
    }

    insert = (data: MicrobiologyPanelAntibiotic): Observable<any> => {
        return this._http.post<any>(this._url, data)
    }

    update = (data: MicrobiologyPanelAntibiotic): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    delete = (id): Observable<any> => {
        return this._http.delete(this._url + '?id=' + id);
    }
}
