import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserManager, Log, UserManagerSettings, User } from 'oidc-client';
import { AraxUser } from '../../models/auth/arax-user';
import { BaMenuService } from 'app/shared/services/baMenu/baMenu.service';
import { Observable, Subject } from 'rxjs';
import { BusService } from 'app/shared/services/eventbus/bus.service';
import { Message } from 'app/shared/services/eventbus/message';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { SystemSetting } from '../../models/enum/system-setting';
import { AuthorizeService } from '../control';
@Injectable()
export class AuthService implements OnInit, OnDestroy {

    private mgr: UserManager;

    private oidcUser: User;

    user: AraxUser = null;
    authHeaders: HttpHeaders;

    // An observable to fire when a user object changes
    // usually after signin/signout
    userChanged: Observable<AraxUser>;

    private userSubject: Subject<AraxUser> = new Subject<AraxUser>();

    readonly settings: UserManagerSettings = {
        authority: this._systemSettingsService.getAppConstantByName(SystemSetting.AuthorityService),
        client_id: 'js',
        redirect_uri: this._systemSettingsService.getAppConstantByName(SystemSetting.RedirectURI),
        post_logout_redirect_uri: this._systemSettingsService.getAppConstantByName(SystemSetting.PostLogoutRedirectURI),
        response_type: 'id_token token',
        scope: 'openid profile api',

        // silent renew will get a new access_token via an iframe 
        // just prior to the old access_token expiring (60 seconds prior)
        silent_redirect_uri: this._systemSettingsService.getAppConstantByName(SystemSetting.SilentRedirectURI),
        automaticSilentRenew: true,
        // silentRequestTimeout:10000,

        // will revoke (reference) access tokens at logout time
        revokeAccessTokenOnSignout: true,
        filterProtocolClaims: false,

        // this will toggle if profile endpoint is used
        loadUserInfo: true,

        // clockSkew: 36000,

    };

    constructor(private http: HttpClient, private menuService: BaMenuService, private busService: BusService, private _systemSettingsService: SystemSettingsService, private _authorizationService: AuthorizeService) {
        // Attach logger only on dev
        Log.logger = console;
        Log.level = Log.ERROR;

        this.mgr = new UserManager(this.settings);
        this.userChanged = this.userSubject.asObservable();
        const instance = this;

        this.mgr.events.addAccessTokenExpiring(() => {
            // Access Token Expiring
            Log.info('addAccessTokenExpiring called');
        });

        this.mgr.events.addUserUnloaded(() => {
            Log.info('addUserUnloaded called');
            // User logged out locally
            // User must be redirected to login
            // instance.mgr.signinRedirect(this.currentPageState);
        });

        this.mgr.events.addUserLoaded(user => {
            // User logged in successfully
            Log.info('addUserLoaded called');
            instance.getUser();
            instance.setUser(user);
        });

        this.mgr.events.addUserSignedOut(() => {
            Log.info('addUserSignedOut called');
            // User logged out from security
            // User must be redirected to login
            // instance.mgr.signinRedirect(this.currentPageState);
        });

        this.mgr.events.addSilentRenewError(e => {
            Log.error('silent renew error', e.message);
        });

        this.userChanged = this.userSubject.asObservable();
    }

    ngOnInit() {


    }

    ngOnDestroy(): void {
        if (this.userSubject) {
            this.userSubject.complete();
        }
    }

    get currentPageState() {
        return { state: window.location.pathname };
    }

    logout() {
        this._authorizationService.clearPermissions()
        this.mgr.signoutRedirect();
    }

    signinSilentCallback() {
        this.mgr.signinSilentCallback();
    }

    signinSilent = (): Promise<boolean> => {
        return this.mgr.signinSilent(this.currentPageState).then((user) => {
            Log.info('signinSilent called, user object is:', user);
            if (user) {
                this.setUser(user);
                return true;
            }
            this.signinRedirect();
            return false;
        }).catch((e) => {
            Log.error('signinSilent exception', e);
            this.signinRedirect();
            return false;
        });
    }

    signinRedirect() {
        this.mgr.signinRedirect(this.currentPageState);
    }

    async setUser(user?: User) {
        if (user) {
            this.oidcUser = user;
            this.setAuthHeaders(user);
            this.user = new AraxUser(user);
            const response = await this._authorizationService.getUserPermission();
            this._authorizationService.permissions = response;
            this.menuService.buildMenu(this._authorizationService);

        } else {
            this.oidcUser = null;
            this.user = null;
            this.authHeaders = new HttpHeaders();
        }
        // send new user object to subscribers,like the one in BaseComponent
        this.userSubject.next(this.user);
        // since we're not streaming objects,it's a good practice to call complete
        this.userSubject.complete();
    }

    clearState() {
        this.mgr.clearStaleState().then(() => {
            Log.info('clearStateState success');
        }).catch(e => {
            Log.error('clearStateState error', e.message);
        });
    }

    getUser() {
        this.mgr.getUser().then((user: User) => {
            Log.info('got user', user);
            this.setUser(user);

        }).catch(err => {
            Log.error('got user exception', err);
        });
    }

    removeUser() {
        this.mgr.removeUser().then(() => {
            this.setUser();
            Log.info('user removed');
        }).catch(err => {
            Log.error('removeUser', err);
        });
    }

    startSigninMainWindow() {
        this.mgr.signinRedirect().then(() => {
            Log.info('signinRedirect done');
        }).catch(err => {
            Log.error('startSigninMainWindow', err);
        });
    }

    signinRedirectCallback = (): Promise<any> => {
        const self = this;

        return new Promise((resolve) => {
            this.mgr.signinRedirectCallback().then((user: User) => {
                Log.info('signinRedirectCallback', user);
                window.history.replaceState({},
                    window.document.title,
                    window.location.origin + window.location.pathname);
                self.getUser();
                resolve(user.state);
            }).catch(err => {
                Log.error('signinRedirectCallback', err);
                resolve(null);
            });
        });
    }

    startSignoutMainWindow() {
        this.mgr.signoutRedirect().then(resp => {
            Log.info('signed out', resp);
        }).catch(err => {
            Log.error('signed out exception', err);
        });
    }

    endSignoutMainWindow() {
        this.mgr.signoutRedirectCallback().then(resp => {
            Log.info('signed out', resp);
            this.setUser();

        }).catch(err => {
            Log.error('signed out exception', err);
        });
    }

    private setAuthHeaders(user: User) {
        this.authHeaders = new HttpHeaders();
        this.authHeaders.append('Authorization', `${user.token_type} ${user.access_token}`);
        this.authHeaders.append('Content-Type', 'application/json');
        this.busService.publishMessage(new Message());
    }

    getTokenType() {
        if (this.oidcUser?.token_type) {
            return `${this.oidcUser?.token_type} ${this.oidcUser?.access_token}`;
        } else {
            return '';
        }
    }
}

