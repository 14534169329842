import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { PricingProcessOutput } from 'app/shared/models/orders-and-billing/pricing-process-output';

@Injectable()
export class PriceService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = `${this.actionUrl}/Price`;
    }

    getItemPrice = (encounterId: number, patientType: number, patientId: number, itemId: number, quantity: number, sectionId: number, unitId?: number, variablePrice?: number, isDischargePrescription: boolean = false, orderDiagnoses: string = ''): Observable<PricingProcessOutput[]> => {
        return this._http.get<any>(`${this.getUrl}/ItemPrice?itemId=${itemId}
        &&encounterId=${encounterId}
        &&patientType=${patientType}
        &&patientId=${patientId}
        &&unitId=${unitId}
        &&quantity=${quantity}
        &&variablePrice=${variablePrice}
        &&isDischargePrescription=${isDischargePrescription}
        &&sectionId=${sectionId}
        &&orderDiagnoses=${orderDiagnoses}`)
    }

    getCashPrice = (itemId: number): Observable<number> => {
        return this._http.get<any>(`${this.getUrl}/CashPrice?itemId=${itemId}`)

    }

    getStandardCashPrice = (itemId: number, patientType: number): Observable<number> => {
        return this._http.get<any>(`${this.getUrl}/StandardCashPrice?itemId=${itemId}&patientType=${patientType}`)

    }
}
