<form (ngSubmit)="onSubmit()">
    <div class="search-title">
        <span>{{ 'Search Button' | translate }}</span>
    </div>
    <div class="row">
        <div class="col-sm-8">
            <div class="form-horizontal">
                <div class="form-group row form-group-default">
                    <label class="col-sm-3 form-control-label">{{ 'Patient Code' | translate }}</label>
                    <div class="col-sm-9">
                        <input type="text" name="patientCode" class="form-control" id="patientCode"
                            placeholder="{{ 'Patient Code' | translate }}" [(ngModel)]="filterStatementTransaction.Mrn">
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-3 form-control-label">{{ 'Patient Name' | translate }}</label>
                    <div class="col-sm-9">
                        <input type="text" name="patientName" class="form-control" id="patientName"
                            placeholder="{{ 'Patient Name' | translate }}"
                            [(ngModel)]="filterStatementTransaction.PatientName">
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-3 form-control-label">{{ 'Transaction Type' | translate }}</label>
                    <div class="col-sm-9">
                        <p-dropdown  [autoDisplayFirst]="false"  [virtualScroll]="true" itemSize="30" name="paymentCode" class="form-control"
                            filter="filter" [options]="paymentCodes" placeholder="Select Transaction Type"
                            [(ngModel)]="filterStatementTransaction.PaymentCodeId"></p-dropdown>
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-3 form-control-label">{{ 'Transaction By' | translate }}</label>
                    <div class="col-sm-9">
                        <p-autoComplete name="cashier" [(ngModel)]="autoCompleteObject" [suggestions]="cashiers"
                            (completeMethod)="filterCashiers($event)" emptyMessage="No Results" field="FullName"
                            (onSelect)="setCashier($event)" placeholder="{{ 'Select Transaction By' | translate }}"
                            [minLength]="1" forceSelection="true" (onBlur)="checkIfValidCashier($event)"
                            (onClear)="clearFieldsOfSelectedCashier($event)">
                        </p-autoComplete>
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-3 form-control-label">{{ 'Transacation From' | translate }}</label>
                    <div class="col-sm-9">
                        <p-calendar name="transacationFrom" dateFormat="dd/mm/yy"
                            placeholder="{{ 'Transacation From' | translate }}"
                            [(ngModel)]="filterStatementTransaction.TransactionDateFrom"></p-calendar>
                    </div>
                </div>
                <div class="form-group row form-group-default">
                    <label class="col-sm-3 form-control-label">{{ 'Transacation To' | translate }}</label>
                    <div class="col-sm-9">
                        <p-calendar name="transacationTo" dateFormat="dd/mm/yy"
                            placeholder="{{ 'Transacation To' | translate }}"
                            [(ngModel)]="filterStatementTransaction.TransactionDateTo"></p-calendar>
                    </div>
                </div>
            </div>
        </div>
        <!--Other-->
        <div class="col-sm-4 text-center">
            <img src="{{ ( 'FingerPrint-transparent' | baSystemPicture ) }}" class="finger-print row-image-header">
            <div class="col-sm-12">
                <button type="submit" class="btn btn-outline-primary btn-block">{{ 'Search Button' | translate
                    }}</button>
            </div>
        </div>
    </div>
</form>