import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { BillSerial } from '../../../models/orders-and-billing/bill-serial';

@Injectable()
export class BillSerialsService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private deleteUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.billSerials.url;
        this.postUrl = this.actionUrl + _configuration.billSerials.url;
        this.putUrl = this.actionUrl + _configuration.billSerials.url;
        this.deleteUrl = this.actionUrl + _configuration.billSerials.url;
    }

    get = (): Observable<any> => {
        return this._http.get<any>(this.getUrl);
    }

    post = (data: any): Observable<any> => {
        return this._http.post<any>(this.postUrl, JSON.stringify(data));
    }

    updated = (data: any): Observable<any> => {
        return this._http.put<any>(this.putUrl, JSON.stringify(data));
    }

    delete = (id: number): Observable<any> => {
        const query = `/${id}`;
        return this._http.delete(this.deleteUrl + query);
    }
}
