import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CoverageClass } from "app/shared/models/orders-and-billing/coverage-class";

@Injectable()
export class CoverageClassService extends ServiceBase {
    private _actionUrl: string;
    private _url: string;
    private _getClassesByContractId: string;
    private _getPayerClassByPayerIdContainContract: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.coverageClass.url;
        this._getClassesByContractId = this._actionUrl + _configuration.coverageClass.getClassesByContractId;
        this._getPayerClassByPayerIdContainContract = this._actionUrl + _configuration.coverageClass.getPayerClassByPayerIdContainContract;
    }

    public getClassById = (id: number): Observable<CoverageClass> => {
        return this._http.get<any>(this._url + '?id=' + id)
    }

    public getClassesByContractId = (contractId: number): Observable<CoverageClass[]> => {
        return this._http.get<any>(this._getClassesByContractId + '?contractId=' + contractId)
    }

    public getPayerClassByPayerIdContainContract = (payerId: number, isActive?: boolean): Observable<CoverageClass[]> => {
        return this._http.get<any>(this._getPayerClassByPayerIdContainContract + '?payerId=' + payerId + '&isActive=' + isActive)
    }

    public add = (data: CoverageClass): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data))
    }

    public update = (data: CoverageClass): Observable<any> => {
        return this._http.put<any>(this._url, data)
    }

}
