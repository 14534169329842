<div class="form-horizontal">
    <div class="form-group row form-group-default">
        <div class="col-sm-6 form-control-label">
            <span class="fw-600 information-title">Blood Group</span>: <span class="information-result">{{
                patientInfoDto?.BloodGroupTitle }}</span>
        </div>
        <div class="col-sm-6 form-control-label">
            <span class="fw-600 information-title">Height (CM)</span>: <span class="information-result">{{
                patientVitalSignInfoDto?.Height }}</span>
        </div>
    </div>
    <div class="form-group row form-group-default">
        <div class="col-sm-6 form-control-label">
            <span class="fw-600 information-title">Weight (KG)</span>: <span class="information-result">{{
                patientVitalSignInfoDto?.Weight }}</span>
        </div>
        <div class="col-sm-6 form-control-label">
            <span class="fw-600 information-title">BMI</span>: <span class="information-result">{{
                patientVitalSignInfoDto?.BMI }}</span>
        </div>
    </div>
    <div class="form-group row form-group-default">
        <div class="col-sm-12 pad form-control-label">
            <span class="fw-600 information-title">Address</span>: <span class="information-title">{{
                patientInfoDto?.Address }}</span>
        </div>
    </div>
    <div *ngIf="patientTypeId == patientTypes.inPatient" class="form-group row form-group-default">
        <div class="col-sm-12 pad form-control-label">
            <span class="fw-600 information-title">Admission Reason</span>: <span class="information-result">{{
                admissionReason?.Title }}</span>
        </div>
    </div>
    <br />
    <h3>Diagnosis</h3>
    <div class="form-group row form-group-default">
        <p-table #dtDiagnosis [value]="patientDiagnosis" [rows]="5" [paginator]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th><span>{{'Diagnosis' | translate}}</span></th>
                    <th><span>{{'Diagnosis Type' | translate}}</span></th>
                    <th><span>{{'Appearance Date' | translate}}</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td><span>{{rowData?.Diagnose?.Title}}</span></td>
                    <td>
                        <span>{{ rowData.DiagnosisTypeId == diagnosisType.Principle ? 'Initial' : ''}}</span>
                        <span>{{ rowData.DiagnosisTypeId == diagnosisType.Final ? 'Final' : ''}}</span>
                    </td>
                    <td><span>{{ rowData.AppearanceDate | date: 'dd/MM/yyyy'}}</span></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <br />
    <h3>Allergy</h3>
    <div class="form-group row form-group-default">
        <p-table #dtAllergy [value]="patientAllergies" [rows]="5" [paginator]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th><span>{{'Substance' | translate}}</span></th>
                    <th><span>{{'Allergy' | translate}}</span></th>
                    <th><span>{{'Onset Date' | translate}}</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>
                        <span>{{ rowData.AllergyType ? rowData.AllergyType.Title : ''}}</span>
                        <span>{{ rowData.SystemAllergy && rowData.SystemAllergy.SourceId == allergySources.Class ?
                            'Class' :
                            ''
                            }}</span>
                        <span>{{ rowData.SystemAllergy && rowData.SystemAllergy.SourceId == allergySources.Ingredient ?
                            'Ingredient' : '' }}</span>
                    </td>
                    <td>            
                        <span>{{ rowData.Allergy ? rowData.Allergy.Title : ''}}</span>
                        <span>{{ rowData.SystemAllergy ? rowData.SystemAllergy.Name : ''}}</span>
                    </td>
                    <td><span>{{ rowData.OnsetDate | date: 'dd/MM/yyyy'}}</span></td>
                </tr>
            </ng-template>
        </p-table>

    </div>
    <br />
    <h3>Active Medications</h3>
    <div class="form-group row form-group-default">
        <p-table #dtMedication [value]="activeMedicationDtos" [rows]="5" [paginator]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th><span>{{'Medication Name' | translate}}</span></th>
                    <th><span>{{'Order Date' | translate}}</span></th>
                    <th><span>{{'Stop Date' | translate}}</span></th>
                    <th><span>{{'Order By' | translate}}</span></th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td><span>{{rowData.ItemDescription | translate}}</span></td>
                    <td><span>{{ rowData.StartDate | date: 'dd/MM/yyyy'}}</span></td>
                    <td><span>{{ rowData.StopDate | date: 'dd/MM/yyyy'}}</span></td>
                    <td><span>{{rowData.DoctorFullName | translate}}</span></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="container-fluid p-0" *ngIf="showLabResults">
        <br>
      <lab-result-cmp #labResultsCmp></lab-result-cmp>
    </div>
    <br />
    <h3>{{'Chief Complaints' | translate}}</h3>
    <div class="form-group row form-group-default">
        <p-table #dtMedication [value]="patientEncounterProblems" [rows]="5" [paginator]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th><span>{{'Main Complaint' | translate}}</span></th>
                    <th><span>{{'Status' | translate}}</span></th>
                    <th><span>{{'Onset Date' | translate}}</span></th>
                    <th><span>{{'Resolved Date' | translate}}</span></th>
                    <th><span>{{'Duration' | translate}}</span></th>
                    <th><span>{{'Associated' | translate}}</span></th>
                    <th><span>{{'Primary' | translate}}</span></th>
                    <th><span>{{'Created By - Date' | translate}}</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td><span>{{getProblemTitle(rowData)}}</span></td>
                    <td><span>{{rowData.Status ? 'Resolved' : 'Active'}}</span></td>
                    <td><span>{{rowData.OnSetDate | date: 'dd/MM/yyyy'}}</span></td>
                    <td><span>{{rowData.ResolvedDate | date: 'dd/MM/yyyy'}}</span></td>
                    <td><span>{{rowData.PeriodValue}} {{getDurationTypeLabel(rowData.PeriodType)}}</span></td>
                    <td><span class="col-sm-6 px-0 header-item-link"><a (click)="viewAssociatedModal(rowData.Associated)">{{ 'View Associated' | translate }}</a></span></td>
                    <td><span class="col-sm-6 px-0 header-item-link"><a (click)="viewPrimaryModal(rowData.Primary)">{{ 'View Primary' | translate }}</a></span></td>
                    <td><span>{{rowData.StaffCreatedBy.FullName}} {{rowData.CreatedDate | date: 'dd/MM/yyyy'}}</span></td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div bsModal #associatedModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade"
    tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" (click)="associatedModal.hide()"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">{{'Associated' | translate}}</h4>
                </div>
                <div class="modal-body">
                    <span>{{associated}}</span>
                </div>
            </div>
        </div>
    </div>

    <div bsModal #primaryModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade"
    tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" (click)="primaryModal.hide()"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">{{'Primary' | translate}}</h4>
                </div>
                <div class="modal-body">
                    <span>{{primary}}</span>
                </div>
            </div>
        </div>
    </div>
    
</div>