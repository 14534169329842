import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { SystemCurrency } from 'app/shared/models/orders-and-billing/system-currency';

@Injectable()
export class SystemCurrencyService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getDefaultCodeUrl: string;
    private getAllUrl: string;
    private getDefaultUrl: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.systemCurrency.url;
        this.getDefaultCodeUrl = this.actionUrl + configuration.systemCurrency.defaultCode;
        this.getDefaultUrl = this.actionUrl + configuration.systemCurrency.default;
        this.getAllUrl = this.actionUrl + configuration.systemCurrency.all;
    }

    getDefaultCode = (): Observable<string> => {
        return this.http.get<any>(this.getDefaultCodeUrl);
    }

    getDefault = (): Observable<SystemCurrency> => {
        return this.http.get<any>(this.getDefaultUrl);
    }

    getAll = (): Observable<SystemCurrency[]> => {
        return this.http.get<any>(this.getAllUrl);
    }
}
