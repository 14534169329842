import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { AppModule } from 'app/app.module';
import { SystemSetting } from 'app/shared/models/enum/system-setting';

@Injectable()
export class AppointmentServiceApiConfiguration {

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.Appointment);
    }
    private _systemSettingsService: SystemSettingsService;

    server: string;

    appointment = {
        url: 'Appointment',
        visitSearch: 'Appointment/Visit/Search',
        cancelAppointment: 'Appointment/Cancel',
        getPatientDataById: 'Appointment/PatientDataById',
        getVisitDataById: 'Appointment/VisitDataById',
    };
}
