import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class PatientDocumentService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private deleteUrl: string;

    constructor(private http: HttpClient, private configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.getUrl = this.actionUrl + configuration.patientDocument.url;
        this.postUrl = this.actionUrl + configuration.patientDocument.url;
        this.deleteUrl = this.actionUrl + configuration.patientDocument.url;
    }

    public get = (patientId): Observable<any> => {
        var queryString = "?patientId=" + patientId;

        return this.http.get<any>(this.getUrl + queryString);
    }

    public post(data: any) {

        return this.http.post<any>(this.postUrl, JSON.stringify(data));
    }

    public delete(id: number) {
        return this.http.delete(this.deleteUrl + "?id=" + id);
    }
}