import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { SystemSetting } from 'app/shared/models/enum/system-setting';
import { AppModule } from 'app/app.module';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';


@Injectable()
export class ControlApiConfiguration {

    private _systemSettingsService: SystemSettingsService;
    server: string;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = `${this._systemSettingsService.getAppConstantByName(SystemSetting.AuthorityService)}/api/`;
    }

    user = {
        url: 'User',
        getAll: 'User/Users',
        update: 'User/Update',
        register: 'User/Register',
        byQuery: 'User/ByQuery',
        resetPassword: 'User/ResetPassword',
        changePasswordUrl: 'User/ChangePassword',
        getUserByStaffId: 'User/UserByStaffId',
        validateUser : 'User/ValidateUser',
        getByUserName : 'User/ByUserName'
    };

    role = {
        url: 'Role',
        byQuery: 'Role/ByQuery',
        createRoleUrl: 'Role/CreateRole',
        deleteRoleUrl: 'Role/DeleteRole',
    };

    rolePermission = {
        url: 'RolePermission',
        getControlMenuPermissionsUrl: 'RolePermission/GetControlMenuPermissions',
        getRolePermissionsUrl: 'RolePermission/GetRolePermissions',
        updateRolePermissionsUrl: 'RolePermission/UpdateRolePermissions',
        userPermissions: 'RolePermission/UserPermissions',
    };

    facility = {
        url: 'Facility',
        getAll: 'Facility/Facilities',
    };

    userRole = {
        url: 'UserRole',
        userByRole: 'UserRole/UserByRole',
        add: 'UserRole/Add',
        update: 'UserRole/Update',
        delete: 'UserRole/Delete',
        getRolesByUserUrl: 'UserRole/RolesByUser'
    };

    permission = {
        url: 'Permission',
        byQuery: 'Permission/ByQuery',
    }

    passwordPolicy = {
        url: 'PasswordPolicy'
    }
}
