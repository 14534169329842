import { FormGroup, FormControl } from '@angular/forms';


// Password strengths
export enum PasswordCheckStrength {
    Short = 1,
    Common = 2,
    Weak = 3,
    Ok = 4,
    Strong = 5,
}

export class CheckPasswordComplexity {
    passwordStrength: number;
    passwordMessage: string;
    constructor() {
    }


    // check-password - complexity


    private commonPasswordPatterns = /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/;

    //
    // Checks if the given password matches a set of common password
    //

    // Expected length of all passwords
    MinimumLength(): number {
        return 8;
    }

    isPasswordCommon(password: string): boolean {
        return this.commonPasswordPatterns.test(password);
    }

    checkPasswordStrength(password) {
        // Build up the strenth of our password
        let numberOfElements = 0;
        numberOfElements = /.*[a-z].*/.test(password) ? ++numberOfElements : numberOfElements;      // Lowercase letters
        numberOfElements = /.*[A-Z].*/.test(password) ? ++numberOfElements : numberOfElements;      // Uppercase letters
        numberOfElements = /.*[0-9].*/.test(password) ? ++numberOfElements : numberOfElements;      // Numbers
        numberOfElements = /[^a-zA-Z0-9]/.test(password) ? ++numberOfElements : numberOfElements;   // Special characters (inc. space)
        // Assume we have a poor password already
        this.passwordStrength = PasswordCheckStrength.Short;
        // Check then strenth of this password using some simple rules
        if (password === null || password.length < this.MinimumLength()) {
            this.passwordStrength = PasswordCheckStrength.Short;
        } else if (this.isPasswordCommon(password)) {
            this.passwordStrength = PasswordCheckStrength.Common;
        } else if (numberOfElements === 0 || numberOfElements === 1 || numberOfElements === 2) {
            this.passwordStrength = PasswordCheckStrength.Weak;
        } else if (numberOfElements === 3) {
            this.passwordStrength = PasswordCheckStrength.Ok;
        } else {
            this.passwordStrength = PasswordCheckStrength.Strong;
        }
        this.passwordMessage = ' Your Password Is ';

        this.passwordMessage = this.passwordMessage + PasswordCheckStrength[this.passwordStrength];
        const result = { PasswordStrength: this.passwordStrength, PasswordMessage: this.passwordMessage };
        return result;
    }
}
