export class PatientDischargeSearchFilter {

    patientCode: string;
    patientName: string;
    nationalID: string;
    phoneNumber: string;
    admissionDate: Date;
    admissionDoctor: number;
    section: number;

    constructor() { }
}
