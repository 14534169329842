import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { QueryBuilderApiConfiguration } from '../query-builder-configuration';
import { Observable } from 'rxjs-compat/Observable';
@Injectable()
export class DashboardGeneratorService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _byRoleIds: string;
    constructor(private _http: HttpClient, private _configuration: QueryBuilderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.dashboardGenerator.url;
        this._byRoleIds = this.actionUrl + _configuration.dashboardGenerator.byRoleIds;

    }

    getByRoleId = (id: number): Observable<any> => {
        return this._http.get<any>(`${this._url}/${id}`);
    }

    getByRoleIds = (data: any): Observable<any> => {
        return this._http.post<any>(`${this._byRoleIds}`, data);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(`${this._url}`, data);
    }
}