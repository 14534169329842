import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';

import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { DoctorFeesStationFilter } from 'app/shared/models/queryModel/doctor-fees-station-filter';
import { DoctorFeesStation } from 'app/shared/models/queryModel/doctor-fees-station';
import { map } from 'rxjs/operators';

@Injectable()
export class DoctorFeesStationService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private doctorFeesStationbBySearchUrl: string;
    private exportDoctorFeesUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.doctorFeesStation.url;
        this.doctorFeesStationbBySearchUrl = this.actionUrl + _configuration.doctorFeesStation.doctorFeesStationbBySearch;
        this.exportDoctorFeesUrl = this.actionUrl + _configuration.doctorFeesStation.exportDoctorFees;
    }

    getDoctorFeesStationBySearch = (doctorFeesStationFilter: DoctorFeesStationFilter, page: number, rows: number): Observable<DoctorFeesStation[]> => {

        const doctorId = doctorFeesStationFilter.DoctorId ? doctorFeesStationFilter.DoctorId : null;
        const patientCode = doctorFeesStationFilter.PatientCode ? doctorFeesStationFilter.PatientCode : '';
        const patientName = doctorFeesStationFilter.PatientName ? doctorFeesStationFilter.PatientName : '';
        const patientTypeId = doctorFeesStationFilter.PatientTypeId ? doctorFeesStationFilter.PatientTypeId : null;
        const encounterId = doctorFeesStationFilter.EncounterId ? doctorFeesStationFilter.EncounterId : null;
        const billServiceDealingTypeId = doctorFeesStationFilter.BillServiceDealingTypeId ? doctorFeesStationFilter.BillServiceDealingTypeId : null;
        const paymentStatus = doctorFeesStationFilter.PaymentStatus ? doctorFeesStationFilter.PaymentStatus : null;
        var encounterFromString = doctorFeesStationFilter.EncounterFromDate ? doctorFeesStationFilter.EncounterFromDate.toISOString() : null;
        var encounterToString = doctorFeesStationFilter.EncounterToDate ? doctorFeesStationFilter.EncounterToDate.toISOString() : null;
        const statementStatusId = doctorFeesStationFilter.StatementStatusId ? doctorFeesStationFilter.StatementStatusId : null;
        var issuedFromDate = doctorFeesStationFilter.IssuedFromDate ? doctorFeesStationFilter.IssuedFromDate.toISOString() : null;
        var issuedToDate = doctorFeesStationFilter.IssuedToDate ? doctorFeesStationFilter.IssuedToDate.toISOString() : null;
        const settlementFilter = doctorFeesStationFilter.SettlementFilter ? doctorFeesStationFilter.SettlementFilter : null;
        var claimFromDateString = doctorFeesStationFilter.ClaimFromDate ? doctorFeesStationFilter.ClaimFromDate.toISOString() : null;
        var claimToDateString = doctorFeesStationFilter.ClaimToDate ? doctorFeesStationFilter.ClaimToDate.toISOString() : null;
        return this._http.get<any>(this.doctorFeesStationbBySearchUrl
            + '?DoctorId=' + doctorId
            + '&&PatientCode=' + patientCode
            + '&&PatientName=' + patientName
            + '&&PatientTypeId=' + patientTypeId
            + '&&EncounterId=' + encounterId
            + '&&BillServiceDealingTypeId=' + billServiceDealingTypeId
            + '&&PaymentStatus=' + paymentStatus
            + '&&EncounterFromDate=' + encounterFromString
            + '&&EncounterToDate=' + encounterToString
            + '&&StatementStatusId=' + statementStatusId
            + '&&IssuedFromDate=' + issuedFromDate
            + '&&IssuedToDate=' + issuedToDate
            + '&&Page=' + page
            + '&&Rows=' + rows
            + '&&SettlementFilter=' + settlementFilter
            + '&&ClaimFromDate=' + claimFromDateString
            + '&&ClaimToDate=' + claimToDateString
        );
    }

    exportDoctorFeesStation = (doctorFeesStationFilter: DoctorFeesStationFilter, page: number, rows: number): Observable<any> => {

        const doctorId = doctorFeesStationFilter.DoctorId ? doctorFeesStationFilter.DoctorId : null;
        const patientCode = doctorFeesStationFilter.PatientCode ? doctorFeesStationFilter.PatientCode : '';
        const patientName = doctorFeesStationFilter.PatientName ? doctorFeesStationFilter.PatientName : '';
        const patientTypeId = doctorFeesStationFilter.PatientTypeId ? doctorFeesStationFilter.PatientTypeId : null;
        const encounterId = doctorFeesStationFilter.EncounterId ? doctorFeesStationFilter.EncounterId : null;
        const billServiceDealingTypeId = doctorFeesStationFilter.BillServiceDealingTypeId ? doctorFeesStationFilter.BillServiceDealingTypeId : null;
        const paymentStatus = doctorFeesStationFilter.PaymentStatus ? doctorFeesStationFilter.PaymentStatus : null;
        var encounterFromString = doctorFeesStationFilter.EncounterFromDate ? doctorFeesStationFilter.EncounterFromDate.toISOString() : null;
        var encounterToString = doctorFeesStationFilter.EncounterToDate ? doctorFeesStationFilter.EncounterToDate.toISOString() : null;
        const statementStatusId = doctorFeesStationFilter.StatementStatusId ? doctorFeesStationFilter.StatementStatusId : null;
        var issuedFromDate = doctorFeesStationFilter.IssuedFromDate ? doctorFeesStationFilter.IssuedFromDate.toISOString() : null;
        var issuedToDate = doctorFeesStationFilter.IssuedToDate ? doctorFeesStationFilter.IssuedToDate.toISOString() : null;

        const url = this.exportDoctorFeesUrl
            + '?DoctorId=' + doctorId
            + '&&PatientCode=' + patientCode
            + '&&PatientName=' + patientName
            + '&&PatientTypeId=' + patientTypeId
            + '&&EncounterId=' + encounterId
            + '&&BillServiceDealingTypeId=' + billServiceDealingTypeId
            + '&&PaymentStatus=' + paymentStatus
            + '&&EncounterFromDate=' + encounterFromString
            + '&&EncounterToDate=' + encounterToString
            + '&&StatementStatusId=' + statementStatusId
            + '&&IssuedFromDate=' + issuedFromDate
            + '&&IssuedToDate=' + issuedToDate
            + '&&Page=' + page
            + '&&Rows=' + (rows + 50);

        return this._http.get<any>(url,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }
}
