import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { PatientOrderService } from '../../../models/queryModel/patient-order-service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientCancelOrderSearch } from '../../../models/queryModel/patient-cancel-order-search';
import { PatientOrderCategorySearch } from 'app/shared/models/queryModel/patient-order-category-search';
import { CategoryInfoDTO } from '../../../models/queryModel/category-info-dto';
import { patientBasicSearchResult } from '../../../models';

@Injectable()
export class PatientOrderSearchService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private orderCategories: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientOrderSearch.url;
        this.orderCategories = this.actionUrl + _configuration.patientOrderSearch.orderCategories;
    }

    getPatientCancelOrderSearch = (patientCancelOrderSearch: PatientCancelOrderSearch): Observable<PatientOrderService[]> => {
        const categoryId = patientCancelOrderSearch.CategoryId;
        const patientId = patientCancelOrderSearch.PatientId;
        const encounterId = patientCancelOrderSearch.EncounterId ? patientCancelOrderSearch.EncounterId : null;
        const patientTypeId = patientCancelOrderSearch.PatientTypeId ? patientCancelOrderSearch.PatientTypeId : null;
        const itemTitle = patientCancelOrderSearch.ItemTitle ? patientCancelOrderSearch.ItemTitle : '';
        const orderActionStatus = patientCancelOrderSearch.OrderActionStatus ? patientCancelOrderSearch.OrderActionStatus : null;
        const encounterDate = patientCancelOrderSearch.EncounterDate ? patientCancelOrderSearch.EncounterDate.toISOString() : null;
        const orderDate = patientCancelOrderSearch.OrderDate ? patientCancelOrderSearch.OrderDate.toISOString() : null;
        const orderSectionId = patientCancelOrderSearch.OrderSectionId ? patientCancelOrderSearch.OrderSectionId : null;
        const orderById = patientCancelOrderSearch.OrderById ? patientCancelOrderSearch.OrderById : null;
        const isExcluded = patientCancelOrderSearch.IsExcluded;
        const itemCode = patientCancelOrderSearch.ItemCode ? patientCancelOrderSearch.ItemCode : '';
        const createdUserId = patientCancelOrderSearch.CreatedUser ? patientCancelOrderSearch.CreatedUser : null;
        const qty = patientCancelOrderSearch.Quantity ? patientCancelOrderSearch.Quantity : (patientCancelOrderSearch.Quantity == 0 ? 0 : null);
        const orderId = patientCancelOrderSearch.OrderId ? patientCancelOrderSearch.OrderId : (patientCancelOrderSearch.OrderId == 0 ? 0 : null);
        const page = patientCancelOrderSearch.Page ? patientCancelOrderSearch.Page : 0;
        const rows = patientCancelOrderSearch.Rows ? patientCancelOrderSearch.Rows : 10;

        const url = `${this.url}?PatientId=${patientId}&EncounterId=${encounterId}&PatientTypeId=${patientTypeId}&ItemTitle=${itemTitle}&OrderActionStatus=${orderActionStatus}&EncounterDate=${encounterDate}`
            + `&OrderDate=${orderDate}&OrderSectionId=${orderSectionId}&OrderById=${orderById}&IsExcluded=${isExcluded}&CreatedUser=${createdUserId}&Quantity=${qty}&ItemCode=${itemCode}&&OrderId=${orderId}&&page=${page}&&rows=${rows}&&categoryId=${categoryId}`;

        return this._http.get<any>(url);
    }

    getOrderCategories = (patientCancelOrderSearch: PatientOrderCategorySearch): Observable<CategoryInfoDTO[]> => {
        const patientId = patientCancelOrderSearch.PatientId;
        const encounterId = patientCancelOrderSearch.EncounterId ? patientCancelOrderSearch.EncounterId : null;
        const patientTypeId = patientCancelOrderSearch.PatientTypeId ? patientCancelOrderSearch.PatientTypeId : null;

        const url = `${this.orderCategories}?PatientId=${patientId}&EncounterId=${encounterId}&PatientTypeId=${patientTypeId}`;

        return this._http.get<any>(url);
    }

}
