<div>
    <div class="row mx-0 favorite">
        <div class="col-sm-12">
            <div class="row clearfix border-bottom" *ngFor='let item of favorites '>
                <div class="col-sm-9 px-0">
                    <button (click)="goto(item)" type="button" class="shortuct-item text-left">{{item.Title}}</button>
                </div>
                <div class="col-sm-3 px-0 text-right pt-2 c-cursor pr-2">
                    <a (click)="onRemoveItem(item)"><i class="fa fa-times text-danger"></i></a>
                </div>
            </div>
        </div>
    </div>

</div>