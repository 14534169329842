import { Item } from 'app/shared/models/orders-and-billing/item';

export class ElementConfigration {
     Code: number;
     Description: string;
     OtherDescription: string;
     ItemCode: string;
     OrderSection: number;
     serviceCodeAndTitle: string = '';
     Item: Item = new Item();
     OrderBy: number;
}