import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
/// import filter 
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class PatientCalculateVisitTransferService extends ServiceBase {

  private actionUrl: string;
  private _post: string;
  private _action: string;
  constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
    super();
    this.actionUrl = _configuration.server;
    this._post = this.actionUrl + _configuration.patientCalculateVisitTransfer.url;
  }


  public calculateVisitTransfer = (patientId, encounterId, toEncounterId, toSectionId): Observable<any> => {
    const parameters = {
      PATIENT_ID: patientId,
      ENCOUNTER_ID: encounterId,
      TO_ENCOUNTER_ID: toEncounterId,
      SECTION_ID: toSectionId
    };
    return this._http.post<any>(this._post, parameters);
  }
}
