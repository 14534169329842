import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { ReturnRequest } from '../../../models/pharmacy/return-request';
import { ReturnRequestDetail } from '../../../models/pharmacy/return-request-detail';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class PharmacyReturnRequestService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private approve: string;
    private reject: string;
    private byPatientIdAndEncounterId: string;
    private checkPendingReturnRequestCount: string;
    private action: string;
    private byOrderNoAndActionId: string;

    constructor(private http: HttpClient, private configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.returnRequest.url;
        this.approve = this.actionUrl + configuration.returnRequest.approve;
        this.reject = this.actionUrl + configuration.returnRequest.reject;
        this.byPatientIdAndEncounterId = this.actionUrl + configuration.returnRequest.byPatientIdAndEncounterId;
        this.checkPendingReturnRequestCount = this.actionUrl + configuration.returnRequest.pendingReturnRequestCount;
        this.byOrderNoAndActionId = this.actionUrl + configuration.returnRequest.byOrderNoAndActionId;
    }

    post = (returnRequest: ReturnRequest): Observable<ReturnRequest> => {
        return this.http.post<ReturnRequest>(this.url, returnRequest);
    }

    approveReturn = (id: number, encounterDate: Date): Observable<ReturnRequest> => {
        return this.http.put<ReturnRequest>(`${this.approve}?id=${id}`, encounterDate);
    }

    rejectReturn = (id: number): Observable<ReturnRequest> => {
        return this.http.put<ReturnRequest>(`${this.reject}?id=${id}`, null);
    }

    getByPatientIdAndEncounterId = (patientId: number, encounterId: number): Observable<ReturnRequest[]> => {
        return this.http.get<any>(`${this.byPatientIdAndEncounterId}?patientId=${patientId}&encounterId=${encounterId}`);
    }

    pendingReturnRequestCount = (encounterId: number): Observable<number> => {
        return this.http.get<any>(`${this.checkPendingReturnRequestCount}?encounterId=${encounterId}`);
    }

    getByOrderNoAndActionId = (orderNo: number, actionId: number): Observable<ReturnRequestDetail[]> => {
        return this.http.get<any>(`${this.byOrderNoAndActionId}?orderNo=${orderNo}&actionId=${actionId}`);
    }

}

