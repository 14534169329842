import 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { BaseComponent } from 'app/shared/infrastructure';
import { PatientDiagnosisService, AdmissionService, PatientService, PatientVitalSignService, PharmacyOrderActionService, PatientAllergyService, NormalResultService, PatientEncounterProblemService, LookUpService } from 'app/shared/services';
import { AdmissionReasonInformation } from 'app/shared/models/admission/admission-reason-information';
import { PatientDiagnosis } from 'app/shared/models/core/patient-diagnosis';
import { PatientInfoDto } from 'app/shared/models/core/patient-info-dto';
import { PatientVitalSignInfoDto } from 'app/shared/models/core/patient-vital-sign-info-dto';
import { ActiveMedicationDto } from 'app/shared/models/pharmacy/active-medication-dto';
import { PatientTypes } from 'app/shared/models/core/enums/patient-types';
import { DiagnosisType } from 'app/shared/models/core/enums/diagnosis-type';
import { PatientAllergy } from 'app/shared/models/core/patient-allergy';
import { AllergySource } from 'app/shared/models/medispan/enums/allergy-source';
import { LabResultComponent } from '../lab-result';
import { PatientEncounterProblem } from 'app/shared/models/core/patient-encounter-problem';
import { Control } from 'app/pages/control';
import { PatientProblemDurationTypes } from 'app/shared/models/pharmacy/enums/patient-problem-duration-types';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'patient-more-information-cmp',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./patient-more-information.scss'],
    templateUrl: './patient-more-information.html',
})

export class PatientMoreInformationComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() showLabResults: boolean;  
    @ViewChild('labResultsCmp') labResultsCmp: LabResultComponent;
    @ViewChild('associatedModal') associatedModal: ModalDirective;
    @ViewChild('primaryModal') primaryModal: ModalDirective;

    admissionReason: AdmissionReasonInformation = new AdmissionReasonInformation();
    patientInfoDto: PatientInfoDto = new PatientInfoDto();
    patientVitalSignInfoDto: PatientVitalSignInfoDto = new PatientVitalSignInfoDto();
    patientDiagnosis: PatientDiagnosis[] = [];
    activeMedicationDtos: ActiveMedicationDto[] = [];
    patientAllergies: PatientAllergy[] = [];
    patientTypeId: number;
    patientTypes: typeof PatientTypes = PatientTypes;
    diagnosisType: typeof DiagnosisType = DiagnosisType;
    allergySources: typeof AllergySource = AllergySource;
    patientEncounterProblems: PatientEncounterProblem[] = [];
    patientProblems: Control[] = [];
    durationTypes: SelectItem[];
    associated: string;
    primary: string;

    constructor(private patientDiagnosisService: PatientDiagnosisService
        , private admissionService: AdmissionService
        , private patientService: PatientService
        , private patientVitalSignService: PatientVitalSignService
        , private pharmacyOrderActionService: PharmacyOrderActionService
        , private patientAllergyService: PatientAllergyService
        , private patientEncounterProblemService: PatientEncounterProblemService
        , private lookUpService: LookUpService) {
        super();
    }

    ngOnInit() {
        this.getPatientProblems();
        this.getDurationTypes();
    }

    loadData(patientId: number, encounterId: number, patientTypeId: number) {
        if (patientId && encounterId && patientTypeId) {
            this.patientTypeId = patientTypeId;
            this.getPatientInfo(patientId);
            this.getPatientVitalSignInfo(patientId);
            this.getPatientAllergy(patientId);
            if (patientTypeId === PatientTypes.inPatient) {
                this.getAdmissionReason(patientId, encounterId, patientTypeId);
            }
            this.getPatientDiagnosis(patientId, encounterId, patientTypeId);
            this.getActiveMedication(patientId, encounterId, patientTypeId);
            this.getChiefCompliants(patientId, encounterId, patientTypeId);
            if (this.showLabResults) {
                this.labResultsCmp.getLabNormalResults(patientId, encounterId);
            }
        }
    }

    getAdmissionReason(patientId: number, encounterId: number, patientTypeId: number) {
        this.admissionReason = new AdmissionReasonInformation();
        this.admissionService.getAdmissionReasonByAdmissionId(encounterId)
            .subscribe((data: AdmissionReasonInformation) => {
                this.admissionReason = data;
            }, error => this.showError(error));
    }

    getPatientDiagnosis(patientId: number, encounterId: number, patientTypeId: number) {
        this.patientDiagnosis = [];
        this.patientDiagnosisService.getAllByPatientEncounter(patientId, encounterId, patientTypeId)
            .subscribe((data: PatientDiagnosis[]) => {
                if (data && data.length > 0) {
                    this.patientDiagnosis = data;
                }
            }, error => this.showError(error));
    }

    getPatientInfo(patientId: number) {
        this.patientInfoDto = new PatientInfoDto();
        this.patientService.getPatientMoreInfo(patientId)
            .subscribe((data: PatientInfoDto) => {
                this.patientInfoDto = data;
            }, error => this.showError(error));
    }

    getPatientVitalSignInfo(patientId: number) {
        this.patientVitalSignInfoDto = new PatientVitalSignInfoDto();
        this.patientVitalSignService.getPatientVitalSignInfo(patientId)
            .subscribe((data: PatientVitalSignInfoDto) => {
                this.patientVitalSignInfoDto = data;
            }, error => this.showError(error));
    }

    getActiveMedication(patientId: number, encounterId: number, patientTypeId: number) {
        this.activeMedicationDtos = [];
        this.pharmacyOrderActionService.getActiveMedication(patientId, encounterId, patientTypeId)
            .subscribe((data: ActiveMedicationDto[]) => {
                if (data && data.length > 0) {
                    this.activeMedicationDtos = data;
                }
            }, error => this.showError(error));
    }

    getPatientAllergy(patientId: number) {
        this.patientAllergies = [];
        this.patientAllergyService.getActiveByPatientId(patientId)
            .subscribe((data: PatientAllergy[]) => {
                if (data && data.length > 0) {
                    this.patientAllergies = data;
                }
            }, error => this.showError(error));
    }   

    getChiefCompliants(patientId: number, encounterId: number, patientTypeId: number) {
        this.patientEncounterProblems = [];
        this.patientEncounterProblemService.get(patientId, encounterId, patientTypeId)
            .subscribe((data: PatientEncounterProblem[]) => {
                if (data && data.length > 0) {
                    this.patientEncounterProblems = data;
                }
            }, error => this.showError(error));
    }

    getPatientProblems() {
        this.patientProblems = [];
        this.lookUpService.getPatientProblem()
            .subscribe((data: Control[]) => {
                if (data && data.length > 0) {
                    this.patientProblems = data;
                }
            }, error => this.showError(error));
    }

    getProblemTitle(problem: any){
        if(problem.ProblemId != 9999 ){
            var problemObj = this.patientProblems.find(i => i.Id === problem.ProblemId);
            return problemObj.Title
        }
        else if ( problem.ProblemId === 9999 && problem.OtherProblem) {
            return problem.OtherProblem
        } 
        return '';
    }

    getDurationTypes() {
        this.durationTypes = Object.keys(PatientProblemDurationTypes).filter(a => a.match(/^\D/)).map(name => {
            return { label: name, value: PatientProblemDurationTypes[name] as number };
        });
    }

    getDurationTypeLabel(durationType: number): string {
        const durationTypeObj = this.durationTypes.find(item => item.value === durationType);
        if (durationTypeObj && durationTypeObj.label) {
            return durationTypeObj.label;
        }
        return '';
    }

    viewAssociatedModal(associated) {
        this.associated = associated;
        this.associatedModal.show();
    }

    viewPrimaryModal(primary) {
        this.primary = primary;
        this.primaryModal.show();
    }

    ngOnDestroy() {

    }

}
