import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SourceModule } from 'app/shared/models/enum/source-module';
import { ReceiverNotification } from 'app/shared/models/hubs/receiver-notification';

@Component({
    selector: 'notifications-media-body-cmp',
    styleUrls: ['./notifications-media-body.scss'],
    templateUrl: './notifications-media-body.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class NotificationsMediaBodyComponent {

    @Input() note: ReceiverNotification;
  
    public constructor() {

    }

    modules: any[] = [
        { code: 'PAT', bColor: 'palette-Pink-500' },
        { code: 'ADM', bColor: 'palette-Purple-500' },
        { code: 'LAB', bColor: 'palette-Red-500' },
        { code: 'CRM', bColor: 'light-strong palette-Brown-400' },
        { code: 'NRS', bColor: 'light-strong palette-Teal-500' },
        { code: 'PHR', bColor: 'dark palette-Amber-700' },
        { code: 'OPR', bColor: 'palette-Blue-Grey-500' },
        { code: 'APT', bColor: 'dark palette-Green-A200' },
    ];
  }
