import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { AdmissionStationSearchFilter } from '../../../models/querymodel/admission-station-search-filter';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
@Injectable()
export class AdmissionStationSearchService extends ServiceBase {

    private actionUrl: string;
    private _search: string;
    private _action: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._search = this.actionUrl + _configuration.admissionStationSearch.url;
    }

    getSearchResult = (data: AdmissionStationSearchFilter): Observable<any> => {
        let queryString: string = '';
        queryString += '?patientCode=';
        if (data.patientCode)
            queryString += data.patientCode;

        queryString += '&fromAdmissionDate=';
        if (data.fromAdmissionDate)
            queryString += data.fromAdmissionDate.toLocaleDateString();

        queryString += '&toAdmissionDate=';
        if (data.toAdmissionDate)
            queryString += data.toAdmissionDate.toLocaleDateString();

        queryString += '&fromDischargeDate=';
        if (data.fromDischargeDate)
            queryString += data.fromDischargeDate.toLocaleDateString();

        queryString += '&toDischargeDate=';
        if (data.toDischargeDate)
            queryString += data.toDischargeDate.toLocaleDateString();

        queryString += '&guarantorId=';
        if (data.guarantorId)
            queryString += data.guarantorId;

        queryString += '&admissionDoctorId=';
        if (data.admissionDoctorId)
            queryString += data.admissionDoctorId;

        queryString += `&admissionStatus=${data.admissionStatus || ''}`;

        queryString += `&pageIndex=${data.pageIndex}`;

        queryString += `&patientFullName=${data.patientFullName || ''}`;

        queryString += `&dealingType=${data.dealingType || ''}`;

        queryString += `&patientLocation=${data.patientLocation || ''}`;

        queryString += `&admissionDoctorFullName=${data.admissionDoctorFullName || ''}`;

        queryString += `&providerName=${data.providerName || ''}`;

        queryString += `&filterAdmissionStatus=${data.filterAdmissionStatus || ''}`;

        queryString += '&admissionReasonId=';
        if (data.admissionReasonId)
            queryString += data.admissionReasonId;

        return this._http.get<any>(this._search + queryString);
    }

    getAdmittedPatient = (admittedPatientId: number): Observable<any> => {
        return this._http.get<any>(`${this._search}/AdmittedPatients?patientId=${admittedPatientId}`);
    }
}