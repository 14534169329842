import {
    QueryModelApiConfiguration,
    PatientBasicSearchService,
    SearchPatientHasAdmissionService,
    PatientReservationSearchService,
    PatientDischargeSearchService,
    PatientTransferSearchService,
    PatientStatmentSearchService,
    AdmissionStationSearchService,
    PatientOrderSearchService,
    TodayCashBoxSearchService,
    MainFundSearchService,
    PatientOpenAndHaltStatmentSearchService,
    CalculateAccomodationService,
    PatientCalculateVisitTransferService,
    SapControlSearchService,
    SearchCashBoxTransferService,
    PharmacyOrderSearchService,
    DiclosureInvoiveService,
    PhysicianDesktopSearchService,
    HospitalPerformanceService,
    HospitalIncomeService,
    PatientVisitSearchService,
    DashboardDoctorPerformanceService,
    InvoiceService,
    PharmacyAntibioticSearchService,
    PharmacyMedicationHistorySearchService,
    ErpFailedTransactionService,
    DoctorFeesStationService,
    OrderActionAuditSearchService,
    TrackingSettlementService,
    ConsultationTypesDetailSearchService,
    RevenueService
} from './services/queryModel';

export const NGA_QUERYMODEL_SERVICES = [
    QueryModelApiConfiguration,
    PatientBasicSearchService,
    SearchPatientHasAdmissionService,
    PatientReservationSearchService,
    PatientDischargeSearchService,
    PatientTransferSearchService,
    PatientStatmentSearchService,
    AdmissionStationSearchService,
    PatientOrderSearchService,
    TodayCashBoxSearchService,
    MainFundSearchService,
    PatientOpenAndHaltStatmentSearchService,
    CalculateAccomodationService,
    PatientCalculateVisitTransferService,
    SapControlSearchService,
    SearchCashBoxTransferService,
    PharmacyOrderSearchService,
    DiclosureInvoiveService,
    PhysicianDesktopSearchService,
    HospitalPerformanceService,
    HospitalIncomeService,
    PatientVisitSearchService,
    DashboardDoctorPerformanceService,
    InvoiceService,
    PharmacyAntibioticSearchService,
    PharmacyMedicationHistorySearchService,
    ErpFailedTransactionService,
    DoctorFeesStationService,
    OrderActionAuditSearchService,
    TrackingSettlementService,
    ConsultationTypesDetailSearchService,
    RevenueService
];
