import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ClaimFilter } from 'app/shared/models/queryModel/claim-filter';
import { AddInvoiceSearchFilter } from 'app/shared/models/queryModel/add-invoice-search-filter';


@Injectable()
export class ClaimService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _changeClaimStatus: string;
    private _existClaimInvoice: string;


    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.claim.url;
        this._changeClaimStatus = this.actionUrl + _configuration.claim.changeClaimStatus;
        this._existClaimInvoice = this.actionUrl + _configuration.claim.existClaimInvoice;
    }

    get = (pageNo, rowNo, filter: ClaimFilter): Observable<any> => {
        let queryString: string = '';

        queryString += '?pageNo=';
        queryString += pageNo;

        queryString += '&rowNo=';
        queryString += rowNo;

        var billNo = 0;
        if (filter.BillNo > 0) {
            billNo = filter.BillNo;
        }
        queryString += '&billNo=';
        queryString += billNo;

        var claimNo = 0;
        if (filter.ClaimNo > 0) {
            claimNo = filter.ClaimNo;
        }
        queryString += '&claimNo=';
        queryString += claimNo;

        var status = 0;
        if (filter.Status > 0) {
            status = filter.Status;
        }
        queryString += '&status=';
        queryString += status;

        queryString += '&patientCode=';
        queryString += filter.PatientCode;

        queryString += '&payerId=';
        queryString += filter.PayerID;

        queryString += '&patientType=';
        queryString += filter.PatientType;

        queryString += '&fromClaimDate=';
        queryString += filter.FromClaimDate ? filter.FromClaimDate.toLocaleDateString() : null;

        queryString += '&toClaimDate=';
        queryString += filter.ToIClaimDate ? filter.ToIClaimDate.toLocaleDateString() : null;

        queryString += '&patientName=';
        queryString += filter.PatientName;

        return this._http.get<any>(this.url + queryString);
    }

    changeStatus = (id: number, claimStatus: number): Observable<any> => {
        let queryString: string = '';

        queryString += '?id=';
        queryString += id;

        queryString += '&claimStatus=';
        queryString += claimStatus;

        return this._http.put<any>(this._changeClaimStatus + queryString, null);
    }

    addInvoiceToExistClaim = (claimId: number, payerId: number, isSelectAll: boolean, filter: AddInvoiceSearchFilter, invoices): Observable<any> => {
        let queryString: string = '';

        queryString += '?claimId=';
        queryString += claimId;

        queryString += '&&payerId=';
        queryString += payerId;

        queryString += '&&isSelectAll=';
        queryString += isSelectAll;

        queryString += '&&invoiceId=';
        queryString += filter.InvoiceId;

        queryString += '&&patientName=';
        queryString += filter.PatientName;

        queryString += '&&patientCode=';
        queryString += filter.PatientCode;

        queryString += '&&patientOtherName=';
        queryString += filter.PatientOtherName;

        queryString += '&&encounterDate=';
        queryString += filter.EncounterDate ? filter.EncounterDate.toLocaleDateString() : null;

        return this._http.post<any>(this._existClaimInvoice + queryString, invoices);
    }
}
