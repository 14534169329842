import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { NormalResultQuery } from '../../../../shared/models/lab/normalResultQuery';
import { SpecimenTransactionNormalResultHistory } from '../../../models/lab/specimen-transaction-normal-result-history';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';
import { NormalResultView } from 'app/shared/models/lab/lookup/normal-result-view';

@Injectable()
export class NumericListTextResultEntryScreenService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _filterNormalResultEntry: string;
    private _getNormalResultPatientHistoryForItem: string;
    private _calculateResult: string;
    private _changeIsCompleteStatus: string;
    private _changeIsReleaseStatus: string;
    private _changeResultType: string;
    private _getSingleNormalResult: string;
    private _changeIsConfirmStatus: string;
    private _getAuditLog: string;
    private _reCalculateNormalRangesUrl: string;
    private _changeIsInfectiousStatus: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.normalResult.url;
        this._filterNormalResultEntry = this.actionUrl + _configuration.normalResult.filterNormalResultEntry;
        this._getNormalResultPatientHistoryForItem = this.actionUrl + _configuration.normalResult.getNormalResultPatientHistoryForItem;
        this._calculateResult = this.actionUrl + _configuration.normalResult.calculateResult;
        this._changeIsCompleteStatus = this.actionUrl + _configuration.normalResult.changeIsCompleteStatus;
        this._changeIsReleaseStatus = this.actionUrl + _configuration.normalResult.changeIsReleaseStatus;
        this._changeResultType = this.actionUrl + _configuration.normalResult.changeResultType;
        this._getSingleNormalResult = this.actionUrl + _configuration.normalResult.getSingleNormalResult;
        this._changeIsConfirmStatus = this.actionUrl + _configuration.normalResult.changeIsConfirmStatus;
        this._getAuditLog = this.actionUrl + _configuration.normalResult.auditLog;
        this._reCalculateNormalRangesUrl = this.actionUrl + _configuration.normalResult.reCalculateNormalRanges; 
        this._changeIsInfectiousStatus = this.actionUrl + _configuration.normalResult.changeIsInfectiousStatus;
    }

    getByOrderId = (orderId: number): Observable<any> => {
        const url = `${this._url}?orderId=${orderId}`;
        return this._http.get<any>(url)


    }

    calculateResult = (data: any): Observable<any> => {
        return this._http.post<any>(this._calculateResult, data)


    }

    getPreviousResult = (itemNo: number, specimenNo: number, patientId: number): Observable<any> => {
        const url = `${this._url}?itemNo=${itemNo}&&specimenNo=${specimenNo}&&patientId=${patientId}`;
        return this._http.get<any>(url)
    }

    filterNormalResultEntry = (viewModel: NormalResultQuery): Observable<any> => {
        const orderFromString = viewModel.OrderFrom != undefined ? viewModel.OrderFrom.toLocaleDateString() : undefined;
        const orderToString = viewModel.OrderTo != undefined ? viewModel.OrderTo.toLocaleDateString() : undefined;

        const url = `${this._filterNormalResultEntry}?LabSection=${viewModel.LabSection}&&OrderFrom=${orderFromString}&&OrderNumber=${viewModel.OrderNumber}` +
            `&&OrderTo=${orderToString}&&PatientCode=${viewModel.PatientCode}&&AccessionNumber=${viewModel.AccessionNumber}`;

        return this._http.get<any>(url)



    }

    insertAndGetNormalResults = (orderId: number): Observable<any> => {
        const url = `${this._url}/InsertAndGetNormalResults?orderId=${orderId}`;
        return this._http.get<any>(url)


    }

    getByPatientAndItem = (patientId: number, itemId: number, periodType: number, periodValue: number): Observable<any> => {
        const url = `${this._url}/ByPatientAndItem?patientId=${patientId}&&itemId=${itemId}&&periodType=${periodType}&&periodValue=${periodValue}`;
        return this._http.get<any>(url)
    }

    upateReferenceRange = (normalId: number, referenceRangeId: number): Observable<any> => {
        const url = `${this._url}/UpateReferenceRange?normalId=${normalId}&referenceRangeId=${referenceRangeId}`;
        return this._http.post<any>(url, null)

    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data)

    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data)

    }

    changeIsCompleteStatus = (normalResultId: number, isComplete: boolean): Observable<any> => {
        const url = `${this._changeIsCompleteStatus}?normalResultId=${normalResultId}&isComplete=${isComplete}`;
        return this._http.put<any>(url, null)

    }

    changeIsReleaseStatus = (patientCode: string, normalResultsIds: number[], isRelease: boolean): Observable<any> => {
        const releaseNormalResults = {
            normalResultsIds,
            isRelease,
            patientCode
        };

        return this._http.post<any>(this._changeIsReleaseStatus, releaseNormalResults)

    }

    getNormalResultPatientHistoryForItem = (patientId: number, itemId?: number, groupId?: number): Observable<SpecimenTransactionNormalResultHistory[]> => {
        const url = `${this._getNormalResultPatientHistoryForItem}?patientId=${patientId}&itemId=${itemId}&groupId=${groupId}`;
        return this._http.get<any>(url)


    }

    updateResultType = (id: number, data: any): Observable<any> => {
        return this._http.put<any>(`${this._changeResultType}/${id}`, data)

    }

    getSingleNormalResult = (id: number): Observable<any> => {
        const url = `${this._getSingleNormalResult}?id=${id}`;
        return this._http.get<any>(url)


    }

    changeIsConfirmStatus = (data): Observable<any> => {
        return this._http.put<any>(this._changeIsConfirmStatus, data)

    }

    getAuditLog = (normalResultId: number): Observable<any> => {
       const url = `${this._getAuditLog}?normalResultId=${normalResultId}`;
       return  this._http.get<any>(url)
    }

    reCalculateNormalRanges = (data: any): Observable<any> => {
        return this._http.put<any>(this._reCalculateNormalRangesUrl, data)
    }

    changeIsInfectiousStatus = (normalResultId: number, isInfectious: boolean): Observable<any> => {
        const url = `${this._changeIsInfectiousStatus}?normalResultId=${normalResultId}&isInfectious=${isInfectious}`;
        return this._http.put<any>(url, null)
    }
}
