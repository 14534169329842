import { Pipe, PipeTransform } from '@angular/core';
import { layoutPaths } from '../../../shared';

@Pipe({ name: 'baSystemPicture' })
export class BaSystemPicturePipe implements PipeTransform {

  transform(input: string, ext = 'png'): string {
    return layoutPaths.images.system + input + '.' + ext;
  }
}
