import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { AppModule } from 'app/app.module';
import { SystemSetting } from 'app/shared/models/enum/system-setting';

@Injectable()
export class QueryBuilderApiConfiguration {
    private _systemSettingsService: SystemSettingsService;
    serverUrl: string;
    server: string;
    dimensionAttachementServer: string;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.QueryBuilder);
    }

    public executeQuery = {
        url: '/ExecuteQuery',
        live: '/ExecuteQuery/live',
    }

    public queryGenerator = {
        url: '/QueryGenerator',
    }
    public assignChart = {
        url: '/AssignChart',
    }

    public generateTable = {
        url: '/GenerateTable',
    }

    public assignCard = {
        url: '/AssignCard',
    }

    public dashboardGenerator = {
        url: '/DashboardGenerator',
        byRoleIds: '/DashboardGenerator/ByRoleIds',
    }
}
