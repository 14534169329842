import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { Observable } from 'rxjs-compat/Observable';

import { SystemAllergy } from 'app/shared/models/core/system-allergy';

@Injectable()
export class SystemAllergyService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getBySearchUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.systemAllergy.url;
        this.getBySearchUrl = this.actionUrl + _configuration.systemAllergy.getBySearch;
    }

    getBySearch = (pageIndex: number, rowNo: number, name: string, sourceId?: number): Observable<SystemAllergy[]> => {
        const nameValue = name ? name : '';
        const sourceIdValue = sourceId ? sourceId : null;
        const query = `${this.getBySearchUrl}?query=${nameValue}&sourceId=${sourceIdValue}&pageIndex=${pageIndex}&rowNo=${rowNo}`;
        return this._http.get<any>(query);
    }

}
