import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { StoreLookup } from 'app/shared/models/core/store-lookup';
@Injectable()
export class StoreService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getLookupUrl: string;
    private _getLookupByIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.allergy.url;
        this._getLookupUrl = this.actionUrl + _configuration.store.getLookupUrl;
        this._getLookupByIdUrl = this.actionUrl + _configuration.store.getLookupByIdUrl;
    }

    getStoreLookup = (query: string): Observable<StoreLookup[]> => {
        return this._http.get<any>(`${this._getLookupUrl}?query=${query}`);
    }

    getStoreLookupById = (id: number): Observable<StoreLookup> => {
        return this._http.get<any>(`${this._getLookupByIdUrl}?id=${id}`);
    }
}
