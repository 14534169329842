import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Item } from '../../../models/orders-and-billing/item';
import { ItemRelation } from '../../../models/orders-and-billing/item-relation';
import { OrderSetItemSearchFilter } from '../../../models/orders-and-billing/order-set-item-search-filter';

@Injectable()
export class OrderSetItemService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private suggestedOrderSetItemUrl: string;
    private orderSetItemBySearchUrl: string;
    private orderSetItemByIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.orderSetItem.url;
        this.suggestedOrderSetItemUrl = this.actionUrl + _configuration.orderSetItem.suggestedOrderSetItem;
        this.orderSetItemBySearchUrl = this.actionUrl + _configuration.orderSetItem.orderSetItemBySearch;
        this.orderSetItemByIdUrl = this.actionUrl + _configuration.orderSetItem.orderSetItemById;
    }


    public getSuggestedOrderSetItem = (query: string, orderSetId: number): Observable<Item[]> => {
        return this._http.get<any>(this.suggestedOrderSetItemUrl
            + '?query=' + query
            + '&&orderSetId=' + orderSetId
        );
    }

    public getOrderSetItemBySearch = (orderSetItemSearchFilter: OrderSetItemSearchFilter): Observable<ItemRelation[]> => {
        orderSetItemSearchFilter.Code = orderSetItemSearchFilter.Code ? orderSetItemSearchFilter.Code : "";
        orderSetItemSearchFilter.Description = orderSetItemSearchFilter.Description ? orderSetItemSearchFilter.Description : "";
        orderSetItemSearchFilter.Count = orderSetItemSearchFilter.Count ? orderSetItemSearchFilter.Count : null;
        orderSetItemSearchFilter.TimeGap = orderSetItemSearchFilter.TimeGap ? orderSetItemSearchFilter.TimeGap : null;

        return this._http.get<any>(this.orderSetItemBySearchUrl
            + '?OrderSetId=' + orderSetItemSearchFilter.OrderSetId
            + '&&Code=' + orderSetItemSearchFilter.Code
            + '&&Description=' + orderSetItemSearchFilter.Description
            + '&&IsOrderable=' + orderSetItemSearchFilter.IsOrderable
            + '&&IsChargable=' + orderSetItemSearchFilter.IsChargable
            + '&&IsVariablePrice=' + orderSetItemSearchFilter.IsVariablePrice
            + '&&Count=' + orderSetItemSearchFilter.Count
            + '&&TimeGap=' + orderSetItemSearchFilter.TimeGap
        );
    }

    public getOrderSetItemById = (orderSetId: number): Observable<any> => {
        return this._http.get<any>(this.orderSetItemByIdUrl + `?orderSetId=${orderSetId}`);
    }
}
