import {
    AssignChartService,
    GenerateTableService,
    QueryGeneratorService,
    AssignCardService,
    DashboardGeneratorService,
    QueryBuilderApiConfiguration,


} from './services/query-builder';

export const NGA_QUERY_BUILDER_SERVICES = [
    AssignChartService,
    GenerateTableService,
    QueryGeneratorService,
    AssignCardService,
    DashboardGeneratorService,
    QueryBuilderApiConfiguration
];