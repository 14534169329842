import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { NurseApiConfiguration } from '../apiConfiguration';
import { DatePipe } from '@angular/common';
import { ServiceBase } from 'app/shared/infrastructure';
import { Observable } from 'rxjs-compat/Observable'

@Injectable()
export class AssignTaskDetailsService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _action: string;
    private _calculateTimePerDay: string;

    constructor(private _http: HttpClient, private _configuration: NurseApiConfiguration, private datePipe: DatePipe) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.assignTaskDetails.url;
    }

    get = (id: number): Observable<any> => {
        return this._http.get<any>(`${this._url}/${id}`);
    }

    getByIdAndTaskId = (id: number, taskId: number, pageNumber, rowNumber): Observable<any> => {
        return this._http.get<any>(`${this._url}/SearchByTaskId/${id}/${taskId}/${pageNumber}/${rowNumber}`);
    }

    getScheduleResult = (patientId: number, encounterId: number, patientTypeId: number): Observable<any> => {
        return this._http.get<any>(`${this._url}/GetScheduleResult/${patientId}/${encounterId}/${patientTypeId}`);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(`${this._url}`, JSON.stringify(data));
    }
}
