import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { DimensionServiceApiConfiguration } from '../dimension-service-api-configuration';
import { Observable } from 'rxjs-compat/Observable';
@Injectable()
export class ItemMappingService extends ServiceBase {

    private actionUrl: string;
    private _getBySearch: string;
    private _url: string;
    constructor(private _http: HttpClient, private _configuration: DimensionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.serverUrl;
        this._url = this.actionUrl + _configuration.itemMapping.url;
        this._getBySearch = this.actionUrl + _configuration.itemMapping.getBySearch;

    }
    getBySearch = (query): Observable<any> => {
        let url = `${this._getBySearch}`;
        url += `?Query=${query.Title ? query.Title : ''}`;
        url += `&StandardCode=${query.StandardCode ? query.StandardCode : ''}`;
        url += `&Status=${query.Status != null ? query.Status ? 1 : 0 : null}`;
        url += `&ItemCategoryId=${query.ItemCategoryId ? query.ItemCategoryId : (query.ItemCategoryId === 0 ? query.ItemCategoryId : null)}`;
        url += `&PageNo=${query.PageNo ? query.PageNo : 1}`;
        url += `&RowNo=${query.RowNo ? query.RowNo : 10}`;

        return this._http.get<any>(url);
    }

    post = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    put = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}