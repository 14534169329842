export class FilterStatementTransaction {

    Mrn: string = '';
    PatientName: string = '';
    TransactionDateFrom?: Date;
    TransactionDateTo?: Date;
    PaymentCodeId?: number;
    TransactionById?: number;
    Page?: number;

    constructor() {

    }
}
