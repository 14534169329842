import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { AppModule } from 'app/app.module';
import { SystemSetting } from 'app/shared/models/enum/system-setting';

@Injectable()
export class AdmissionServiceApiConfiguration {

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.Admission);
    }
    private _systemSettingsService: SystemSettingsService;

    server: string;
    consultationType = {
        url: 'ConsultationType',
    };

    class = {
        url: 'Class',
        getFilterClass: 'Class/GetFilterClass',
    };

    admissionReason = {
        url: 'AdmissionReason',
        byId: 'AdmissionReason/ById',
        bySearch: 'AdmissionReason/BySearch',
        bySearchPaged: 'AdmissionReason/BySearchPaged',
    };

    room = {
        url: 'Room',
        getByClassOrBoth: 'Room/GetByClassOrBoth',
        getByClassAndSection: 'Room/GetByClassAndSection',
        RoomAvailabilityByClass: 'Room/ClassesRoomBedAvailability',
        RoomAvailabilityBySection: 'Room/AvailableSectionPercentage',
    };

    bed = {
        url: 'Bed',
    };

    bedAvailability = {
        url: 'BedAvailability',
    };

    bedStatus = {
        toCanceled: 'BedStatus/ChangeBedStatusToCanceled?bedId=',
        toOccupied: 'BedStatus/ChangeBedStatusToOccupied?bedId=',
    };

    reservation = {
        url: 'Reservation',
    };

    companion = {
        url: 'Companion',
    };

    companionPeriods = {
        url: 'CompanionPeriods',
    };

    admission = {
        url: 'Admission',
        doctorRelease: 'Admission/DoctorRelease',
        openToRelease: 'Admission/OpenToRelease',
        releaseToOpen: 'Admission/ReleaseToOpen',
        admissionReasonByAdmissionId: 'Admission/AdmissionReasonByAdmissionId',
        notifyPyxisOnPatientUpdate: 'Admission/NotifyPyxisOnPatientUpdate',
        notifyPyxisOnMergePatient: 'Admission/NotifyPyxisOnMergePatient',
        setReleaseDate: 'Admission/SetReleaseDate',
        setDischargeDate: 'Admission/SetDischargeDate',
        getReleaseDate: 'Admission/GetReleaseDate',
        publishAdmissionHistory: 'Admission/AdmissionHistory',
    };

    transfer = {
        url: 'Transfer',
    };

    inpatientTimelineHistory = {
        inpatientHistory: 'Admission/PatientHistory',
    };

    admissionReportSetup = {
        url: 'AdmissionReportSetup',
    };

    consultationTypesDetail = {
        url: 'ConsultationTypesDetail',
        getWalkInVisitService: 'ConsultationTypesDetail/getWalkInVisitService',
    };

    consultationDoctor = {
        url: 'consultationDoctor',
        getConsultingDoctorByAdmissionId: 'consultationDoctor/ByAdmissionId',
        postConsultingDoctor: 'consultationDoctor/PostConsultingDoctor',
        updateConsultingDoctor: 'consultationDoctor/UpdateConsultingDoctor'
    };

}
