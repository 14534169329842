import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { MedicationConfiguration } from '../../../models/pharmacy/medication-configuration';
import { ServiceBase } from 'app/shared/infrastructure';
@Injectable()
export class MedicationConfigurationService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getByGenericAndCategory: string;
    private _getById: string;
    private _add: string;
    private _put: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.medicationConfiguration.url;
        this._getByGenericAndCategory = this.actionUrl + _configuration.medicationConfiguration.getByGenericAndCategory;
        this._getById = this.actionUrl + _configuration.medicationConfiguration.getById;
    }

    // get by category and query
    lookUpByGenericAndCategory = (genericId: number, lookUpCategoryId: number, isActive: boolean): Observable<MedicationConfiguration[]> => {
        return this._http.get<any>(this._getByGenericAndCategory + '?genericId=' + genericId + '&&lookUpCategoryId=' + lookUpCategoryId + '&&isActive=' + isActive)


    }

    getById = (id: number): Observable<MedicationConfiguration> => {
        return this._http.get<any>(this._getById + '?id=' + id)


    }

    post = (data: MedicationConfiguration): Observable<number> => {
        return this._http.post<number>(this._url, data)


    }

    update = (data: MedicationConfiguration): Observable<any> => {
        return this._http.put<any>(this._url, data)

    }
}
