import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { Assignment } from 'app/shared/models/orders-and-billing/assignment';

@Injectable()
export class AssignmentService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getByIdUrl: string;
    private getBySearchUrl: string;
    private getByQueryUrl: string;
    private _getByItemId: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.assignment.url;
        this.getByIdUrl = this.actionUrl + configuration.assignment.byId;
        this.getBySearchUrl = this.actionUrl + configuration.assignment.bySearch;
        this.getByQueryUrl = this.actionUrl + configuration.assignment.byQuery;
        this._getByItemId = this.actionUrl + configuration.assignment.byItemId;
    }

    getById = (id: number): Observable<Assignment> => {
        return this.http.get<Assignment>(this.getByIdUrl + '?id=' + id);
    }

    add = (data: Assignment): Observable<any> => {
        return this.http.post<any>(this.url, JSON.stringify(data));
    }

    update = (data: Assignment): Observable<any> => {
        return this.http.put<any>(this.url, JSON.stringify(data));
    }

    getBySearch = (title: string, priorityId: number, isScreen: boolean, screenId: number, isActive: boolean, pageIndex: number, rowNo: number): Observable<any> => {
        let titleValue = title ? title : '';
        let priorityIdValue = priorityId ? priorityId : null;
        let isScreenValue = isScreen ? isScreen : (isScreen == false ? isScreen : null);
        let screenIdValue = screenId ? screenId : null;
        let isActiveValue = isActive ? isActive : (isActive == false ? isActive : null);

        return this.http.get<any>(this.getBySearchUrl
            + '?title=' + titleValue
            + '&&priorityId=' + priorityIdValue
            + '&&isScreen=' + isScreenValue
            + '&&screenId=' + screenIdValue
            + '&&isActive=' + isActiveValue
            + '&&pageIndex=' + pageIndex
            + '&&rowNo=' + rowNo
        );
    }

    getByQuery = (query: string): Observable<Assignment[]> => {
        return this.http.get<any>(this.getByQueryUrl + '?query=' + query);
    }

    getByItemId = (itemsIds: string): Observable<any> => {
        return this.http.get<any>(`${this._getByItemId}/${itemsIds}`);
    }
}
