import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SystemSetting } from 'app/shared/models/enum/system-setting';
import { AppModule } from 'app/app.module';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';

@Injectable()
export class OrderApiConfiguration {
    private _systemSettingsService: SystemSettingsService;
    server: string;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.Order);
    }

    getSpecimenBarCodeReportURL() {
        return this._systemSettingsService.getAppConstantByName(SystemSetting.SpecimenBarcodeReportViewer);
    }

    order =
        {
            url: 'Order',
            editOrderDoctor: 'Order/EditOrderDoctor',
            checkBalance: 'Order/Item/WarehouseBalance',
            latestOrderDate: 'Order/LatestOrderDate',
            publishRadilogyHistory: 'Order/RadilogyHistory',
        };

    transaction =
        {
            url: 'StatementTrans',
        };

    voucher =
        {
            url: 'VoucherType',
        };

    profitMargin =
        {
            url: 'PriceListProfitMargin',
        };

    profitMarginDetail =
        {
            url: 'PriceListProfitMarginDetails',
        };

    unit =
        {
            url: 'Unit',
            byGroupId: 'Unit/ByGroupId',
            byGroupIds: 'Unit/ByGroupIds',
        };

    orderDetail = {
        url: 'OrderDetail',
        cancelOrderDetail: 'OrderDetail/CancelOrderDetail',
        cancelMultiOrder: 'OrderDetail/CancelMultiOrder',
        orderIndex: 'OrderDetail/OrderIndex',
        orderActionElement: 'OrderDetail/OrderActionElement',
        execludeOrderAction: 'OrderDetail/ExecludeOrderAction',
        orderByCategory: 'OrderDetail/OrderByCategory',
        receivedOrderDetail: 'OrderDetail/ReceivedOrderDetail',
        completeOrderDetail: 'OrderDetail/CompleteOrderDetail',
        changeOrderStatus: 'OrderDetail/ChangeOrderStatus',
        multiOrderActionElement: 'OrderDetail/MultiOrderActionElement',
        orderPriority: 'OrderDetail/OrderPriority',
        changeOrderReceiveStatus: 'OrderDetail/ChangeOrderReceiveStatus',
        receivedOrderLog: 'OrderDetail/ReceivedOrderLog',
        doctorNote: 'OrderDetail/DoctorNote',
    };

    insurance = {
        url: 'CoverageAproval',
    };

    funds =
        {
            url: 'Fund',
            all: 'Fund/All',
            active: 'Fund/Active',
            getLookUp: 'Fund/GetLookUp',
            getCashierFundsUrl: 'Fund/CashierFunds',
            mainFund: 'Fund/MainFund',
            isMainFund: 'Fund/IsMainFund',
            loggedInFund: 'Fund/LoggedInFund',
        };

    cahierfunds =
        {
            url: 'CashierFund',
            getFundsForPermittedUser: 'CashierFund/FundsForPermittedUser',
            getCashierStaffId: 'CashierFund/CashierStaffId',
        };

    cashierTrans =
        {
            url: 'CashierPrivilege',
            byStaffId: 'CashierPrivilege/ByStaffId',
            byLoggedUser: 'CashierPrivilege/ByLoggedUser',
        };

    patientStatement = {
        url: 'PatientStatement',
        getByFillter: 'PatientStatement/GetByFillter',
        reOpen: 'PatientStatement/ReOpen',
        financeRelease: 'PatientStatement/FinanceRelease',
        issueBill: 'PatientStatement/IssueBill',
        inPatientPatientTotalAmount: 'PatientStatement/InPatientPatientTotalAmount',
        haltStatement: 'PatientStatement/HaltStatement',
        openHaltStatement: 'PatientStatement/OpenHaltStatement',
        validateStatement: 'PatientStatement/Validate',
        isOrderAttachedToOpenStatements: 'PatientStatement/OrderAttachedToOpenStatements',
        canDischargePatient: 'PatientStatement/CanDischargePatient',
        canCancelPatientAdmission: 'PatientStatement/CanCancelPatientAdmission',
        isOrderActionAttachedToOpenStatements: 'PatientStatement/OrderActionAttachedToOpenStatements',
        blockStatement: 'PatientStatement/BlockStatement',
        unBlockStatement: 'PatientStatement/UnBlockStatement',
        creditInvoiceSearch: 'PatientStatement/CreditInvoicesSearch',
        creditInvoicesSearchForAddInvoice: 'PatientStatement/CreditInvoicesSearchForAddInvoice',
        canTransfePatient: 'PatientStatement/CanTransferPatient',
        remark: 'PatientStatement/Remarks',
        getDealingType: 'PatientStatement/DealingType',
        getCloseDate: 'PatientStatement/CloseDate',
        setCloseDate: 'PatientStatement/SetCloseDate',
        getDischargeDate: 'PatientStatement/GetDischargeDate',
        isStatementOpenOrHalted: 'PatientStatement/IsStatementOpenOrHalted',
        encounterInfo: 'PatientStatement/EncounterInfo',
    };

    claim = {
        url: 'Claim',
        changeClaimStatus: 'Claim/ChangeClaimStatus',
        claimAmount: 'Claim/ClaimAmount',
        existClaimInvoice: 'Claim/ExistClaimInvoice',
    }

    claimDetail = {
        url: 'ClaimDetail',
        getEClaimStatusUrl: 'ClaimDetail/EClaimStatus',
        cancelEClaimUrl: 'ClaimDetail/CancelEClaim',
        exortToExcel: 'ClaimDetail/ClaimDetailCsv',
        settlementLinkedInvoices: 'ClaimDetail/SettlementLinkedInvoices',
        settlementNoneLinkedInvoices: 'ClaimDetail/SettlementNoneLinkedInvoices',
        getNoneLinkedInvoicesForExcel: 'ClaimDetail/GetNoneLinkedInvoicesForExcel',
        importLinkInvoices: 'ClaimDetail/ImportLinkInvoices'
    }

    billServices = {
        url: 'BillService',
        getByFillter: 'BillService/GetByFillter',
        getDiscountableElement: 'BillService/DiscountableElement',
        discount: 'BillService/Discount',
        invoiceIndex: 'BillService/InvoiceIndex',
        invoiceIndexDetail: 'BillService/InvoiceIndexDetail',
        getDiscountableItemDetail: 'BillService/DiscountableItemDetail',
        orderActionInvoiceDetail: 'BillService/OrderActionInvoiceDetail',
        getNotCanceledByFillter: 'BillService/GetNotCanceledByFillter',
        netAmountByStatmentId: 'BillService/NetAmountByStatmentId',
        getLastActionDateByStatementId: 'BillService/GetLastActionDateByStatementId',
        checkIfPaid: 'BillService/CheckIfPaid'
    };

    patientEncounterInsurance = {
        url: 'PatientEncounterInsurance',
        getByFilter: 'PatientEncounterInsurance/ByFilter',
        getByEncounter: 'PatientEncounterInsurance/ByEncounter',
    };

    coverageContract = {
        url: 'CoverageContract',
        getCoverageContractsByPayerId: 'CoverageContract/GetCoverageContractsByPayerId',
        getPayerCoverageContractEndDateAndStartDate: 'CoverageContract/PayerCoverageContractEndDateAndStartDate',
        copyContract: 'CoverageContract/CopyContractClass'
    };

    coverageClass = {
        url: 'CoverageClass',
        getClassesByContractId: 'CoverageClass/ClassesByContractId',
        getPayerClassByPayerIdContainContract: 'CoverageClass/PayerClassByPayerIdContainContract',
    };

    coverageApprovalSection = {
        url: 'CoverageApprovalSection',
    };

    coverageApprovalDiagnosis = {
        url: 'CoverageApprovalDiagnosis',
    };


    statementTransaction = {
        url: 'StatementTransaction',
        downPayment: 'StatementTransaction/DownPayment',
        payment: 'StatementTransaction/Payment',
        refund: 'StatementTransaction/Refund',
        statementTransactions: 'StatementTransaction/StatementTransactions',
        getFundStatementTransactionsUrl: 'StatementTransaction/FundStatementTransactions',
        downPaymentCancelation: 'StatementTransaction/DownPaymentCancelation',
        discountCancelation: 'StatementTransaction/DiscountCancelation',
        invoiceCancelation: 'StatementTransaction/InvoiceCancelation',
        mainFundTransactionDetails: 'StatementTransaction/MainFundTransactionDetails',
        mainFundReceiveTransaction: 'StatementTransaction/MainFundReceiveTransaction',
        getSapFailedIncomingPaymentsUrl: 'StatementTransaction/SapFailedIncomingPayments',
        retrySapFailedIncomingPaymentsUrl: 'StatementTransaction/RetrySapFailedIncomingPayments',
        getSapFailedOutgoingPaymentsUrl: 'StatementTransaction/SapFailedOutgoingPayments',
        retrySapFailedOutgoingPaymentsUrl: 'StatementTransaction/RetrySapFailedOutgoingPayments',
        retrySapFailedCancelDownPaymentsUrl: 'StatementTransaction/RetrySapFailedCancelDownPayments',
        retrySapFailedRefundPaymentsUrl: 'StatementTransaction/RetrySapFailedRefundPayments',
        settlePatientBalanceUrl: 'StatementTransaction/SettlePatientBalance',
        searchTransactionStatements: 'StatementTransaction/SearchTransactionStatements',
        exportTransactionStatements: 'StatementTransaction/ExportTransactionStatements',
    };

    elementsConfiguration = {
        url: 'BillElement',
    };

    payer = {
        url: 'Payer',
        getChildPayers: 'Payer/GetChildPayers',
        byQuery: 'Payer/ByQuery',
        activePayersSearch: 'Payer/ActivePayersSearch',
        getPayersContainContracts: 'Payer/PayersContainContracts',
        getSapFailedPayersRegistrationsUrl: 'Payer/SapFailedPayersRegistrations',
        retrySapFailedPayersRegistrationsUrl: 'Payer/RetrySapFailedPayerRegistrations',
        getPayersBySearchChild: 'Payer/PayersBySearchChild',
    };

    payerContactPerson = {
        url: 'PayerContactPerson'
    }

    approvalservices = {
        url: 'CoverageApprovalService',
    };

    item = {
        url: 'Item',
        bySearch: 'Item/BySearch',
        bySearchPaged: 'Item/BySearchPaged',
        suggestedMainItem: 'Item/SuggestedMainItem',
        LabParents: 'Item/LabParents',
        getServiceByCategory: 'Item/GetServiceByCategory'
    };

    itemInstruction = {
        url: 'ItemInstruction',
    };

    cashBoxTranfer = {
        url: 'CashTranferRequest',
        pendingRequest: 'CashTranferRequest/PendingRequest',
        requestDetailsUrl: 'CashTranferRequest/RequestDetails',
        sendTransferRequestUrl: 'CashTranferRequest/TransferRequest',
        acceptTransferRequestUrl: 'CashTranferRequest/AcceptTransferRequest',
        rejectTransferRequestUrl: 'CashTranferRequest/RejectTransferRequest',
        retrySapDispatchMoney: 'CashTranferRequest/RetrySapDispatchMoney',
        changeActualAmount: 'CashTranferRequest/ChangeActualAmount'
    };

    itemRelation = {
        url: 'ItemRelation',
        itemCountByMainItemId: 'ItemRelation/RelatedItemCountByMainItemId',
        medicationProductItemRelation: 'ItemRelation/MedicationProductItemRelation',
    };

    itemCategory = {
        url: 'ItemCategory',
        activeCategories: 'ItemCategory/AllCategories',
    };

    revenueSectionDefinition = {
        url: 'RevenueSectionDefinition',
        byCategoryAndOrderSection: 'RevenueSectionDefinition/ByCategoryAndOrderSection',
        pagedResults: 'RevenueSectionDefinition/PagedResults',
    };

    parentItem = {
        url: 'ParentItem',
        leaf: 'ParentItem/Leaf',
        tree: 'ParentItem/Tree',
        suggestedChildItem: 'ParentItem/SuggestedChildItem',
        itemBySearch: 'ParentItem/ItemBySearch',
    };

    packageItem = {
        url: 'PackageItem',
        suggestedPackageItem: 'PackageItem/SuggestedPackageItem',
        packageItemBySearch: 'PackageItem/PackageItemBySearch',
    };

    groupItem = {
        url: 'GroupItem',
        suggestedGroupItem: 'GroupItem/SuggestedGroupItem',
        groupItemBySearch: 'GroupItem/GroupItemBySearch',
    };

    orderSetItem = {
        url: 'OrderSetItem',
        suggestedOrderSetItem: 'OrderSetItem/SuggestedOrderSetItem',
        orderSetItemBySearch: 'OrderSetItem/OrderSetItemBySearch',
        orderSetItemById: 'OrderSetItem/OrderSetItemById'
    };

    coverageService = {
        url: 'CoverageService',
        getServicesByClassId: 'CoverageService/ServicesByClassId',
    };

    coverageDiscount = {
        url: 'CoverageDiscount',
        discountsByClassId: 'CoverageDiscount/DiscountsByClassId',
    };

    billSerials = {
        url: 'BillSerial',
    };

    billElement = {
        url: 'BillElement',
        getAllElement: 'BillElement/AllElement',
        allActiveElement: 'BillElement/AllActiveElement'
    };

    packageSession = {
        url: 'PackageSession',
    };

    patientPackageSession = {
        url: 'PatientPackageSession',
    };

    patientPayer = {
        url: 'PatientPayer',
        getById: 'PatientPayer/PatientPayerById?id=',
        getByPatientId: 'PatientPayer/PatientPayerByPatientId?patientId=',
        isHavePayer: 'PatientPayer/IsHavePayer?patientId=',
        getPayerInfo: 'PatientPayer/GetPayerInfo?patientId=',
        getPreferencePatientPayerInformation: 'PatientPayer/PreferencePatientPayerInformation',
        getPreferencePatientPayerAndContractInformation: 'PatientPayer/PreferencePatientPayerAndContractInformation',
    };

    priceList = {
        url: 'PriceList',
        getAllActivePriceList: 'PriceList/GetAllActivePriceList',
        getEmptyPriceListItems: 'PriceList/GetEmptyPriceListItems',
        getNonEmptyPriceListItems: 'PriceList/GetNonEmptyPriceListItems',
    };

    priceListItem = {
        url: 'PriceListItem',
    };

    priceCopyList = {
        url: 'PriceCopyList',
        copy: 'PriceCopyList/Copy',
    };

    pricingCategory = {
        url: 'PricingCategory',
    };

    favoriteList = {
        url: 'FavoriteList',
        getById: 'FavoriteList/GetById',
        getByUser: 'FavoriteList/GetByUser',
        addList: 'FavoriteList/AddList',
    };

    pendingOrder = {
        url: 'PendingOrderTransaction'
    };

    staff = {
        url: 'Staff',
        getCashiersUrl: 'Staff/Cashiers',
        getByQueryUrl: 'Staff/ByQuery',
        getCashiersByQueryUrl: 'Staff/CashiersByQuery'
    };

    patientBill = {
        url: 'PatientBill',
        sapFailedActivePatientsBillsUrl: 'PatientBill/SapFailedActivePatientsBills',
        retrySapFailedActivePatientsBillsUrl: 'PatientBill/RetrySapFailedActivePatientsBills',
        retryAllSapFailedActivePatientsBillsUrl: 'PatientBill/RetryAllSapFailedActivePatientsBills',
    };

    unitConversion = {
        url: 'UnitConversion',
    };

    payerSettlement = {
        allSettlement: 'PayerSettlement/AllSettlementsList',
        PayerSettlementsByRecieptNoSearch: 'PayerSettlement/PayerSettlementsByRecieptNoSearch',
        PayerSettlement: 'PayerSettlement',
        changeStatus: 'PayerSettlement/ChangeStatus',
        payerSettlementsByPayerSearch: 'PayerSettlement/PayerSettlementsByPayerSearch',
        payerSettlementsByStatusSearch: 'PayerSettlement/PayerSettlementsByStatusSearch',
        SettlementSummary: 'PayerSettlement/Summary'
    };

    payerReceipt = {
        payerReceiptByPayerSearch: 'PayerReceipt/PayerReceiptByPayerSearch',
        getAll: 'PayerReceipt/AllPayerReceipts',
        changeStatus: 'PayerReceipt/ChangeStatus',
        post: 'PayerReceipt',
    };

    bank =
        {
            url: "Bank",
            all: 'Bank/All',
            active: 'Bank/Active',
        };

    cashierDiscount =
        {
            url: 'CashierDiscount',
            privateActiveUrl: 'CashierDiscount/Private/Active'
        };

    settlementInvoice = {
        url: 'SettlementInvoice',
        all: 'SettlementInvoice/All',
        subset: 'SettlementInvoice/Subset',
        taxAmount: 'SettlementInvoice/TaxAmount',
    };

    assignment = {
        url: 'Assignment',
        byId: 'Assignment/ById',
        bySearch: 'Assignment/BySearch',
        byQuery: 'Assignment/ByQuery',
        byItemId: 'Assignment/GetByItemId',
    };

    assignmentRole = {
        url: 'AssignmentRole',
        bySearch: 'AssignmentRole/BySearch',
    };

    contractStationCoverageService = {
        url: 'ContractService',
        servicesByContractId: 'ContractService/ServicesByContractId',
    };

    contractStationCoverageDiscount = {
        url: 'ContractDiscount',
        discountsByContractId: 'ContractDiscount/DiscountsByContractId',
    };

    visaType = {
        url: 'VisaType',
        all: 'VisaType/All',
        allActive: 'VisaType/AllActive',
    };

    packagePlan = {
        url: 'PackagePlan',
        getByPatientAndPackageIdUrl: 'PackagePlan/ByPatientAndPackageId',
    };

    doctorSettlement = {
        url: 'DoctorSettlement',
        bySearch: 'DoctorSettlement/BySearch',
        cancel: 'DoctorSettlement/Cancel',
        approve: 'DoctorSettlement/Approve',
    }

    doctorSettlementDetail = {
        url: 'DoctorSettlementDetail',
        byDoctorSettlement: 'DoctorSettlementDetail/ByDoctorSettlement',
    }

    doctorBalance = {
        url: 'DoctorBalance',
        getDoctorBalance: 'DoctorBalance/GetDoctorBalance',
    };

    systemCurrency = {
        url: 'SystemCurrency',
        defaultCode: 'SystemCurrency/DefaultCode',
        default: 'SystemCurrency/Default',
        all: 'SystemCurrency/All'
    };


    excludedSections = {
        url: 'ExcludedSections',
        getExcludedSections: 'ExcludedSections/AllExcludedSections',
    };

    excludedDiagnosis = {
        url: 'ExcludedDiagnosis',
        getExcludedSections: 'ExcludedDiagnosis/AllExcludedDiagnosis',
    };

    payerTemplate = {
        url: 'PayerTemplate',
        getAllActivePayerTemplate: 'PayerTemplate/AllActive'
    };

    templateMasterCode = {
        url: 'TemplateMasterCode',
        getByPayerTemplateIdUrl: 'TemplateMasterCode/GetByPayerTemplateId',
    };

    payerDiscount = {
        url: 'PayerDiscount',
        discountsByPayerId: 'PayerDiscount/DiscountsByPayerId',
    };

    payerDiagnosisGroup = {
        url: 'PayerDiagnosisGroup',
        getAll: 'PayerDiagnosisGroup/GetAll',
        getAllActive: 'PayerDiagnosisGroup/GetAllActive',
    };

    PayerDiagnosisGroupDetails = {
        url: 'PayerDiagnosisGroupDetails',
    };

    settlementDeductionSetup = {
        url: 'SettlementDeductionSetup',
        getAccounts: 'SettlementDeductionSetup/Accounts',
        getActive: 'SettlementDeductionSetup/Active',
    };

    currencyLog = {
        url: 'CurrencyLog',
    };
}
