import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { AppModule } from 'app/app.module';
import { SystemSetting } from 'app/shared/models/enum/system-setting';

@Injectable()
export class MedispanServiceApiConfiguration {

    private _systemSettingsService: SystemSettingsService;
    server: string;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.MediSpanUrl);
    }

    route = {
        url: 'Route',
        getAll: 'Route/All',
    };

    mediSpan = {
        url: 'MediSpan',
        mediSpanResult: 'MediSpan/MediSpanResult',
        mediSpanLog: 'MediSpan/MediSpanLog',
    };

    setting = {
        url: 'Setting',
        byGroupId: 'Setting/ByGroupId',
    };

    allergySeverity = {
        url: 'AllergySeverity',
        getAll: 'AllergySeverity/All',
    };
}
