<div baCardBlur class="animated fadeIn card {{cardType}} {{baCardClass || ''}}"
    [ngClass]="{'card-static-height': staticHeight, 'mobile-card-min-height' : validateMobile()}" zoom-in>
    <div *ngIf="title" class="card-header clearfix">
        <h3 class="card-title" translate>{{title}}</h3>
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>



<!-- [ngClass]="{'mobile-card': validateMobile()}" -->