export class AraxUser {

    readonly id: string;

    readonly name: string;

    readonly fullName: string;


    readonly facility: number;

    readonly staffId: number;

    constructor(user: Oidc.User) {

        this.id = user.profile.sub;

        this.name = user.profile.name;
        this.fullName = user.profile.full_name;

        this.facility = Number(user.profile.selected_facility) || 0;

        this.staffId = Number(user.profile.staff_id) || 0;

    }
}
