import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class LabFavoriteListService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _addList: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.LabfavoriteList.url;
        this._addList = this.actionUrl + _configuration.LabfavoriteList.addList;
    }

    public addList = (data: any): Observable<any> => {
        return this._http.post<any>(this._addList, data);
    }
}