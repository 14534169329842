import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';

@Injectable()
export class AdtClassService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getFilterClass;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.class.url;
        this._getFilterClass = this.actionUrl + _configuration.class.getFilterClass
    }

    public get = (isActive): Observable<any> => {
        return this._http.get<any>(this._url + '?isActive=' + isActive);
    }

    public getById = (id): Observable<any> => {
        return this._http.get<any>(this._url + '?id=' + id);
    }

    public getLevel = (): Observable<any> => {
        return this._http.get<any>(this._url + '?getList');
    }

    public GetFilterClass = (query): Observable<any> => {
        return this._http.get<any>(this._getFilterClass + '?query=' + query);
    }

    public add = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update = (id: number, data: any): Observable<any> => {
        return this._http.put<any>(this._url + '?id=' + id, JSON.stringify(data));
    }
}