export enum PatientPaymentCodes {
    Payment = 1,
    Refund = 2,
    Discount = 3,
    DownPayment = 4,
    Invoice = 5,
    CancelDiscount = 6,
    CancelDownPayment = 7,
    CancelInvoice = 8,
    Settlement = 9
}
