import { AbstractControl, ValidatorFn } from '@angular/forms';


export class DecimalValidator {

    public static validate: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
        const v: string = control.value;

        if (control.value) {
            return /^\d*(\.\d{0,2})?$/.test(v) ? null : { digits: true };
        }
        else {
            return null;
        }
    };

    public static validateThreeDigit: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
        const v: string = control.value;

        if (control.value) {
            return /^\d*(\.\d{0,3})?$/.test(v) ? null : { digits: true };
        }
        else {
            return null;
        }
    };
}
