import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { SystemSetting } from 'app/shared/models/enum/system-setting';
import { AppModule } from 'app/app.module';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';

@Injectable()
export class NurseApiConfiguration {

    private _systemSettingsService: SystemSettingsService;
    server: string;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.Nurse);
    }

    assignTask = {
        url: 'assignTask',
        calculateTimePerDay: 'assignTask/calculateTimePerDay',
        insertTaskById: 'assignTask/InsertTaskById',
    };

    assignTaskDetails = {
        url: 'assignTaskDetails',
    };
}
