import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from 'app/shared/infrastructure';
import { Observable } from 'rxjs-compat/Observable';
import 'rxjs/add/operator/map';
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { MedicalLabelLog } from 'app/shared/models/pharmacy/medical-label-log';

@Injectable()
export class MedicalLabelLogService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private http: HttpClient, private configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.medicalLabelLog.url;
    }

    StoreOrderActionIdsForPrintLabel(medicalLabelLog: MedicalLabelLog): Observable<number> {
        return this.http.post<any>(this.url, medicalLabelLog);
    }
}
