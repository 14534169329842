import { ItemRelation } from './item-relation';

export interface IMetaData {
    properties?: any;
    favoriteId?: any;
}

export class Item implements IMetaData {

    Id: number = 0;
    Code: string = '';
    Title: string = '';
    ForeignTitle: string = '';
    TypeId?: number = null;
    IsOrderable?: boolean = null;
    IsVariablePrice?: boolean = null;
    IsActive?: boolean = null;
    IsChargable?: boolean = null;
    CategoryId?: number = null;
    UnitId?: number = null;
    DefaultUnitId?: number = null;
    DefaultUnitGroupId?: number = null;
    PACSValue: string = '';
    Barcode: string = '';
    AverageCost: number = 0;

    MainItemRelations: ItemRelation[] = null;
    SubItemRelations: ItemRelation[] = null;

    properties?: any = {};
    favoriteId?: any;
    StockBalance?: number = 0;
    ExpiryDate?: Date = new Date();
    qty?: number;
    constructor(init?: Partial<Item>) {
        if (init) {
            for (const p in this) {
                this[p.toString()] = (p in init ? init : this)[p.toString()];
            }
        }
        return this;
    }

}
