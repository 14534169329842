export class PatientVisitSearch {

    PatientCode: string;
    PatientName: string;
    TelCode: string;
    NationalCode: string;
    VisitDate?: Date;
    SectionId?: number;
    DoctorId: number;
    SourceId: number;

    constructor() { }
}
