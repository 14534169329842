import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';

@Injectable()
export class PayerContactPersonService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.payerContactPerson.url;
    }

    getByPayerId = (id: number): Observable<any> => {
        return this._http.get<any>(`${this.url}/${id}`);
    }

    public add(data: any) {
        return this._http.post<any>(this.url, JSON.stringify(data));
    }

    public update(id, data: any) {
        return this._http.put<any>(`${this.url}/${id}`, JSON.stringify(data));
    }

    public delete(id: number) {
        return this._http.delete<any>(`${this.url}/${id}`);
    }
}