import {
    AppointmentServiceApiConfiguration,
    AppointmentService,

} from './services/appointmentService';

export const NGA_APPOINTMENT_SERVICES = [
    AppointmentServiceApiConfiguration,
    AppointmentService,
];
