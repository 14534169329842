import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { DimensionServiceApiConfiguration } from '../dimension-service-api-configuration';
import { Observable } from 'rxjs-compat/Observable';
@Injectable()
export class RemittanceTransactionsService extends ServiceBase {

    private dimensionTPO: string;
    private localDimension: string;
    private dimensionAttachementServer: string;
    private remittanceClaim: string;
    private _newRemittanceAdvice: string;
    private _remittanceAdviceDetails: string;
    private _remittanceAdviceDownloaded: string;
    private _remittanceAttachement: string;
    private _setRemittanceDownloadedLocal: string;
    private _resubmissionLocal: string;
    private _setRemittanceStatusAsPending: string;
    private _setRemittanceStatusAsClose: string;
    private _getDownloadedClaims: string;
    private _getByClaimId: string;
    constructor(private _http: HttpClient, private _configuration: DimensionServiceApiConfiguration) {
        super();
        this.dimensionTPO = _configuration.server;
        this.localDimension = _configuration.serverUrl;
        this.dimensionAttachementServer = _configuration.dimensionAttachementServer;
        this.remittanceClaim = this.localDimension + _configuration.remittance.remittanceClaim;
        this._setRemittanceDownloadedLocal = this.localDimension + _configuration.remittance.setRemittanceDownloadedLocal;
        this._resubmissionLocal = this.localDimension + _configuration.submissionClaim.resubmission;
        this._setRemittanceStatusAsPending = this.localDimension + _configuration.remittance.setRemittanceStatusAsPending;
        this._setRemittanceStatusAsClose = this.localDimension + _configuration.remittance.setRemittanceStatusAsClose;
        this._getDownloadedClaims = this.localDimension + _configuration.remittance.getDownloadedClaims;
        this._getByClaimId = this.localDimension + _configuration.remittance.getByClaimId;
        this._newRemittanceAdvice = this.dimensionTPO + _configuration.remittance.getNewRemittanceAdvice;
        this._remittanceAdviceDetails = this.dimensionTPO + _configuration.remittance.getRemittanceAdviceDetails;
        this._remittanceAdviceDownloaded = this.dimensionTPO + _configuration.remittance.setRemittanceAdviceDownloaded;
        this._remittanceAttachement = this.dimensionAttachementServer + _configuration.remittanceAttachement.uploadAttachment;
    }

    // ************************* TPO ************************* //

    getNewRemittance = (): Observable<any> => {
        return this._http.get<any>(this._newRemittanceAdvice, { responseType: 'dimension' as 'json' });
    }

    getRemittanceAdviceDetails = (id): Observable<any> => {
        let url = `${this._remittanceAdviceDetails}?id=${id}&direction=0`
        return this._http.get<any>(url, { responseType: 'dimension' as 'json' });
    }

    setRemittanceAdviceDownloaded = (id): Observable<any> => {
        let url = `${this._remittanceAdviceDownloaded}?id=${id}`
        return this._http.post(url, null, { responseType: 'dimension' as 'json' });
    }


    uploadAttachment = (uploadedFile: any): Observable<any> => {
        let url = `${this._remittanceAttachement}`

        const formData: FormData = new FormData();
        formData.append('file', uploadedFile[0], uploadedFile[0].name);

        return this._http.post(url, formData, { responseType: 'dimensionAttachement' as 'json' });
    }

    // ************************* TPO ************************* //


    // ************************* LOCAL URL ************************* //

    post = (data): Observable<any> => {
        return this._http.post<any>(this.remittanceClaim, JSON.stringify(data));
    }

    getDownloadedClaims(query) {

        let url = `${this._getDownloadedClaims}`;
        url += `?InvoiceNumber=${query.InvoiceNumber ? query.InvoiceNumber : ''}`;
        if (query.InvoiceDate) {
            url += `&InvoiceDate=${query.InvoiceDate.toISOString()}`;
        }
        url += `&PayerName=${query.PayerName ? query.PayerName : ''}`;
        url += `&PatientName=${query.PatientName ? query.PatientName : ''}`;
        url += `&DenialCode=${query.DenialCode ? query.DenialCode : ''}`;
        url += `&PaymentReference=${query.PaymentReference ? query.PaymentReference : ''}`;
        url += `&IsCloseStatus=${query.IsCloseStatus ? query.IsCloseStatus : ''}`;
        if (query.SettlementDate) {
            url += `&SettlementDate=${query.SettlementDate.toISOString()}`;
        }
        url += `&page=${query.PageNo ? query.PageNo : 1}`;
        url += `&Rows=${query.RowNo ? query.RowNo : 10}`;

        return this._http.get<any>(url);
    }

    getByClaimId(query, claimId) {

        let url = `${this._getByClaimId}`;
        url += `?ItemCode=${query.ItemCode ? query.ItemCode : ''}`;
        url += `&ItemCertaCode=${query.ItemCertaCode ? query.ItemCertaCode : ''}`;
        url += `&Title=${query.Title ? query.Title : ''}`;
        if (query.Quantity) {
            url += `&Quantity=${query.Quantity ? query.Quantity : ''}`;
        }
        if (query.Net) {
            url += `&Net=${query.Net ? query.Net : ''}`;
        }
        if (query.PaymentAmount) {
            url += `&PaymentAmount=${query.PaymentAmount ? query.PaymentAmount : ''}`;
        }
        url += `&DenialCode=${query.DenialCode ? query.DenialCode : ''}`;
        url += `&Comment=${query.Comment ? query.Comment : ''}`;

        return this._http.get<any>(`${url}&Page=${query.PageNo}&Rows=${query.RowNo}&ClaimId=${claimId}&IsRejection=${query.isRejection ? query.isRejection : false}`);
    }

    setRemittanceDownloadedLocal = (id): Observable<any> => {
        return this._http.patch(`${this._setRemittanceDownloadedLocal}?remittanceID=${id}`, null);
    }

    reSubmission = (claimId: number, data): Observable<any> => {
        return this._http.post<any>(`${this._resubmissionLocal}?statementId=${claimId}`, JSON.stringify(data));
    }

    setRemittanceStatusAsPending = (claimId: number): Observable<any> => {
        return this._http.patch(`${this._setRemittanceStatusAsPending}/${claimId}`, null);
    }

    setRemittanceStatusAsClose = (claimId: number): Observable<any> => {
        return this._http.patch(`${this._setRemittanceStatusAsClose}/${claimId}`, null);
    }
    // ************************* LOCAL URL ************************* //
}	