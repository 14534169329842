import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';

@Injectable()
export class ConsultationTypesDetailService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _walkInVisitServiceURL: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.consultationTypesDetail.url;
        this._walkInVisitServiceURL = this.actionUrl + _configuration.consultationTypesDetail.getWalkInVisitService;
    }


    public insert = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    public delete(id) {
        return this._http.delete<any>(`${this._url}?id=${id}`);
    }

    public getWalkInVisitService = (consultationTypeId, doctorId): Observable<any> => {
        return this._http.get<any>(`${this._walkInVisitServiceURL}?consultationTypeId=${consultationTypeId}&doctorId=${doctorId}`);
    }

}
