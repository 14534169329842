import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

@Injectable()
export class ReservationService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private http: HttpClient, private configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.reservation.url;
    }

    getAdmissionDoctor = (id): Observable<any> => {
        return this.http.get<any>(`${this.url}?admissionId=${id}`);
    }

    add(data: any) {
        return this.http.post<any>(this.url, JSON.stringify(data));
    }
}
