import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import 'style-loader!./advanceSearchRegistrationInformationAllStatus.scss';
import { PatientBasicSearchFilter } from 'app/shared/models/queryModel';
import { PatientBasicSearchService } from 'app/shared/services/queryModel/patientBasicSearch';
@Component({
    selector: 'advance-search-registration-information-all-status-cmp',
    templateUrl: './advanceSearchRegistrationInformationAllStatus.html',
})

export class AdvanceSearchRegistrationInformationAllStatusComponent implements OnInit, OnDestroy {

    @Output() onSearch = new EventEmitter<any>();
    patientBasicSearchFilter: PatientBasicSearchFilter = new PatientBasicSearchFilter();

    constructor(private _patientBasicSearchService: PatientBasicSearchService) {
    }

    ngOnInit() {


    }

    onSubmit() {
        // this variable to regardless  status for patient 
        const checkStatus = false;
        this._patientBasicSearchService.getSearchResult(this.patientBasicSearchFilter, checkStatus)
            .subscribe((data) => {
                this.onSearch.emit({ patientBasicSearchResult: data });

            });
    }

    ngOnDestroy() {
    }
}
