import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { HospitalPharmacy } from '../../../models/pharmacy/hospitalPharmacy';
import { ServiceBase } from 'app/shared/infrastructure';
import { forkJoin } from 'rxjs';
import { OrderApiConfiguration } from 'app/shared/services/order-and-billing';

@Injectable()
export class HospitalPharmacyService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getByIdUrl: string;
    private _getActivePharmaciesUrl: string;
    private _activePharmaciesByPharmacyType: string;
    private _patientStatementUrl: string;
    private _getActiveAuthPharmaciesUrl: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration, _orderApiConfiguration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.hospitalPharmacy.url;
        this._getByIdUrl = this.actionUrl + _configuration.hospitalPharmacy.getByIdUrl;
        this._getActivePharmaciesUrl = this.actionUrl + _configuration.hospitalPharmacy.getActivePharmaciesUrl;
        this._getActiveAuthPharmaciesUrl = this.actionUrl + _configuration.hospitalPharmacy.getActiveAuthPharmaciesUrl;
        this._activePharmaciesByPharmacyType = this.actionUrl + _configuration.hospitalPharmacy.activePharmaciesByPharmacyType;

        this._patientStatementUrl = _orderApiConfiguration.server + _orderApiConfiguration.patientStatement.url;
    }

    getAll = (): Observable<HospitalPharmacy[]> => {
        return this._http.get<any>(this._url);
    }

    getById = (id: number): Observable<HospitalPharmacy> => {
        return this._http.get<any>(this._getByIdUrl + '?id=' + id);
    }

    getActivePharmacies = (): Observable<HospitalPharmacy[]> => {
        return this._http.get<any>(this._getActivePharmaciesUrl);
    }

    getActiveAuthPharmacies = (): Observable<HospitalPharmacy[]> => {
        return this._http.get<any>(this._getActiveAuthPharmaciesUrl);
    }

    getActivePharmaciesByPharmacyType = (pharmacyType: number): Observable<HospitalPharmacy[]> => {
        return this._http.get<any>(this._activePharmaciesByPharmacyType + '?pharmacyType=' + pharmacyType);
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }

    getPatientStatementUrl = (patientId: number, encounterId: number, patientType: number): Observable<number> => {
        const url = `${this._patientStatementUrl}/StatusByEncounterInfo?patientId=${patientId}&&encounterId=${encounterId}&&patientType=${patientType}`;
        return this._http.get<any>(url);
    }

    getNewOrderInformation = (id: number, pharmacyType: number, patientId: number, encounterId: number, patientType: number):
        Observable<[HospitalPharmacy, HospitalPharmacy[], number]> => {

        // get Hospital Pharmacy Info
        const url1 = this.getById(id);
        // get Active Pharmacies By Pharmacy Type
        const url2 = this.getActivePharmaciesByPharmacyType(pharmacyType);
        // Check Patient Statement Status
        const url3 = this.getPatientStatementUrl(patientId, encounterId, patientType);

        return forkJoin([url1, url2, url3]);
    }
}
