export class VisitAdmissionsSearchResult {

    // Admission Properities
    PatientCode: string;
    FullName: string;
    OtherFullName: string;
    FirstName: string;
    FatherName: string;
    MidName: string;
    LastName: string;
    OtherFirstName: string;
    OtherFatherName: string;
    OtherMidName: string;
    OtherLastName: string;
    Address: string;
    BirthDate: Date;
    CityId: number;
    CountryId: number;
    TelCode: string;
    GenderId: number;
    IsUnknown: boolean;
    FileLocationId: number;
    NationalCode: string;
    ActualClass: any;
    RequestedClass: any;
    ActualClassId: number;
    RequestedClassId: number;
    SectionDescription: string;
    RoomCode: string;
    RoomId: number;
    BedId: number;
    BedCode: number;
    AdmissionDoctor: number;
    AdmissionDoctorDescription: string;
    DealingTypeDescription: string;
    AdmissionDate: Date;
    LengthOfStay: number;
    IsJudicial: boolean;
    JudicialComments: string;
    DischargeDoctorId: number;
    AdmissionReasonId: number;
    EscortCode: number;
    AdmissionNumber: number;
    ReferenceId: number;
    TransferMethod?: number;
    TransferBy?: number;
    TransferByText: string;
    // Visit Properties
    VisitNo: number;
    PartyId: number;
    RefernceId: number;
    DoctorId: number;
    VisitDate: Date;
    Status: number;
    DischargeStatus: boolean;
    Remarks: string;
    UserId: number;
    PcName: string;
    EntryDate: Date;
    ComplainId: number;
    TelephoneType: number;
    TelephoneNo: string;
    IsErCase: boolean;
    AccidentId: number;
    IsPoliceCase: boolean;
    DischargeDoctor: number;
    DischargePartyId: number;
    DischargeReferanceId: number;
    ApptId: number;
    CrisisId: number;
    JudicialDischargeStatus: boolean;
    AttendNo: number;
    DischargeBy: number;
    DischargePc: string;
    CreatedBy: number;
    CreatedDate: Date;
    ModifiedBy: number;
    ModifiedDate: Date;
    ExternalDoctor: string;
    ExaminationReason: string;
    CancelReason: string;
    CancellationDate: Date;
    CancellationBy: number;
    ErAreaId: number;
    PoliceCaseNote: string;
    SessionNo: number;
    ConsultationTypeId: number;
    AppointmentDate: Date;
    IsAccident: boolean;
    Source: string;
    SourceId: number;
    Facility: any;
    OrganizationSection: any;
    Patient: any;
    FromInstance: any;
    DoctorStaff: any;

    AdmissionDoctorFullName?: string;
    AdmissionDoctorOtherFullName?: string;
    // Shared Properties
    Type: number;
    Id: number;
    SectionId: number;
    WareHouseCode: string;
    FacilityId: number;
    PatientId: number;
    DealingType: number;
    DischargeDate: Date;
    CashPriceListId?: number;
    OldCashPriceListId?: number;
    SponsorName?: string;
    IsBlocked: boolean;
    BlockReason: string;
    FormNumber: string;
    constructor() { }
}
