import { Component, Input, ViewEncapsulation } from '@angular/core';
import { isMobile } from 'app/shared';

@Component({
    selector: 'ba-card',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./ba-card.scss'],
    templateUrl: './baCard.html',
})
export class BaCard {
    @Input() title: String;
    @Input() baCardClass: String;
    @Input() cardType: String;
    @Input() staticHeight: boolean = false;


    validateMobile() {
        if (isMobile()) {
            return true;
        }
        return false;
    }
}
