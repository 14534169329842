import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { Router } from '@angular/router';
import { CountService } from 'app/shared/services/notificationService/count.service';
import { SignalrService } from 'app/shared/services';
import { Notification } from 'app/shared/models/hubs/enums//notification';
import { ReceiverNotification } from 'app/shared/models/hubs/receiver-notification';
import { ReceiveNotificationService } from 'app/shared/services/his-notification';
import { BaseComponent } from 'app/shared/infrastructure';
import { SystemSetting } from 'app/shared/models/enum/system-setting';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'notifications-cmp',
    styleUrls: ['./notifications.scss'],
    templateUrl: './notifications.html',
    encapsulation: ViewEncapsulation.None,
})


export class NotificationsComponent extends BaseComponent implements OnInit, OnDestroy {

    // we need this var on afterViewChecked method to check if service recieved or not
    isReceivedNotification: boolean;
    notifications: ReceiverNotification[] = [];
    unReadNotificationCount: number;
    securityServer: string;

    public constructor(private _router: Router,
        private _countService: CountService,
        private _signalrService: SignalrService,
        private _receiveNotificationService: ReceiveNotificationService,
        private _changeDetectorRef: ChangeDetectorRef) {
        super();
        this.securityServer = this._systemSettingsService.getAppConstantByName(SystemSetting.AuthorityService);
    }

    public ngOnInit() {
        this._signalrService.on(Notification.Name, Notification.ReceiveNotification)
            .subscribe((value: ReceiverNotification) => {
                if (value) {
                    this.notifications.unshift(value);
                    if (this.notifications.length > 20) {
                        this.notifications.splice(this.notifications.length - 1, 1)
                    }
                    this.calculateUnReadCount();
                }
            });

        this._countService.unreadCount
            .subscribe(value => {
                this.unReadNotificationCount = value;
            });

        if (this.enableNotification()) {
            interval(2000)
                .pipe(takeWhile(() => !this.isReceivedNotification))
                .subscribe(() => {
                    this.getLatestNotifications();
                });
        }

        this._changeDetectorRef.detectChanges();
    }

    getLatestNotifications() {
        const userData = JSON.parse(sessionStorage.getItem(`oidc.user:${this.securityServer}:js`))?.profile;
        if (!userData) {
            return;
        }
        this.notifications = [];
        const roles = Array.isArray(userData.role) ? userData.role?.join(',') : userData.role;
        this._receiveNotificationService.getLatestNotifications(userData.staff_id, roles)
            .subscribe((notifications: ReceiverNotification[]) => {
                if (notifications && notifications.length > 0) {
                    this.notifications = notifications;
                }
                this.calculateUnReadCount();
                this.isReceivedNotification = true;
            }, error => {
                this.isReceivedNotification = true;
            });
    }

    openNote(note: ReceiverNotification) {
        this._receiveNotificationService.markAsRead(note.id).subscribe(value => {
            if (value) {
                note.markAsRead = true;
                if (note.url) {
                    let externalUrl = this.externalUrl(note.receiverModule);
                    if (externalUrl) {
                        this.openLinkInNewTab(externalUrl + note.url);
                    }
                    else {
                        this._router.navigateByUrl(note.url);
                    }
                }
                this.calculateUnReadCount();
                jQuery('.dropdown-menu').removeClass('show');
            }
        });
    }

    calculateUnReadCount() {
        const unreadNotification = this.notifications?.filter(f => !f.markAsRead);
        this._countService.unreadCount.next(unreadNotification?.length || 0);
    }

    public ngOnDestroy() {
    }
}
