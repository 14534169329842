import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Observable } from 'rxjs-compat/Observable'
import { PackagePlan } from 'app/shared/models/orders-and-billing/package-plan';

@Injectable()
export class PackagePlanService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getByPatientAndPackageIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.packagePlan.url;
        this.getByPatientAndPackageIdUrl = this.actionUrl + _configuration.packagePlan.getByPatientAndPackageIdUrl;
    }

    getByPatientAndPackageId = (patientPackageSessionId): Observable<PackagePlan[]> => {
        return this._http.get<any>(`${this.getByPatientAndPackageIdUrl}?patientPackageSessionId=${patientPackageSessionId}`)
    }

    public insert(data) {
        return this._http.post<any>(this.url, JSON.stringify(data));
    }

    public update(data) {
        return this._http.put<any>(this.url, data);
    }
}
