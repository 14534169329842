import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable';
import { LabApiConfiguration } from '../apiConfiguration';
import { LabReportTemplateInfo } from 'app/shared/models/lab/lab-report-template-info';

// Models
import {
    SpecimenTransactionViewModel, SpecimenViewModel, SpecimenTransaction,
    ExternalOrderStationSearchModel, TatMonitorSearchModel, SentExternalLaboratory,
    SpecimenTest, SpecimenTestFilterModel
} from 'app/shared/models/lab';

import { ServiceBase } from 'app/shared/infrastructure';
import { map } from 'rxjs/operators';

@Injectable()
export class SpecimenTransactionService extends ServiceBase {

    private actionUrl: string;
    private _specimenFilter: string;
    private _filterSpecimenResultText: string;
    private _filterSpecimenTransactionForTechnicianWorkbench: string;
    private _filterSensitivityResultEntry: string;
    private filterExternalLabSpecimenItemsUrl: string;
    private _exportExternalLabSpecimenItemsToCsv: string;
    private filterTatMonitorUrl: string;
    private _getSpecimenTransactionPatientHistoryForItem: string;
    private _getPreviousReleasedTestsByPatientId: string;
    private _filterLabOrderSpecimenTestSearch: string;
    private _getSpecimenTransactionsForCultureTests: string;
    private _getOrderStatus: string;
    private _uncollectedSpecimenFilter: string;
    private _specimenDetail: string;
    private _specimenTransactionToAnalyzer: string;
    private _reRunTest: string;
    private _searchEngineUrl: string;
    public results: any;
    private _isTestRequestedBefore: string;
    private _labReportTypeUrl: string;
    private _isCultureTestOrderedBefore: string;
    private _labReportTypeByEncounterUrl: string;
    private _hasTextReportUrl: string;
    private _specimenStatusCanceledOrReleasedUrl: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._getSpecimenTransactionsForCultureTests = this.actionUrl + _configuration.specimenTransaction.getSpecimenTransactionsForCultureTests;
        this._specimenFilter = this.actionUrl + _configuration.specimenTransaction.specimenFilter;
        this._filterSpecimenResultText = this.actionUrl + _configuration.specimenTransaction.filterSpecimenResultText;
        this._filterSpecimenTransactionForTechnicianWorkbench = this.actionUrl + _configuration.specimenTransaction.filterSpecimenTransactionForTechnicianWorkbench;
        this._filterSensitivityResultEntry = this.actionUrl + _configuration.specimenTransaction.filterSensitivityResultEntry;
        this.filterExternalLabSpecimenItemsUrl = this.actionUrl + _configuration.specimenTransaction.filterExternalLabSpecimenItemsUrl;
        this.filterTatMonitorUrl = this.actionUrl + _configuration.specimenTransaction.filterTatMonitorUrl;
        this._getSpecimenTransactionPatientHistoryForItem = this.actionUrl + _configuration.specimenTransaction.getSpecimenTransactionPatientHistoryForItem;
        this._getPreviousReleasedTestsByPatientId = this.actionUrl + _configuration.specimenTransaction.getPreviousReleasedTestsByPatientId;
        this._filterLabOrderSpecimenTestSearch = this.actionUrl + _configuration.specimenTransaction.filterLabOrderSpecimenTestSearch;
        this._getOrderStatus = this.actionUrl + _configuration.specimenTransaction.orderStatus;
        this._uncollectedSpecimenFilter = this.actionUrl + _configuration.specimenTransaction.uncollectedSpecimenFilter;
        this._specimenDetail = this.actionUrl + _configuration.specimenTransaction.specimenDetail;
        this._specimenTransactionToAnalyzer = this.actionUrl + _configuration.specimenTransaction.specimenTransactionToAnalyzer;
        this._reRunTest = this.actionUrl + _configuration.specimenTransaction.rerunTest;
        this._searchEngineUrl = this.actionUrl + _configuration.specimenTransaction.searchEngineUrl;
        this._exportExternalLabSpecimenItemsToCsv = this.actionUrl + _configuration.specimenTransaction.exportExternalLabSpecimenItemsToCsv;
        this._isTestRequestedBefore = this.actionUrl + _configuration.specimenTransaction.isTestRequestedBefore;
        this._labReportTypeUrl = this.actionUrl + _configuration.specimenTransaction.labReportType;
        this._isCultureTestOrderedBefore = this.actionUrl + _configuration.specimenTransaction.isCultureTestOrderedBefore;
        this._labReportTypeByEncounterUrl = this.actionUrl + _configuration.specimenTransaction.labReportTypeByEncounter;
        this._hasTextReportUrl = this.actionUrl + _configuration.specimenTransaction.hasTextReport;
        this._specimenStatusCanceledOrReleasedUrl = this.actionUrl + _configuration.specimenTransaction.allSpecimenStatusCanceledOrReleased;
    }

    getPreviousReleasedTests = (patientId: number, itemId: number): Observable<SpecimenTransaction[]> => {
        const url = `${this._getPreviousReleasedTestsByPatientId}?patientId=${patientId}&&itemId=${itemId}`;
        return this._http.get<any>(url);
    }

    specimenFilter = (viewModel: SpecimenTransactionViewModel): Observable<any> => {
        const orderFromString = viewModel.OrderFrom != undefined ? viewModel.OrderFrom.toISOString() : undefined;
        const orderToString = viewModel.OrderTo != undefined ? viewModel.OrderTo.toISOString() : undefined;

        const url = `${this._specimenFilter}?LabSection=${viewModel.LabSection}&&OrderFrom=${orderFromString}&&OrderNumber=${viewModel.OrderNumber}` +
            `&&OrderTo=${orderToString}&&PatientCode=${viewModel.PatientCode}&&SpecimenNumber=${viewModel.SpecimenNumber}&&SpecimenType=${viewModel.SpecimenType}&&Status=${viewModel.Status}`;

        return this._http.get<any>(url);
    }

    uncollectedSpecimenFilter = (viewModel: SpecimenViewModel): Observable<any> => {

        const orderDateString = viewModel.OrderDate != undefined ? viewModel.OrderDate.toISOString() : null;

        let queryString: string = '';
        queryString += '?SpecimenNumber=';
        if (viewModel.SpecimenNumber)
            queryString += viewModel.SpecimenNumber;

        queryString += '&SpecimenType=';
        if (viewModel.SpecimenType)
            queryString += viewModel.SpecimenType;

        queryString += '&Status=';
        if (viewModel.Status)
            queryString += viewModel.Status;

        queryString += '&OrderNumber=';
        if (viewModel.OrderNumber)
            queryString += viewModel.OrderNumber;

        queryString += `&OrderDate=${orderDateString}`;

        queryString += '&PatientCode=';
        if (viewModel.PatientCode)
            queryString += viewModel.PatientCode;

        queryString += '&PatientName=';
        if (viewModel.PatientName)
            queryString += viewModel.PatientName;

        queryString += '&PatientSection=';
        if (viewModel.PatientSection)
            queryString += viewModel.PatientSection;

        queryString += '&OrderDoctor=';
        if (viewModel.OrderDoctor)
            queryString += viewModel.OrderDoctor;

        return this._http.get<any>(this._uncollectedSpecimenFilter + queryString);
    }

    getSpecimenDetail = (specimenNo: number): Observable<any> => {
        const url = `${this._specimenDetail}?specimenNo=${specimenNo}`;

        return this._http.get<any>(url);
    }

    sendSpecimenTransactionToAnalyzer = (specimenTransactionModel: SpecimenTransaction): Observable<any> => {
        return this._http.post<any>(this._specimenTransactionToAnalyzer, specimenTransactionModel);
    }

    filterSpecimenResultText = (viewModel: SpecimenTransactionViewModel): Observable<SpecimenTransaction[]> => {
        let params = [];
        params.push("LabSection=" + (viewModel.LabSection ? viewModel.LabSection : null));
        params.push("OrderFrom=" + (viewModel.OrderFrom ? viewModel.OrderFrom.toDateString() : null));
        params.push("OrderNumber=" + (viewModel.OrderNumber ? viewModel.OrderNumber : null));
        params.push("OrderTo=" + (viewModel.OrderTo ? viewModel.OrderTo.toDateString() : ''));
        params.push("PatientCode=" + (viewModel.PatientCode ? viewModel.PatientCode : ''));
        params.push("AccessionNumber=" + (viewModel.AccessionNumber ? viewModel.AccessionNumber : ''));

        let queryString = params.join("&").replace(/%20/g, '+');
        return this._http.get<any>(this._filterSpecimenResultText + "?" + queryString);
    }

    filterSensitivityResultEntry = (viewModel: SpecimenTransactionViewModel): Observable<any> => {
        const orderFromString = viewModel.OrderFrom != undefined ? viewModel.OrderFrom.toLocaleDateString() : undefined;
        const orderToString = viewModel.OrderTo != undefined ? viewModel.OrderTo.toLocaleDateString() : undefined;

        const url = `${this._filterSensitivityResultEntry}?LabSection=${viewModel.LabSection}&&OrderFrom=${orderFromString}&&OrderNumber=${viewModel.OrderNumber}` +
            `&&OrderTo=${orderToString}&&PatientCode=${viewModel.PatientCode}&&AccessionNumber=${viewModel.SpecimenNumber}`;

        return this._http.get<any>(url);
    }

    filterSpecimenTransactionForTechnicianWorkbench = (viewModel: SpecimenTransactionViewModel, page: number, rows: number): Observable<SpecimenTransaction[]> => {
        const orderFromString = viewModel.OrderFrom ? viewModel.OrderFrom.toISOString() : null;
        const orderToString = viewModel.OrderTo ? viewModel.OrderTo.toISOString() : null;
        const PatientTelCodeString = viewModel.PatientTelCode ? viewModel.PatientTelCode.toString() : '';
        viewModel.PatientCode = viewModel.PatientCode ? viewModel.PatientCode : '';
        viewModel.PatientName = viewModel.PatientName ? viewModel.PatientName : '';

        const url = `${this._filterSpecimenTransactionForTechnicianWorkbench}?LabSection=${viewModel.LabSection}&&OrderFrom=${orderFromString}&&OrderNumber=${viewModel.OrderNumber}` +
            `&&OrderTo=${orderToString}&&PatientCode=${viewModel.PatientCode}&&PatientName=${viewModel.PatientName}&&PatientTelCode=${PatientTelCodeString}&&SpecimenNumber=${viewModel.SpecimenNumber}&&Status=${viewModel.Status}&&page=${page}&&rows=${rows}`;

        return this._http.get<any>(url);
    }

    filterExternalLabSpecimenItems = (searchModel: ExternalOrderStationSearchModel): Observable<SentExternalLaboratory[]> => {
        let params = [];
        params.push("accessionNumber=" + (searchModel.accessionNumber != null ? searchModel.accessionNumber : ''));
        params.push("patientMrn=" + (searchModel.patientMrn != null ? searchModel.patientMrn : ''));
        params.push("orderNumber=" + (searchModel.orderNumber != null ? searchModel.orderNumber : ''));
        params.push("testCode=" + (searchModel.testCode != null ? searchModel.testCode : ''));
        params.push("fromOrderDate=" + (searchModel.fromOrderDate != null ? searchModel.fromOrderDate.toISOString() : ''));
        params.push("toOrderDate=" + (searchModel.toOrderDate != null ? searchModel.toOrderDate.toISOString() : ''));
        params.push("ExternalLaboratoryId=" + (searchModel.externalLaboratoryId != null ? searchModel.externalLaboratoryId : ''));

        let queryString = params.join("&").replace(/%20/g, '+');
        return this._http.get<any>(this.filterExternalLabSpecimenItemsUrl + "?" + queryString);
    }

    exportExternalLabSpecimenItemsToCsv = (searchModel: ExternalOrderStationSearchModel): Observable<any> => {

        let params = [];
        params.push("accessionNumber=" + (searchModel.accessionNumber != null ? searchModel.accessionNumber : ''));
        params.push("patientMrn=" + (searchModel.patientMrn != null ? searchModel.patientMrn : ''));
        params.push("orderNumber=" + (searchModel.orderNumber != null ? searchModel.orderNumber : ''));
        params.push("testCode=" + (searchModel.testCode != null ? searchModel.testCode : ''));
        params.push("fromOrderDate=" + (searchModel.fromOrderDate != null ? searchModel.fromOrderDate.toISOString() : ''));
        params.push("toOrderDate=" + (searchModel.toOrderDate != null ? searchModel.toOrderDate.toISOString() : ''));
        params.push("ExternalLaboratoryId=" + (searchModel.externalLaboratoryId != null ? searchModel.externalLaboratoryId : ''));
        let queryString = params.join("&").replace(/%20/g, '+');

        return this._http.get<any>(`${this._exportExternalLabSpecimenItemsToCsv}?${queryString}`,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }
                    return response;
                }));
    }

    filterTatMonitor = (searchModel: TatMonitorSearchModel): Observable<any> => {
        let params = [];
        params.push("accessionNumber=" + (searchModel.accessionNumber != null ? searchModel.accessionNumber : ""));
        params.push("patientMrn=" + (searchModel.patientMrn != null ? searchModel.patientMrn : ""));
        params.push("orderNumber=" + (searchModel.orderNumber != null ? searchModel.orderNumber : ""));
        params.push("labSectionId=" + (searchModel.labSectionId != null ? searchModel.labSectionId : ""));
        params.push("fromOrderDate=" + (searchModel.fromOrderDate != null ? searchModel.fromOrderDate.toISOString() : ""));
        params.push("toOrderDate=" + (searchModel.toOrderDate != null ? searchModel.toOrderDate.toISOString() : ""));

        let queryString = params.join("&").replace(/%20/g, '+');
        return this._http.get<any>(this.filterTatMonitorUrl + "?" + queryString);
    }

    getSpecimenTransactionPatientHistoryForItem = (patientId: number, itemId?: number, groupId?: number): Observable<SpecimenTransaction[]> => {
        const url = `${this._getSpecimenTransactionPatientHistoryForItem}?patientId=${patientId}&&itemId=${itemId}&&groupId=${groupId}`;
        return this._http.get<any>(url);
    }

    getSpecimenTransactionsForCultureTests = (orderId: number): Observable<SpecimenTransaction[]> => {
        const url = `${this._getSpecimenTransactionsForCultureTests}?orderId=${orderId}&&itemId=`;
        return this._http.get<any>(url);
    }

    getOrderStatus = (orderIds: string): Observable<any> => {
        const url = `${this._getOrderStatus}?orderIds=${orderIds}`;
        return this._http.get<any>(url);
    }

    reRunTest = (orderId: number, specimenNo: number): Observable<boolean> => {
        const url = `${this._reRunTest}?orderId=${orderId}&&specimenNo=${specimenNo}`;
        return this._http.get<any>(url);
    }

    getOrderSpecimenTestSearch = (viewModel: SpecimenTestFilterModel, page: number, rows: number): Observable<SpecimenTest[]> => {
        const orderFromString = viewModel.OrderFrom ? viewModel.OrderFrom.toDateString() : null;
        const orderToString = viewModel.OrderTo ? viewModel.OrderTo.toDateString() : null;

        let params = [];
        params.push("SpecimenNumber=" + (viewModel.SpecimenNumber ? viewModel.SpecimenNumber : null));
        params.push("PatientCode=" + (viewModel.PatientCode ? viewModel.PatientCode : ''));
        params.push("LabSectionId=" + (viewModel.LabSectionId ? viewModel.LabSectionId : null));
        params.push("OrderNumber=" + (viewModel.OrderNumber ? viewModel.OrderNumber : null));
        params.push("TestName=" + (viewModel.TestName ? viewModel.TestName : ''));
        params.push("OrderPhysicianId=" + (viewModel.OrderPhysicianId ? viewModel.OrderPhysicianId : null));
        params.push("OrderFrom=" + orderFromString);
        params.push("OrderTo=" + orderToString);
        params.push("ExistResultStatusId=" + (viewModel.ExistResultStatusId ? viewModel.ExistResultStatusId : null));
        params.push("ResultTypeId=" + (viewModel.ResultTypeId ? viewModel.ResultTypeId : null));
        params.push("CultureResultId=" + (viewModel.CultureResultId ? viewModel.CultureResultId : null));
        params.push("SpecimenTypeId=" + (viewModel.SpecimenTypeId ? viewModel.SpecimenTypeId : null));
        params.push("AccessionNumber=" + (viewModel.AccessionNumber ? viewModel.AccessionNumber : ''));
        params.push("PatientName=" + (viewModel.PatientName ? viewModel.PatientName : ''));
        params.push("SpecimenTestStatusId=" + (viewModel.SpecimenTestStatusId ? viewModel.SpecimenTestStatusId : null));
        params.push("TestCode=" + (viewModel.TestCode ? viewModel.TestCode : ''));
        params.push("Page=" + page);
        params.push("Rows=" + rows);

        let queryString = params.join("&").replace(/%20/g, '+');
        return this._http.get<any>(`${this._searchEngineUrl}?${queryString}`);
    }

    isTestRequestedBefore = (itemId: number, patientId: number, orderDate: string): Observable<boolean> => {
        const url = `${this._isTestRequestedBefore}?itemId=${itemId}&&patientId=${patientId}&&orderDate=${orderDate}`;
        return this._http.get<any>(url);
    }

    getLabReportType = (orderNumber?: number, patientcode?: string, orderFrom = null, orderTo = null): Observable<LabReportTemplateInfo> => {
        return this._http.get<any>(`${this._labReportTypeUrl}?orderId=${orderNumber}&patientcode=${patientcode}&orderFrom=${orderFrom}&orderTo=${orderTo}`);
    }

    isCultureTestOrderedBefore = (orderNumber?: number, patientcode?: string, orderFrom = null, orderTo = null): Observable<boolean> => {
        return this._http.get<any>(`${this._isCultureTestOrderedBefore}?orderId=${orderNumber}&patientcode=${patientcode}&orderFrom=${orderFrom}&orderTo=${orderTo}`);
    }

    getLabReportTypeByEncounter = (patientId: number, encounterId: number, patientTypeId: number): Observable<LabReportTemplateInfo> => {
        return this._http.get<any>(`${this._labReportTypeByEncounterUrl}?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`);
    }

    hasTextReport = (orderNumber?: number, patientcode?: string, orderFrom = null, orderTo = null): Observable<boolean> => {
        return this._http.get<any>(`${this._hasTextReportUrl}?orderId=${orderNumber}&patientcode=${patientcode}&orderFrom=${orderFrom}&orderTo=${orderTo}`);
    }

    allSpecimenStatusCanceledOrReleased = (patientId: number): Observable<boolean> => {
        return this._http.get<any>(`${this._specimenStatusCanceledOrReleasedUrl}?patientId=${patientId}`);
    }
}
