import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { VisitLookup } from '../../../models/core/visit-lookup';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { DoctorPatient } from '../../../models/physician-desktop/doctor-patient';
import { VisitAdmissionsSearchResult } from '../../../models/queryModel/visitAdmissionsSearchResult';
import { AddWalkinVisitViewModel } from 'app/shared/models/core/add-walkin-visit-view-model';
import { PatientVisitFilter } from 'app/shared/models/core/patient-visit-filter';
import { PatienVisit } from 'app/shared/models/core/patientVisit';
import { PatientEncounterInfo } from 'app/shared/models/queryModel/patient-encounter-info';

@Injectable()
export class PatientVisitService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _walkInVisit: string;
    private _erVisit: string;
    private _openedVisitsForSearch: string;
    private _patientVisitByDoctorId: string;
    private _patientVisitByNurseId: string;
    private _listAllErVisitsOfPatient: string;
    private _visitStatusById: string;
    private _patientVisitBySearch: string;
    private _reOpenPatientVisit: string;
    private _getVisitDate: string;
    private _dischargePatientUrl: string;
    private _notifyPyxisOnPatientUpdateUrl: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.patientVisit.url;
        this._walkInVisit = this.actionUrl + _configuration.patientVisit.walkInVisit;
        this._erVisit = this.actionUrl + _configuration.patientVisit.erVisit;
        this._openedVisitsForSearch = this.actionUrl + _configuration.patientVisit.openedVisitsForSearch;
        this._patientVisitByDoctorId = this.actionUrl + _configuration.patientVisit.patientVisitByDoctorId;
        this._patientVisitByNurseId = this.actionUrl + _configuration.patientVisit.patientVisitByNurseId;
        this._listAllErVisitsOfPatient = this.actionUrl + _configuration.patientVisit.listAllErVisitsOfPatient;
        this._visitStatusById = this.actionUrl + _configuration.patientVisit.visitStatusById;
        this._patientVisitBySearch = this.actionUrl + _configuration.patientVisit.patientVisitBySearch;
        this._reOpenPatientVisit = this.actionUrl + _configuration.patientVisit.reOpenPatientVisit;
        this._getVisitDate = this.actionUrl + _configuration.patientVisit.getVisitDate;
        this._dischargePatientUrl = this.actionUrl + _configuration.patientVisit.dischargePatient;
        this._notifyPyxisOnPatientUpdateUrl = this.actionUrl + _configuration.patientVisit.notifyPyxisOnPatientUpdate;
    }

    getVisitStatusById = (visitId): Observable<any> => {
        return this._http.get<any>(this._visitStatusById + '?visitId=' + visitId);
    }

    getById = (id: number): Observable<any> => {
        return this._http.get<any>(this._url + "/GetById?Id=" + id);
    }

    getVisitDate = (id: number): Observable<any> => {
        return this._http.get<any>(this._getVisitDate + "?Id=" + id);
    }

    listAllErVisitsOfPatient = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._listAllErVisitsOfPatient + '?patientId=' + patientId);
    }

    patientVisitInfo = (id: number, checkStatus = true): Observable<any> => {
        const url = `${this._url}/PatientVisitInfo?id=${id}`;
        return this._http.get<any>(url);
    }

    getOpenedVisitsForSearch = (patientId): Observable<any> => {
        return this._http.get<any>(this._openedVisitsForSearch + '?patientId=' + patientId);
    }

    getOpenedVisits = (patientId): Observable<VisitLookup[]> => {
        return this._http.get<any>(this._url + "/OpenedVisitId?patientId=" + patientId)
            .map((response: Response) => {

                let objects = response;
                let visits: VisitLookup[] = [];
                for (let index in objects) {
                    visits.push(new VisitLookup(objects[index]));
                }
                return visits || [];
            });
    }

    getLastVisitDate = (sectionId: number, doctorId: number, patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '?sectionId=' + sectionId + '&&doctorId=' + doctorId + '&&patientId=' + patientId);
    }

    addWalkInVisit = (patientId: number, data: any): Observable<any> => {
        return this._http.post<any>(this._walkInVisit + '?walkInPatientId=' + patientId, data);
    }

    addErVisit = (patientId: number, data: any): Observable<any> => {
        return this._http.post<any>(this._erVisit + '?erPatientId=' + patientId, data);
    }

    getPatientVisitByDoctorId = (isActiveEncounter: Boolean): Observable<any> => {
        return this._http.get<any>(this._patientVisitByDoctorId + '?isActiveEncounter=' + isActiveEncounter);
    }

    public getPatientVisitByNurseId = (isActiveEncounter: Boolean): Observable<any> => {
        return this._http.get<any>(this._patientVisitByNurseId + '?isActiveEncounter=' + isActiveEncounter);
    }

    updateVisitDetails = (visitAdmissionsSearchResult: VisitAdmissionsSearchResult): Observable<any> => {
        return this._http.put<any>(this._url + '?cashPriceListId=' + visitAdmissionsSearchResult.CashPriceListId, JSON.stringify(visitAdmissionsSearchResult));
    }


    getOpenPatientVisitById = (visitId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/OpenPatientVisitById?visitId=' + visitId);
    }

    public getPatientVisitBySearch = (patientVisitFilter: PatientVisitFilter, page: number, rows: number): Observable<any> => {
        const patientId = patientVisitFilter.PatientId ? patientVisitFilter.PatientId : null;
        const patientCode = patientVisitFilter.PatientCode ? patientVisitFilter.PatientCode : '';
        const patientName = patientVisitFilter.PatientName ? patientVisitFilter.PatientName : '';
        const sectionId = patientVisitFilter.SectionId ? patientVisitFilter.SectionId : null;
        var visitDate = patientVisitFilter.VisitDate ? patientVisitFilter.VisitDate.toDateString() : null;
        const dealingTypeId = patientVisitFilter.DealingTypeId ? patientVisitFilter.DealingTypeId : null;
        const statusId = patientVisitFilter.StatusId ? patientVisitFilter.StatusId : null;
        const consultationTypeId = patientVisitFilter.ConsultationTypeId ? patientVisitFilter.ConsultationTypeId : null;
        const sourceId = patientVisitFilter.SourceId ? patientVisitFilter.SourceId : null;
        const doctorId = patientVisitFilter.DoctorId ? patientVisitFilter.DoctorId : null;

        return this._http.get<any>(this._patientVisitBySearch
            + '?PatientId=' + patientId
            + '&&PatientCode=' + patientCode
            + '&&PatientName=' + patientName
            + '&&SectionId=' + sectionId
            + '&&VisitDate=' + visitDate
            + '&&DealingTypeId=' + dealingTypeId
            + '&&StatusId=' + statusId
            + '&&ConsultationTypeId=' + consultationTypeId
            + '&&DoctorId=' + doctorId
            + '&&SourceId=' + sourceId
            + '&&PageIndex=' + page
            + '&&PageSize=' + rows
        );
    }

    reOpenPatientVisit = (visitId): Observable<any> => {
        return this._http.put<any>(`${this._reOpenPatientVisit}?visitId=${visitId}`
            , null);
    }

    getAllPatientEncounters = (patientId: number): Observable<any> => {
        return this._http.get<any>(this._url + '/AllPatientEncounters?patientId=' + patientId);
    }

    dischargePatient(visitId: number) {
        return this._http.post<any>(this._dischargePatientUrl, visitId);
    }

    notifyPyxisOnPatientUpdate = (patientId: number) => {
        return this._http.post<any>(this._notifyPyxisOnPatientUpdateUrl, patientId);
    }
}
