import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { PBLSetup } from '../../../models/core/pbl-setup';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Subject } from 'rxjs';
@Injectable()
export class FavoritePageStaffService extends ServiceBase {

    isOpen: Subject<true> = new Subject<true>();
    private actionUrl: string;

    private _url: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.favoritePageStaff.url;
    }

    get = (): Observable<PBLSetup> => {
        const url = `${this._url}`
        return this._http.get<any>(url);
    }

    add = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, data);
    }

    delete = (id: number): Observable<any> => {
        return this._http.delete(`${this._url}/${id}`);
    }
}
