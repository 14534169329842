import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { AdmissionServiceApiConfiguration } from '../admissionServiceApiConfiguration';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';

@Injectable()
export class TransferService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: AdmissionServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.transfer.url;
    }

    public getAll = (): Observable<any> => {
        return this._http.get<any>(this._url);
    }

    public insert = (data: any): Observable<any> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    public update = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, JSON.stringify(data));
    }

    // handel accomommdation

    public getTransferByVisit = (id): Observable<any> => {
        return this._http.get<any>(this._url + '/GetTransferByAdmissionId?addmissionId=' + id);
    }

    // handel update accomodation tansfer 

    public updateTransfer = (transferId, data): Observable<any> => {
        let updateTrans = `/ChangeAccomodation?transferId=${transferId}`;
        return this._http.put<any>(this._url + updateTrans, JSON.stringify(data));
    }

    public getTransfersByAdmissionIdAndPatientId = (patientId, admissionId): Observable<any> => {
        // const query=`?addmissionId=${admissionId}&&patientId=${patientId}`;
        return this._http.get<any>(this._url + '/TransferByAdmissionIdAndPatientId?patientId=' + patientId + '&&admissionId=' + admissionId);
    }
}