import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ItemSearch } from '../../../models/pharmacy/item-search';
@Injectable()
export class PharmacyFavoriteListService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    private _addList: string;
    private _favoriteItems: string;
    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.favoriteList.url;
        this._addList = this.actionUrl + _configuration.favoriteList.addList;
        this._favoriteItems = this.actionUrl + _configuration.favoriteList.favoriteItems;
    }

    public addList = (data: any): Observable<any> => {
        return this._http.post<any>(this._addList, data);
    }

    getFavoriteItems = (itemSearchQuery: string, isFavPrescriptionSearch: boolean, isQuickPrescriptionSearch: boolean): Observable<ItemSearch[]> => {

        return this._http.get<any>(`${this._favoriteItems}` + '?query=' + itemSearchQuery + '&FavoritePrescription=' + isFavPrescriptionSearch + '&QuickPrescription=' + isQuickPrescriptionSearch);
    }
}