import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { TpaInvoiceErrorInfo } from 'app/shared/models/queryModel/tpa-invoice-error-info';
import { TpaInvoiceNotMappedItem } from 'app/shared/models/queryModel/tpa-invoice-not-mapped-item';

@Injectable()
export class InvoiceService extends ServiceBase {

    private actionUrl: string;
    private tpaIssuedInvoiceUrl: string;
    private tpaUnIssuedInvoiceUrl: string;
    private tpaClaimInvoiceUrl: string;
    private tpaDischargeInvoiceUrl: string;
    private tpaInvoiceErrorInfoUrl: string;
    private tpaNotMappedItemUrl: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.tpaIssuedInvoiceUrl = this.actionUrl + _configuration.invoice.tpaIssuedInvoice;
        this.tpaUnIssuedInvoiceUrl = this.actionUrl + _configuration.invoice.tpaUnIssuedInvoice;
        this.tpaClaimInvoiceUrl = this.actionUrl + _configuration.invoice.tpaClaimInvoice;
        this.tpaDischargeInvoiceUrl = this.actionUrl + _configuration.invoice.tpaDischargeInvoice;
        this.tpaInvoiceErrorInfoUrl = this.actionUrl + _configuration.invoice.tpaInvoiceErrorInfo;
        this.tpaNotMappedItemUrl = this.actionUrl + _configuration.invoice.tpaNotMappedItem;
    }

    tpaIssuedInvoice = (patientId: number, encounterId: number, patientTypeId: number): Observable<any> => {
        const url = `${this.tpaIssuedInvoiceUrl}?patientId=${patientId}&&encounterId=${encounterId}&&patientTypeId=${patientTypeId}`;
        return this._http.post<any>(url, null);
    }

    tpaUnIssuedInvoice = (patientId: number, encounterId: number, patientTypeId: number): Observable<any> => {
        const url = `${this.tpaUnIssuedInvoiceUrl}?patientId=${patientId}&&encounterId=${encounterId}&&patientTypeId=${patientTypeId}`;
        return this._http.post<any>(url, null);
    }

    tpaClaimInvoice = (claimId: number): Observable<any> => {
        const url = `${this.tpaClaimInvoiceUrl}?claimId=${claimId}`;
        return this._http.post<any>(url, null);
    }

    tpaDischargeInvoice = (patientId: number, encounterId: number, patientTypeId: number): Observable<any> => {
        const url = `${this.tpaDischargeInvoiceUrl}?patientId=${patientId}&&encounterId=${encounterId}&&patientTypeId=${patientTypeId}`;
        return this._http.post<any>(url, null);
    }

    getTpaInvoiceErrorInfo = (invoiceId: number): Observable<TpaInvoiceErrorInfo> => {
        const url = `${this.tpaInvoiceErrorInfoUrl}?invoiceId=${invoiceId}`;

        return this._http.get<any>(url);
    }

    getTpaNotMappedItem = (invoiceId: number): Observable<TpaInvoiceNotMappedItem[]> => {
        const url = `${this.tpaNotMappedItemUrl}?invoiceId=${invoiceId}`;

        return this._http.get<any>(url);
    }
}
