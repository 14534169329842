import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

    constructor() { }

    public isLoading = new Subject<boolean>();
    private _requestsCount: number = 0;
    get requestsList() {
        return this._requestsCount;
    }
    set requestsList(value) {
        const totalRequests = this._requestsCount;
        this._requestsCount = value;
        if (totalRequests == 0 && value > 0) {
            document.body.classList.add("disable-items");
            this.isLoading.next(true);
        }
        if (totalRequests > 0 && value <= 0) {
            this.isLoading.next(false);
            document.body.classList.remove("disable-items");
            if (document.body.classList.contains("disable-loader")) {
                document.body.classList.remove("disable-loader");
            }
        }
    }

}

