import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../infrastructure/serviceBase.component';
import { TransAction } from '../../models/orders-and-billing/trans-action';
@Injectable()
export class TransActionService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private putUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.transaction.url;
        this.putUrl = this.actionUrl + _configuration.transaction.url;
    }

    public get = (): Observable<TransAction[]> => {
        return this._http.get<any>(this.getUrl);
    }

    public put(data: any) {
        return this._http.put<any>(this.putUrl, JSON.stringify(data));
    }
}