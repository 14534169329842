import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

// import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { AppModule } from 'app/app.module';
import { throwError, EMPTY } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { SystemSetting } from 'app/shared/models/enum/system-setting';
import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { LoaderService } from 'app/loader/_services/loader.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    private _systemSettingsService: SystemSettingsService;

    authService: AuthService;
    systemSetting

    constructor(private _loaderService: LoaderService) {
        this.authService = AppModule.injector.get(AuthService);
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);

    }

    setHeaaderWithoutCredential(request, tokenType, responseType) {
        return request.clone({
            setHeaders: {
                'Authorization': tokenType,
                'Accept': "application/json",
                'Content-Type': 'application/json',
                'Accept-Language': 'en',
            }, responseType: responseType
        });
    }

    setDimensionHeader(request, responseType) {
        return request.clone({
            setHeaders: {
                'Accept': "application/json",
                'Content-Type': 'application/json',
                'username': this._systemSettingsService.getAppConstantByName(SystemSetting.DimensionUsername),
                'password': this._systemSettingsService.getAppConstantByName(SystemSetting.DimensionPassword),
            }, withCredentials: true, responseType: responseType
        });
    }

    setDimensionAttachementHeader(request) {
        return request.clone({
            setHeaders: {
                'Accept': "text/plain",
                'username': this._systemSettingsService.getAppConstantByName(SystemSetting.DimensionUsername),
                'password': this._systemSettingsService.getAppConstantByName(SystemSetting.DimensionPassword),
            }, withCredentials: true
        });
    }

    setHISHeader(request, tokenType, responseType) {
        return request.clone({
            setHeaders: {
                'Authorization': tokenType,
                'Accept': "application/json",
                'Content-Type': 'application/json',
                'Accept-Language': 'en',
            }, withCredentials: true, responseType: responseType
        });
    }

    setMockHISHeader(request, responseType) {
        return request.clone({
            setHeaders: {
                'Accept': "application/json",
                'Content-Type': 'application/json',
                'Accept-Language': 'en',
            }, withCredentials: true, responseType: responseType
        });
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler) {
        let tokenType = environment?.useAuthService ? this.authService.getTokenType() : null;

        // Check if token is ready
        if (!request.url.includes('/assets/')) {

            if (environment?.useAuthService) {
                if (tokenType === null || tokenType.match(/^ *$/) !== null) {
                    return EMPTY;
                }
            }
        }


        let isAppointment = false;
        let isSilent = false;
        // Validate response type and handle spinner loader
        let responseType: any = request.responseType;
        if (responseType == 'silent' as 'json' || responseType == 'noCredential' as 'json') {
            if (responseType == 'noCredential' as 'json') {
                isAppointment = true;
                document.body.classList.add("disable-loader");
            } else if (responseType == 'silent' as 'json') {
                isSilent = true;
                document.body.classList.add("silent-loader");
            }
            responseType = 'json';
        } else {

            document.body.classList.add("disable-items");
        }

        // Check Repsponse Type and set header
        let isDimension = false;
        let isDimensionAttachement = false;

        if (responseType == 'dimension' as 'json') {
            isDimension = true;
            responseType = 'json';
        }

        if (responseType == 'dimensionAttachement' as 'json') {
            isDimensionAttachement = true;
            responseType = 'json';
        }

        if (responseType == 'appointment' as 'json') {
            isAppointment = true;
            responseType = 'json';
        }
        if (isDimension) {
            request = this.setDimensionHeader(request, responseType);
        } else if (isDimensionAttachement) {
            request = this.setDimensionAttachementHeader(request);
        } else if (tokenType) {
            if (isAppointment || isSilent) {
                request = this.setHeaaderWithoutCredential(request, tokenType, responseType);
            } else {
                request = this.setHISHeader(request, tokenType, responseType);
            }
        } else {
            request = this.setMockHISHeader(request, responseType);
        }

        // extend server response observable with logging
        return this.onHandleRequest(next, request, isSilent);


    }

    onHandleRequest(next, request, isSilent) {
        if(!isSilent){
            this._loaderService.requestsList++;
        }
        return next.handle(request)
            .pipe(
                tap(
                    // Succeeds when there is a response; ignore other events
                    event => {

                    },
                    // Operation failed; error is an HttpErrorResponse
                    error => {
                        if (error.status === 401) {
                            this.authService = AppModule.injector.get(AuthService);
                            this.authService.signinSilent();
                            //this._auth.signOut();
                            return throwError('Please wait for sign in...');
                        } else {
                            return throwError(error.statusText);
                        }
                    }
                ),
                // Log when response observable either completes or errors
                finalize(() => {
                    if(isSilent){
                        document.body.classList.remove('silent-loader')
                    }
                    else {
                        this._loaderService.requestsList--;
                    }
                })
            );
    }
}