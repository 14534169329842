import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { Speciality } from 'app/shared/models/core/Speciality';

@Injectable()
export class SpecialityService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getAllUrl: string;
    private _getAllActiveUrl: string;


    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.speciality.url;
        this._getAllUrl = this.actionUrl + _configuration.speciality.getAll;
        this._getAllActiveUrl = this.actionUrl + _configuration.speciality.getAllActive
    }

    getAll = (): Observable<Speciality[]> => {
        return this._http.get<any>(this._getAllUrl);
    }
    getAllActive = (): Observable<Speciality[]> => {
        return this._http.get<any>(this._getAllActiveUrl);
    }

    update(data: Speciality) {
        return this._http.put<any>(this._url, data);
    }

    Add(data: Speciality) {
        return this._http.post<any>(this._url, data);
    }
}