import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';


export class RangeValidator {

  public static validate = (range: Array<number>): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: any} => {
      if (control.value == null || control.value.length === 0) {
        return null;  // don't validate empty values to allow optional controls
      }
      const value = parseFloat(control.value);
      return !isNaN(value) && value >= range[0] && value <= range[1] ? null : { range: true };
    };
  }
}
