import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { ServiceBase } from 'app/shared/infrastructure';
import { HttpClient } from '@angular/common/http';
import { MedispanServiceApiConfiguration } from '../medispan-service-api-configuration';
import { Observable } from 'rxjs-compat/Observable';

import { AllergySeverity } from 'app/shared/models/medispan/allergy-severity';

@Injectable()
export class AllergySeverityService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getAllUrl: string;

    constructor(private _http: HttpClient, private _configuration: MedispanServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.allergySeverity.url;
        this.getAllUrl = this.actionUrl + _configuration.allergySeverity.getAll;
    }

    getAll = (): Observable<AllergySeverity[]> => {
        return this._http.get<any>(this.getAllUrl)
    }

    add(allergySeverity: AllergySeverity) {
        return this._http.post<any>(this.url, JSON.stringify(allergySeverity));
    }

    update(allergySeverity: AllergySeverity) {
        return this._http.put<any>(this.url, JSON.stringify(allergySeverity));
    }
}
