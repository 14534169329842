import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

import { CurrencyLog } from 'app/shared/models/orders-and-billing/currency-log';

@Injectable()
export class CurrencyLogService extends ServiceBase {

    private actionUrl: string;
    private url: string;

    constructor(private http: HttpClient, private configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = configuration.server;
        this.url = this.actionUrl + configuration.currencyLog.url;
    }

    add = (data: CurrencyLog): Observable<number> => {
        return this.http.post<any>(this.url, JSON.stringify(data));
    }
}
