import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { DosesTime } from '../../../models/pharmacy/doses-time';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class DosesTimeService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;
    private _getAllUrl: string;
    private _getByFrequencyIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._url = this._actionUrl + _configuration.dosesTime.url;
        this._getAllUrl = this._actionUrl + _configuration.dosesTime.getAllUrl;
        this._getByFrequencyIdUrl = this._actionUrl + _configuration.dosesTime.getByFrequencyIdUrl;
    }

    getAll = (): Observable<DosesTime[]> => {
        return this._http.get<any>(this._getAllUrl)
    }

    getDosesByFrequencyId = (frequencyId): Observable<DosesTime[]> => {
        return this._http.get<any>(this._getByFrequencyIdUrl + '?frequencyId=' + frequencyId)
    }

    insert = (data): Observable<any> => {
        return this._http.post<any>(this._url, data)
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data)

    }

    delete = (id): Observable<any> => {
        return this._http.delete(this._url + "?id=" + id)
    }
}
