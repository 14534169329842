import {
    FacilityService,
    CoreApiConfiguration,
    LookUpService,
    PatientService,
    DiagnoseService,
    CodeService,
    MedicalCategoryService,
    DoctorClinicService,
    PatientVisitService,
    FileRequestService,
    PatientKinService,
    NameService,
    PatientDocumentService,
    AllergyService,
    AllergyTypeService,
    PatientAllergyService,
    PatientDiagnosisService,
    UserSectionService,
    MainStaffService,
    FacilityUnitService,
    PBLSetupService,
    ErAreaService,
    PatientEncounterProblemService,
    OrganizationSectionService,
    DiseaseCodeService,
    ReportService,
    StoreService,
    TimelineHistoryService,
    PatientVitalSignService,
    PatientRiskFactorService,
    CarePlanShortcutService,
    PatientDietService,
    SpecialityService,
    FavoritePageStaffService,
    SystemAllergyService,
    PatientInfectiousService,
    MedicalFileSetupService,
    ExecuteQueryService,
    NotificationRoleService
} from './services/core';

export const NGA_CORE_SERVICES = [
    FacilityService,
    CoreApiConfiguration,
    LookUpService,
    PatientService,
    DiagnoseService,
    CodeService,
    MedicalCategoryService,
    DoctorClinicService,
    PatientVisitService,
    FileRequestService,
    PatientKinService,
    NameService,
    PatientDocumentService,
    AllergyService,
    AllergyTypeService,
    PatientAllergyService,
    PatientDiagnosisService,
    UserSectionService,
    MainStaffService,
    FacilityUnitService,
    PBLSetupService,
    PatientEncounterProblemService,
    OrganizationSectionService,
    DiseaseCodeService,
    ReportService,
    StoreService,
    TimelineHistoryService,
    PatientVitalSignService,
    PatientRiskFactorService,
    CarePlanShortcutService,
    PatientDietService,
    SpecialityService,
    FavoritePageStaffService,
    SystemAllergyService,
    ErAreaService,
    PatientInfectiousService,
    MedicalFileSetupService,
    ExecuteQueryService,
    NotificationRoleService
];
