import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { catchError } from 'rxjs/operators';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { PatientBasicSearchFilter } from '../../../models/queryModel';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { Patient } from 'app/shared/models/core/patient.model';
import { of } from 'rxjs/observable/of';

@Injectable()
export class PatientBasicSearchService extends ServiceBase {

    private actionUrl: string;
    private _get: string;
    private _action: string;

    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._get = this.actionUrl + _configuration.patientBasicSearch.url;
    }

    public getSearchResult = (data: PatientBasicSearchFilter, checkStatus: boolean): Observable<Patient[]> => {
        let queryString: string = '';
        queryString += '?patientCode=';
        if (data.patientCode)
            queryString += data.patientCode;

        queryString += '&patientName=';
        if (data.patientName)
            queryString += data.patientName;

        queryString += '&nationalID=';
        if (data.nationalID)
            queryString += data.nationalID;

        queryString += '&phoneNumber=';
        if (data.phoneNumber)
            queryString += data.phoneNumber;

        queryString += '&registrationDate=';
        if (data.registrationDate)
            queryString += data.registrationDate.toLocaleDateString();

        queryString += '&checkStatus=' + checkStatus;
        return this._http.get<any>(this._get + queryString);
    }

    doSearchbyCode(codes: Observable<any>, debounceMs = 400, checkStatus: boolean = true) {
        return codes
            .pipe(
                debounceTime(debounceMs),
                distinctUntilChanged(),
                switchMap(code => this.rawSearchByCode(code, checkStatus)),
            );
    }

    rawSearchByCode(code, checkStatus): Observable<any> {
        if (code == '') {
            return of('');
        }
        let queryString: string = '';
        queryString += '?patientCode=';
        queryString += code;
        queryString += '&patientName=';
        queryString += '&nationalID=';
        queryString += '&phoneNumber=';
        queryString += '&registrationDate=';


        queryString += '&checkStatus=' + checkStatus;

        return this._http
            .get(this._get + queryString)
            .pipe(
                catchError(this.handleError),
            )
    }

}
