import {
    ControlApiConfiguration,
    UserService,
    RoleService,
    RolePermissionService,
    UserRoleService,
    SecurityFacilityService,
    PermissionService,
    PasswordPolicyService,
    AuthorizeService,

} from './services/control';

export const NGA_CONTROL_SERVICES = [
    ControlApiConfiguration,
    UserService,
    RoleService,
    RolePermissionService,
    UserRoleService,
    SecurityFacilityService,
    PermissionService,
    PasswordPolicyService,
    AuthorizeService,
];