import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PagesMenu } from 'app/pages/pages.menu-v2';
import { GlobalState } from 'app/global.state';

@Component({
    selector: 'ba-main-menu-item',
    styleUrls: ['baMainMenuItem.component.scss'],
    templateUrl: './baMainMenuItem.html',
})

export class baMainMenuItem implements OnInit {
    menus: any[];


    @Input() set menuChildren(value: any) {
        if (value) {
            this.menus = [];
            this.menus = value;
        }
    }


    constructor(private _router: Router, private _state: GlobalState) {
    }

    ngOnInit() {
        if (!this.menus) {
            this.menus = [] = PagesMenu;
        }

    }

    goto(event, item) {
        if (item.lastChild && !item.url) {
            localStorage.setItem('HomeItems', JSON.stringify(item));
            this._router.navigateByUrl('/pages/home/' + item.id);
            this._state.notifyDataChanged('menu.isCollapsed', true);
        } else if (item.url) {
            this._router.navigateByUrl(item.url);
            this._state.notifyDataChanged('menu.isCollapsed', true);
        } else {
            this.viewDetails(event, item);
            this._state.notifyDataChanged('menu.isCollapsed', false);
        }
    }

    viewDetails(event, item) {

        const menuElement = jQuery('#menus');
        const element = menuElement.find('.' + item.path + item.id);
        if (item.isMain) {
            if (element.hasClass('active-sub')) {

                if (jQuery('.ng2').hasClass('menu-collapsed')) {
                    this._state.notifyDataChanged('menu.isCollapsed', false);
                } else {
                    menuElement.find('.collapsed').removeClass('in');
                    menuElement.find('.arow-item').removeClass('active-arrow');
                    menuElement.find('.active-sub').removeClass('active-sub')
                }
                return;
            }
            menuElement.find('.collapsed').removeClass('in');
            menuElement.find('.arow-item').removeClass('active-arrow');
            menuElement.find('.active-sub').removeClass('active-sub')
        } else if (item.children) {

            let otherElements = menuElement.find('.sub-menu-child').not('.' + item.path + item.id);
            otherElements.removeClass('active-sub');
            otherElements.find('.collapsed').removeClass('in');
            otherElements.find('.arow-item').removeClass('active-arrow');

            if (element.hasClass('active-sub')) {

                if (jQuery('.ng2').hasClass('menu-collapsed')) {
                    this._state.notifyDataChanged('menu.isCollapsed', false);
                } else {
                    element.find('.sub-menu-child').removeClass('active-sub');
                    element.find('.collapsed').first().removeClass('in');
                    element.removeClass('active-sub');
                    element.find('.arow-item').first().removeClass('active-arrow');
                }
                return;
            }
            element.find('.collapsed').addClass('in');
            element.find('.arow-item').addClass('active-arrow');
            element.find('.active-sub').addClass('active-sub')
            return;
        } else {
            this.removeSelectedSub(item, menuElement);
        }

        element.find('.sub-menu-child').removeClass('active-sub');

        element.find('.collapsed').first().addClass('in');
        element.addClass('active-sub');
        element.find('.arow-item').first().addClass('active-arrow');
    }

    removeSelectedSub(item, menuElement) {
        const element = jQuery('.' + item.path + item.id + '.sub-menu-child');
        if (element) {
            const subChildItem = element.parent().find('.sub-menu-child');
            if (subChildItem.length > 0) {
                var i = 0;
                while (i < subChildItem.length) {
                    const subChildClasses = subChildItem[i].classList;
                    var j = 0;
                    while (j < subChildClasses.length) {
                        const selectedItem: string = item.path + item.id;
                        if (subChildClasses[j].toString() != selectedItem.toString()) {
                            if (subChildClasses[j].toString() == 'sub-menu-child' || subChildClasses[j].toString() == 'active-sub') {

                            } else {
                                menuElement.find('.' + subChildClasses[j]).find('.active-sub').removeClass('active-sub')
                                menuElement.find('.' + subChildClasses[j]).find('.collapsed').removeClass('in');
                                menuElement.find('.' + subChildClasses[j]).find('.arow-item').removeClass('active-arrow');
                            }
                        }
                        j++
                    }
                    i++
                }
            }
        }
    }
}
