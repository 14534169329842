import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class EncounterNormalResultService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getByEncounter: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.normalResult.url;
        this._getByEncounter = this.actionUrl + _configuration.normalResult.encounterNormalResult;
    }

    getByEncounter = (encounterId, patientType, patientId): Observable<any> => {


        let queryString: string = '';
        queryString += '?encounterId=';
        queryString += encounterId;

        queryString += '&patientTypeId=';
        queryString += patientType;

        queryString += '&patientId=';
        queryString += patientId;


        return this._http.get<any>(this._getByEncounter + queryString);
    }
}
