import { Injectable } from '@angular/core';
import { SpecimenTransactionViewModel } from '../models/lab/specimenTransactionViewModel';
import { SpecimenViewModel } from '../models/lab/specimenViewModel';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { SignalRConfiguration } from './signalr-configuration.service';

@Injectable()
export class SpecimentTransactionSignalRService {

    private connection: SignalR.Hub.Connection;

    private proxy: SignalR.Hub.Proxy;

    constructor(private signalRConfiguration: SignalRConfiguration) {

        this.connection = $.hubConnection(this.signalRConfiguration.labApiServer);
        this.connection.logging = true;
        this.proxy = this.connection.createHubProxy('collectSpecimenHub');

        // This is a dummy handler, signalr won't work without at least one handler
        this.proxy.on('newUpdate', data => {
            console.log(data);
        });

        this.connection.start({ jsonp: true })
            .done(connection => {
                console.log('Now connected, connection ID=' + connection.id);
            })
            .fail(() => { console.log('Could not connect'); });
    }

    getLatestCollectedSpecimen(specimenTransactionViewModel: SpecimenTransactionViewModel): Observable<any> {
        let orderFromString: string = '';
        let orderToString: string = '';

        if (specimenTransactionViewModel.OrderFrom) {
            specimenTransactionViewModel.OrderFrom = new Date(specimenTransactionViewModel.OrderFrom);
            orderFromString = specimenTransactionViewModel.OrderFrom.toISOString();
        }

        if (specimenTransactionViewModel.OrderTo) {
            specimenTransactionViewModel.OrderTo = new Date(specimenTransactionViewModel.OrderTo);
            orderToString = specimenTransactionViewModel.OrderTo.toISOString();
        }

        return Observable.create(observer => {
            this.proxy.invoke("getLatestCollectedSpecimen",
                specimenTransactionViewModel.SpecimenNumber,
                specimenTransactionViewModel.SpecimenType,
                specimenTransactionViewModel.Status,
                specimenTransactionViewModel.LabSection,
                orderFromString,
                orderToString,
                specimenTransactionViewModel.OrderNumber,
                specimenTransactionViewModel.PatientCode,
                specimenTransactionViewModel.AccessionNumber)
                .done(data => {
                    observer.next(data);
                    observer.complete();
                })
                .fail(error => {
                    observer.throw(error || 'backend server error');
                });
        });

    }

    getLatestSpecimen(specimenViewModel: SpecimenViewModel): Observable<any> {
        let orderDateString: string = '';

        if (specimenViewModel.OrderDate) {
            orderDateString = specimenViewModel.OrderDate.toDateString();
        }

        return Observable.create(observer => {
            this.proxy.invoke("getLatestSpecimen",
                specimenViewModel.SpecimenNumber,
                specimenViewModel.SpecimenType,
                specimenViewModel.Status,
                specimenViewModel.PatientCode,
                specimenViewModel.PatientName,
                specimenViewModel.PatientSection,
                specimenViewModel.OrderDoctor,
                specimenViewModel.OrderNumber,
                orderDateString)
                .done(data => {
                    observer.next(data);
                    observer.complete();
                })
                .fail(error => {
                    observer.throw(error || 'backend server error');
                });
        });

    }
}
