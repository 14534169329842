import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable';
import { AppointmentServiceApiConfiguration } from '../appointmentServiceApiConfiguration';
import { ServiceBase } from '../../../../shared/infrastructure/serviceBase.component';
import { AppointmentVisitViewModel } from 'app/shared/models/appointment/appointment-visit-view-model';
import { PagedResult } from 'app/shared/models/appointment/paged-result';
import { AppointmentVisitSearchFilter } from 'app/shared/models/appointment/appointment-search-filter';

@Injectable()
export class AppointmentService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _opdSearchUrl: string;
    private _cancelAppointmentUrl: string;
    private _getPatientDataByIdUrl: string;
    private _getVisitDataByIdUrl: string;

    constructor(private _http: HttpClient, private _configuration: AppointmentServiceApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + this._configuration.appointment.url;
        this._opdSearchUrl = this.actionUrl + _configuration.appointment.visitSearch;
        this._cancelAppointmentUrl = this.actionUrl + _configuration.appointment.cancelAppointment;
        this._getPatientDataByIdUrl = this.actionUrl + _configuration.appointment.getPatientDataById;
        this._getVisitDataByIdUrl = this.actionUrl + _configuration.appointment.getVisitDataById;
    }

    getOpdAppointment = (filter: AppointmentVisitSearchFilter): Observable<any> => {

        let queryString: string = '';
        queryString += '?AppointmentDate=';
        queryString += filter.AppointmentDate ? filter.AppointmentDate.toLocaleDateString() : '';

        queryString += '&PageIndex=';
        queryString += filter.PageIndex;

        queryString += '&PageSize=';
        queryString += filter.PageSize;

        queryString += '&PatientCode=';
        if (filter.PatientCode) {
            queryString += filter.PatientCode;
        }

        queryString += '&TeleCode=';
        if (filter.TeleCode) {
            queryString += filter.TeleCode;
        }

        queryString += '&DoctorId=';
        if (filter.DoctorId) {
            queryString += filter.DoctorId;
        }

        queryString += '&NationalCode=';
        if (filter.NationalCode) {
            queryString += filter.NationalCode;
        }

        queryString += '&PatientName=';
        if (filter.PatientName) {
            queryString += filter.PatientName;
        }

        queryString += '&SectionId=';
        if (filter.SectionId) {
            queryString += filter.SectionId;
        }

        queryString += '&StatusId=';
        if (filter.StatusId) {
            queryString += filter.StatusId;
        }

        return this._http.get<any>(`${this._opdSearchUrl}/${queryString}`, 
        { responseType: 'appointment' as 'json', withCredentials: false, })
    }

    cancelAppointment = (appointmentId: number, cancelReasonId: number): Observable<PagedResult<AppointmentVisitViewModel>> => {

        let queryString: string = '';
        queryString += '?id=';
        queryString += appointmentId;

        queryString += '&cancelReasonId=';
        queryString += cancelReasonId;

        return this._http.put<any>(`${this._cancelAppointmentUrl}/${queryString}`, null, { responseType: 'appointment' as 'json', withCredentials: false, })
    }

    getPatientDataById = (id: number): Observable<any> => {

        let queryString: string = '';
        queryString += '?id=';
        queryString += id;

        return this._http.get<any>(`${this._getPatientDataByIdUrl}/${queryString}`, { responseType: 'appointment' as 'json', withCredentials: false, });
    }

    getVisitDataById = (id: number): Observable<any> => {

        let queryString: string = '';
        queryString += '?id=';
        queryString += id;

        return this._http.get<any>(`${this._getVisitDataByIdUrl}/${queryString}`, { responseType: 'appointment' as 'json', withCredentials: false, });
    }
}
