import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Message } from 'primeng/api';
import 'style-loader!./advanceSearchAdmission.scss';
import { SearchPatientHasAdmissionService } from "../../services/queryModel/searchPatientHasAdmission";
import { LookUpService } from "../../services/core/lookup";
import { AdmissionSearchFilter } from "../../models/queryModel";
@Component({
    selector: 'advance-search-admission-cmp',
    templateUrl: './advanceSearchAdmission.html',
})

export class AdvanceSearchAdmissionComponent implements OnInit, OnDestroy {
    @Output() onSearch = new EventEmitter<any>();
    admissionSearchFilter: AdmissionSearchFilter = new AdmissionSearchFilter();

    sections: any;
    admissionDoctors: any;
    hasAdmission: boolean = false;
    busy: Subscription;
    searchResult: any;
    constructor(private _searchPatientHasAdmissionService: SearchPatientHasAdmissionService, private _lookUpService: LookUpService) {

    }
    ngOnInit() {
        this.getSections();
        this.getAdmissionDoctor();
    }


    getSections() {
        this._lookUpService.getAllMedicalCategorySection()
            .subscribe((data: any) => {
                this.sections = [];
                for (let i = 0; i < data.length; i++) {
                    this.sections.push({ label: data[i].Title, value: data[i].Id });
                }
            });
    }

    getAdmissionDoctor() {
        this._lookUpService.getDoctorStaff()
            .subscribe((data: any) => {
                this.admissionDoctors = [];
                for (let i = 0; i < data.length; i++) {
                    this.admissionDoctors.push({ label: data[i].Title, value: data[i].Id });
                } this.admissionDoctors.unshift({ label: "All Doctor", value: null })

            });

    }

    onSubmit() {
        this._searchPatientHasAdmissionService.getSearchResult(this.admissionSearchFilter)
            .subscribe((data) => {
                this.onSearch.emit({ admissionSearchResult: data });
            });

    }

    ngOnDestroy() {
    }

}
