import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-cmp',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  constructor() {

  }
}
