import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { SpecimenTransactionViewModel } from '../../../../shared/models/lab/specimenTransactionViewModel';
import { SpecimenTransactionChangeStatusViewModel } from '../../../models/lab/specimenTransactionChangeStatusViewModel';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class SpecimenTransactionStatusService extends ServiceBase {

    private actionUrl: string;
    private _changeToCollected: string;
    private _changeSpecimenTransactionStatus: string;
    private _patientReleaseUrl: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._changeToCollected = this.actionUrl + _configuration.specimenTransactionStatus.changeToCollected;
        this._changeSpecimenTransactionStatus = this.actionUrl + _configuration.specimenTransactionStatus.changeSpecimenTransactionStatus;
        this._patientReleaseUrl = this.actionUrl + _configuration.specimenTransactionStatus.patientRelease;
    }
    changeToCollected = (viewModel): Observable<any> => {
        return this._http.post<any>(this._changeToCollected, viewModel);
    }

    changeSpecimenTransactionStatus = (specimenTransactionChangeStatusViewModel: SpecimenTransactionChangeStatusViewModel): Observable<any> => {
        return this._http.post<any>(this._changeSpecimenTransactionStatus, specimenTransactionChangeStatusViewModel);
    }

    acceptSpecimen = (specimenTransactionChangeStatusViewModel: SpecimenTransactionChangeStatusViewModel): Observable<any> => {
        return this._http.patch(`${this.actionUrl}SpecimenTransactionStatus/Acceptance`, specimenTransactionChangeStatusViewModel);
    }


    patientRelease = (): Observable<any> => {
        return this._http.get<any>(`${this._patientReleaseUrl}`);
    }
}
