import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { SettlementDeductionSetup } from 'app/shared/models/orders-and-billing/settlement-deduction-setup';
import { AccountInfo } from 'app/shared/models/orders-and-billing/Account-info';
import { StringChain } from 'lodash';

@Injectable()
export class SettlementDeductionSetupService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private putUrl: string;
    private getAccountsUrl: string;
    private getActiveUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.settlementDeductionSetup.url;
        this.postUrl = this.actionUrl + _configuration.settlementDeductionSetup.url;
        this.putUrl = this.actionUrl + _configuration.settlementDeductionSetup.url;
        this.getAccountsUrl = this.actionUrl + _configuration.settlementDeductionSetup.getAccounts;
        this.getActiveUrl = this.actionUrl + _configuration.settlementDeductionSetup.getActive;
    }

    get = (): Observable<SettlementDeductionSetup[]> => {
        return this._http.get<any>(this.getUrl);
    }

    post = (data: SettlementDeductionSetup): Observable<any> => {
        return this._http.post<any>(this.postUrl, JSON.stringify(data));
    }

    put = (data: SettlementDeductionSetup): Observable<any> => {
        return this._http.put<any>(this.putUrl, JSON.stringify(data));
    }

    getAccounts = (accountName): Observable<AccountInfo[]> => {
        return this._http.get<any>(`${this.getAccountsUrl}?accountName=${accountName}`);
    }

    getActive = (): Observable<SettlementDeductionSetup[]> => {
        return this._http.get<any>(`${this.getActiveUrl}`);
    }
}
