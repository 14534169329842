import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs-compat/Observable'
import { LabApiConfiguration } from '../apiConfiguration';
import { SensitiveMicrobiologyAnti } from '../../../models/lab/sensitiveMicrobiologyAnti';
import { ServiceBase } from "app/shared/infrastructure";

@Injectable()
export class SensitiveMicroBiologyAntiService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _checkSensitiveMicrobiologyAntis: string;
    private _insertAndGetSensitiveMicrobiologyAntis: string;

    constructor(private _http: HttpClient, private _configuration: LabApiConfiguration, private _router: Router) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.sensitiveMicrobiologyAnti.url;
        this._checkSensitiveMicrobiologyAntis = this.actionUrl + _configuration.sensitiveMicrobiologyAnti.checkSensitiveMicrobiologyAntis;
        this._insertAndGetSensitiveMicrobiologyAntis = this.actionUrl + _configuration.sensitiveMicrobiologyAnti.insertAndGetSensitiveMicrobiologyAntis;
    }

    getByMicroBiologyPanelIdAntibioticId = (sensitiveMicrobiologyId: number, microBiologyPanelId: number, antibioticId: number): Observable<SensitiveMicrobiologyAnti> => {
        const url = `${this._url}?sensitiveMicrobiologyId=${sensitiveMicrobiologyId}&&microBiologyPanelId=${microBiologyPanelId}&&antibioticId=${antibioticId}`;
        return this._http.get<any>(url);
    }

    checkSensitiveMicrobiologyAntis = (sensitiveMicrobiologyId: number, microBiologyPanelId: number): Observable<SensitiveMicrobiologyAnti[]> => {
        const url = `${this._checkSensitiveMicrobiologyAntis}?sensitiveMicrobiologyId=${sensitiveMicrobiologyId}&&microBiologyPanelId=${microBiologyPanelId}`;
        return this._http.get<any>(url);
    }

    insert = (data: any): Observable<SensitiveMicrobiologyAnti> => {
        return this._http.post<any>(this._url, JSON.stringify(data));
    }

    insertAndGetSensitiveMicrobiologyAntis = (sensitiveMicrobiologyId: number, microBiologyPanelId: number): Observable<SensitiveMicrobiologyAnti[]> => {
        const url = `${this._insertAndGetSensitiveMicrobiologyAntis}?sensitiveMicrobiologyId=${sensitiveMicrobiologyId}&&microBiologyPanelId=${microBiologyPanelId}`;
        return this._http.post<any>(url, null);
    }

    update = (data: any): Observable<any> => {
        return this._http.put<any>(this._url, JSON.stringify(data));
    }

    remove = (id: number): Observable<any> => {
        const url = `${this._url}?id=${id}`;
        return this._http.delete(url);
    }
}