import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class FavoriteListService extends ServiceBase {

    private actionUrl: string;
    private _url: string;
    private _getById: string;
    private _getByUser: string;
    private _addList: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.favoriteList.url;
        this._getById = this.actionUrl + _configuration.favoriteList.getById;
        this._getByUser = this.actionUrl + _configuration.favoriteList.getByUser;
        this._addList = this.actionUrl + _configuration.favoriteList.addList;
    }

    public getById = (id: number): Observable<any> => {
        return this._http.get<any>(this.getById + '?id=' + id);
    }

    public getByUserId = (): Observable<any> => {
        return this._http.get<any>(this._getByUser);
    }

    public addList = (data: any): Observable<any> => {
        return this._http.post<any>(this._addList, data);
    }
}