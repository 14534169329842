export class PatientBasicSearchFilter {

    patientCode: string;
    patientName: string;
    nationalID: number;
    phoneNumber: number;
    registrationDate: Date;
   
    constructor() { }
}
