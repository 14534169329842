<div class="row mx-0 py-2 border-bottom notification-title">
    <div class="col-sm-12 text-center p-0 fs-12 fw-500">
        <span class="fw-600" *ngIf="unReadNotificationCount > 0"><span>{{unReadNotificationCount}}</span> New Notifications</span>
        <span class="fw-600" *ngIf="unReadNotificationCount <= 0 || !unReadNotificationCount"><b>Notifications</b></span>
    </div>
</div>
<div class="row mx-0 notification-container">
    <div class="col-sm-12 p-0">
        <a class="item-notification" (click)="openNote(item);" [ngClass]="{'un-read':!item.markAsRead}"
           *ngFor="let item of notifications">
            <notifications-media-body-cmp class="flex-center space-between vertical full-width" [note]="item">
            </notifications-media-body-cmp>
        </a>
        <div class="inbox-empty" *ngIf="notifications?.length <= 0">Inbox is empty </div>
    </div>
</div>