import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { ClaimSettlement } from 'app/shared/models/orders-and-billing/claim-settlement';
import { ClaimSettlementFilter } from 'app/shared/models/orders-and-billing/claim-settlement-filter';
import { SettlementSummary } from 'app/shared/models/orders-and-billing/settlement-summary';
import { SettlementVerifyResult } from 'app/shared/models/orders-and-billing/settlement-verify-result';

@Injectable()
export class ClaimSettlementService extends ServiceBase {

    private actionUrl: string;
    _allSettlement: string;
    _PayerSettlementsByRecieptNoSearch: string;
    _PayerSettlement: string;
    _changeStatus: string;
    _payerSettlementsByPayerSearch: string;
    _payerSettlementsByStatusSearch: string;
    _payerSettlementSummary: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._allSettlement = `${this.actionUrl}${_configuration.payerSettlement.allSettlement}`;
        this._PayerSettlementsByRecieptNoSearch = `${this.actionUrl}${_configuration.payerSettlement.PayerSettlementsByRecieptNoSearch}`;
        this._PayerSettlement = `${this.actionUrl}${_configuration.payerSettlement.PayerSettlement}`;
        this._changeStatus = `${this.actionUrl}${_configuration.payerSettlement.changeStatus}`;
        this._payerSettlementsByStatusSearch = `${this.actionUrl}${_configuration.payerSettlement.payerSettlementsByPayerSearch}`;
        this._payerSettlementSummary = `${this.actionUrl}${_configuration.payerSettlement.SettlementSummary}`;
    }

    public getAllSettlement = (data: ClaimSettlementFilter): Observable<any> => {
        const queryString = `?page=${data.page}&settlementId=${data.settlementId ? data.settlementId : null}&payerId=${data.payerId ? data.payerId : null}&receiptId=${data.receiptId ? data.receiptId : null}&status=${data.status ? data.status : null}&settlementDate=${data.settlementDate ? data.settlementDate.toISOString() : null}`;
        let url = `${this._allSettlement}${queryString}`;
        return this._http.get<any>(url);
    }

    public getAllSettlementByPayerSettlementsByPayerSearch = (payerId: number): Observable<ClaimSettlement[]> => {
        let url = `${this._payerSettlementsByPayerSearch}?payerId=${payerId}`;
        return this._http.get<any>(url)
    }

    public getAllSettlementByPayerSettlementsByRecieptNoSearch = (receiptId: number): Observable<ClaimSettlement[]> => {
        let url = `${this._PayerSettlementsByRecieptNoSearch}?receiptId=${receiptId}`;
        return this._http.get<any>(url)
    }

    public getSummaryBySettlementId = (settlementId: number): Observable<SettlementSummary> => {
        let url = `${this._payerSettlementSummary}?settlementId=${settlementId}`;
        return this._http.get<any>(url)
    }

    public addSettlement = (data: ClaimSettlement): Observable<any> => {
        return this._http.post<any>(this._PayerSettlement, data);
    }

    public editSettlement = (data: ClaimSettlement): Observable<any> => {
        return this._http.put<any>(this._PayerSettlement, data);
    }

    public changeStatus = (payerSettlementId: number, oldStatus: number, newStatus: number): Observable<ClaimSettlement[]> => {
        const data = {
            PayerSettlementId: payerSettlementId,
            OldStatus: oldStatus,
            NewStatus: newStatus
        }
        return this._http.post<any>(this._changeStatus, data)
    }

    public getSettlement = (settlementId: number): Observable<ClaimSettlement> => {

        const queryString = `?settlementId=${settlementId}`;
        let url = `${this._PayerSettlement}${queryString}`;
        return this._http.get<any>(url)
    }

    public verify = (settlementId: number): Observable<SettlementVerifyResult> => {
        let url = `${this._PayerSettlement}/Verification?settlementId=${settlementId}`;
        return this._http.post<any>(url, settlementId)
    }
}
