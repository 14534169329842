import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { PatientEncounterInsuranceViewModel } from 'app/shared/models/orders-and-billing/patient-encounter-insurance-view-model';

@Injectable()
export class PatientEncounterInsuranceService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private _getByFillter: string;
    private _getByEncounter: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.patientEncounterInsurance.url;
        this._getByFillter = this.actionUrl + _configuration.patientEncounterInsurance.getByFilter;
        this._getByEncounter = this.actionUrl + _configuration.patientEncounterInsurance.getByEncounter;
    }

    public getByFilter = (patientId, encounterId, payerId, PatientType): Observable<any> => {
        const query = `?patientId=${patientId}&&encounterId=${encounterId}&&payerId=${payerId}
        &&patientType=${PatientType}`;
        return this._http.get<any>(this._getByFillter + query);
    }

    public getByEncounter = (encountersId, patientTypeId): Observable<PatientEncounterInsuranceViewModel[]> => {
        const query = `?encountersId=${encountersId}&&patientTypeId=${patientTypeId}`;
        return this._http.get<any>(this._getByEncounter + query);
    }

}
