import { AbstractControl, ValidatorFn } from '@angular/forms';


export class IntegerValidator {

    public static validate: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
        if (control.value) {
            return /^\d+$/.test(control.value) ? null : { 'number': true };
        }
        else {
            return null;
        }
    };
}