import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { Router } from '@angular/router';

import { LabApiConfiguration } from '../apiConfiguration';
import { ResultList } from '../../../models/lab/resultList';
import { ServiceBase } from 'app/shared/infrastructure';

@Injectable()
export class ResultListService extends ServiceBase {

    private _actionUrl: string;
    private _url: string;

    constructor(private http: HttpClient,
        private configuration: LabApiConfiguration,
        private router: Router) {
        super();
        this._actionUrl = configuration.server;
        this._url = this._actionUrl + configuration.resultList.url;
    }

    getAll = (): Observable<ResultList[]> => {
        return this.http.get<any>(this._url)
    }

    insert = (data): Observable<any> => {
        return this.http.post<any>(this._url, data)
    }

    update = (data): Observable<any> => {
        return this.http.put<any>(this._url, data);
    }
}
