import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SystemSettingsService } from 'app/shared/services/system-setting/system-setting.service';
import { SystemSetting } from 'app/shared/models/enum/system-setting';
import { AppModule } from 'app/app.module';

@Injectable()
export class QueryModelApiConfiguration {

    private _systemSettingsService: SystemSettingsService;
    server: string;

    constructor() {
        this._systemSettingsService = AppModule.injector.get(SystemSettingsService);
        this.server = this._systemSettingsService.getAppConstantByName(SystemSetting.QueryModel);
    };

    patientBasicSearch = {
        url: 'PatientBasicSearch',
    };

    searchPatientHasAdmission = {
        url: 'SearchPatientHasAdmission',
    };

    todayCashBox = {
        url: 'TodayCashBoxSearch',
    };

    mainFund = {
        url: 'MainFundSearch',
    };

    admissionStationSearch = {
        url: 'AdmissionStationSearch',
    };

    patientReservationSearch = {
        url: 'PatientReservationSearch',
    };

    patientTransferSearch = {
        url: 'PatientTransferSearch',
    };

    PhysicianDesktopSearch = {
        url: 'PhysicianDesktopPatientSearch',
        inPatient: '/InPatient',
        outPatient: '/OutPatient',
        allInPatient: '/AllInPatient',
        allOutPatient: '/AllOutPatient',
    };

    patientDischargeSearch = {
        url: 'PatientDischargeSearch',
    };

    patientStatmentSearch = {
        url: 'PatientStatmentSearch',
    };

    patientOrderSearch = {
        url: 'PatientOrderSearch',
        orderCategories: 'PatientOrderSearch/OrderCategories',
    };

    pharmacyAntibioticSearch = {
        url: 'PharmacyAntibioticSearch',
    };

    pharmacyMedicationHistorySearch = {
        url: 'PharmacyMedicationHistory',
    };

    patientOpenAndHaltStatmentSearch = {
        url: 'PatientOpenAndHaltStatmentSearch',
    };

    calculateAccomodation = {
        url: 'CalculateAccomodationFees',
    }

    patientCalculateVisitTransfer = {
        url: 'PatientCalculateVisitTransfer',
    }

    sapControlSearch = {
        url: 'SapControlSearch'
    };


    cashBoxTransfer = {
        url: 'CashBoxTransfer',
    };

    mergedPatient = {
        url: 'MergedPatient',
    };

    pharmacyOrderSearch = {
        url: 'PharmacyOrderSearch'
    };

    DisclosureInvoice = {
        url: 'DisclosureInvoice'
    };

    hospitalPerformance = {
        url: 'HospitalPerformance',
        generalPerformance: 'HospitalPerformance/GeneralPerformance',
        dailyInPatientRevenuesByDealingType: 'HospitalPerformance/DailyInPatientRevenuesByDealingType',
        monthlyInPatientRevenuesByDealingType: 'HospitalPerformance/MonthlyInPatientRevenuesByDealingType',
        yearlyInPatientRevenuesByDealingType: 'HospitalPerformance/YearlyInPatientRevenuesByDealingType',
        dailyOutPatientRevenuesByDealingType: 'HospitalPerformance/DailyOutPatientRevenuesByDealingType',
        monthlyOutPatientRevenuesByDealingType: 'HospitalPerformance/MonthlyOutPatientRevenuesByDealingType',
        yearlyOutPatientRevenuesByDealingType: 'HospitalPerformance/YearlyOutPatientRevenuesByDealingType',
        dailyRevenuesBySection: 'HospitalPerformance/DailyRevenuesBySection',
        monthlyRevenuesBySection: 'HospitalPerformance/MonthlyRevenuesBySection',
        yearlyRevenuesBySection: 'HospitalPerformance/YearlyRevenuesBySection',
        dailyRevenuesByElement: 'HospitalPerformance/DailyRevenuesByElement',
        monthlyRevenuesByElement: 'HospitalPerformance/MonthlyRevenuesByElement',
        yearlyRevenuesByElement: 'HospitalPerformance/YearlyRevenuesByElement',
        csvDailyRevenuesBySection: 'HospitalPerformance/CsvDailyRevenuesBySection',
        csvMonthlyRevenuesBySection: 'HospitalPerformance/CsvMonthlyRevenuesBySection',
        csvYearlyRevenuesBySection: 'HospitalPerformance/CsvYearlyRevenuesBySection',
        csvDailyRevenuesByElement: 'HospitalPerformance/CsvDailyRevenuesByElement',
        csvMonthlyRevenuesByElement: 'HospitalPerformance/CsvMonthlyRevenuesByElement',
        csvYearlyRevenuesByElement: 'HospitalPerformance/CsvYearlyRevenuesByElement',

        dailyRadiologyRevenue: 'HospitalPerformance/DailyRadiologyOrder',
        monthlyRadiologyRevenue: 'HospitalPerformance/MonthlyRadiologyOrder',
        yearlyRadiologyRevenue: 'HospitalPerformance/YearlyRadiologyOrder',

        dailyLaboratoryRevenue: 'HospitalPerformance/DailyLaboratoryOrder',
        monthlyLaboratoryRevenue: 'HospitalPerformance/MonthlyLaboratoryOrder',
        yearlyLaboratoryRevenue: 'HospitalPerformance/YearlyLaboratoryOrder',

        dailyEmergencyRevenue: 'HospitalPerformance/DailyEmergencyVisit',
        monthlyEmergencyRevenue: 'HospitalPerformance/MonthlyEmergencyVisit',
        yearlyEmergencyRevenue: 'HospitalPerformance/YearlyEmergencyVisit',

        dailyRevenuesByDealingType: 'HospitalPerformance/DailyRevenuesByDealingType',
        monthlyRevenuesByDealingType: 'HospitalPerformance/MonthlyRevenuesByDealingType',
        yearlyRevenuesByDealingType: 'HospitalPerformance/YearlyRevenuesByDealingType',

    };

    HospitalIncome = {
        url: 'HospitalIncome'
    };

    patientVisitSearch = {
        url: 'PatientVisitSearch',
        patientNurses: 'PatientVisitSearch/GetPatientNurses'
    };

    revenue = {
        url: 'Revenue'
    };

    dashboardDoctorPerformance = {
        url: 'DoctorPerformance',
        dailyDoctorsPerformanceCounter: 'DoctorPerformance/DailyDoctorsPerformanceCounter',
        monthlyDoctorsPerformanceCounter: 'DoctorPerformance/MonthlyDoctorsPerformanceCounter',
        yearlyDoctorsPerformanceCounter: 'DoctorPerformance/YearlyDoctorsPerformanceCounter',
        dailyDoctorsPerformance: 'DoctorPerformance/DailyDoctorsPerformance',
        monthlyDoctorsPerformance: 'DoctorPerformance/MonthlyDoctorsPerformance',
        yearlyDoctorsPerformance: 'DoctorPerformance/YearlyDoctorsPerformance',
        csvDailyDoctorsPerformance: 'DoctorPerformance/CsvDailyDoctorsPerformance',
        csvMonthlyDoctorsPerformance: 'DoctorPerformance/CsvMonthlyDoctorsPerformance',
        csvYearlyDoctorsPerformance: 'DoctorPerformance/CsvYearlyDoctorsPerformance',
        dailyPerformanceBySection: 'DoctorPerformance/DailyDoctorsPerformanceBySection',
        monthlyPerformanceBySection: 'DoctorPerformance/MonthlyDoctorsPerformanceBySection',
        yearlyPerformanceBySection: 'DoctorPerformance/YearlyDoctorsPerformanceBySection',
        csvDailyPerformanceBySection: 'DoctorPerformance/CsvDailyDoctorsPerformanceBySection',
        csvMonthlyPerformanceBySection: 'DoctorPerformance/CsvMonthlyDoctorsPerformanceBySection',
        csvYearlyPerformanceBySection: 'DoctorPerformance/CsvYearlyDoctorsPerformanceBySection',
        selectedDoctorRevenue: 'DoctorPerformance/DoctorsRevenuePieByDoctor',
        selectedDoctorNumberofAdmissions: 'DoctorPerformance/TotalOfAdmissionsPieByDoctor',
        doctorsOrderedByRevenue: 'DoctorPerformance/DoctorsOrderedByRevenue',
    };

    invoice = {
        url: 'Invoice',
        tpaIssuedInvoice: 'Invoice/TpaIssuedInvoice',
        tpaUnIssuedInvoice: 'Invoice/TpaUnIssuedInvoice',
        tpaClaimInvoice: 'Invoice/TpaClaimInvoice',
        tpaDischargeInvoice: 'Invoice/TpaDischargeInvoice',
        tpaInvoiceErrorInfo: 'Invoice/TpaInvoiceErrorInfo',
        tpaNotMappedItem: 'Invoice/TpaNotMappedItem',
    };

    erpFailedTransaction = {
        url: 'ErpFailedTransaction',
        doctorFeesWithInvalidVendor: 'ErpFailedTransaction/DoctorFeesWithInvalidVendor',
        invoicesWithEmptyArAccount: 'ErpFailedTransaction/InvoicesWithEmptyArAccount',
        refreshVendorCode: 'ErpFailedTransaction/RefreshVendorCode',
        refreshArAccountNumber: 'ErpFailedTransaction/RefreshArAccountNumber',
    };

    doctorFeesStation = {
        url: 'DoctorFeesStation',
        doctorFeesStationbBySearch: 'DoctorFeesStation/DoctorFeesStationbBySearch',
        exportDoctorFees: 'DoctorFeesStation/ExportDoctorFees'
    };

    trackingSettlement = {
        url: 'TrackingSettlement',
        getTrackingSettlements: 'TrackingSettlement/GetTrackingSettlements',
    };
    consultationTypesDetail = {
        url: 'ConsultationTypesDetail',
        allByConsultationType: 'ConsultationTypesDetail/GetAllByConsultationType',
    };
}
