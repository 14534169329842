import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../order-api-configuration/app.constant.service';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { DoctorBalance } from 'app/shared/models/orders-and-billing/doctor-balance';

@Injectable()
export class DoctorBalanceService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private getDocrtorBalanceUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.doctorBalance;
       this.getDocrtorBalanceUrl= this.actionUrl + _configuration.doctorBalance.getDoctorBalance;
    }

    getDoctorBalance = (doctorId: number): Observable<DoctorBalance> => {
        return this._http.get<any>(`${this.getDocrtorBalanceUrl}?doctorId=${doctorId}`);
    }
}
