import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { PharmacyApiConfiguration } from '../apiConfiguration';
import { HospitalPharmacy } from '../../../models/pharmacy/hospitalPharmacy';
import { ServiceBase } from 'app/shared/infrastructure';
import { forkJoin } from 'rxjs';
import { OrderApiConfiguration } from 'app/shared/services/order-and-billing';

@Injectable()
export class HospitalPharmacySectionService extends ServiceBase {

    private actionUrl: string;
    private _url: string;

    constructor(private _http: HttpClient, private _configuration: PharmacyApiConfiguration, _orderApiConfiguration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.hospitalPharmacySection.url;
    }

    getAll = (): Observable<any> => {
        return this._http.get<any>(`${this._url}`);
    }
    get = (id: number): Observable<any> => {
        return this._http.get<any>(`${this._url}/${id}`);
    }

    insert = (id: number, data): Observable<any> => {
        return this._http.post<any>(`${this._url}/${id}`, data);
    }

    update = (data): Observable<any> => {
        return this._http.put<any>(this._url, data);
    }
}
