export class AdmissionStationSearchFilter {

    patientCode?: string;
    fromAdmissionDate?: Date;
    toAdmissionDate?: Date;
    fromDischargeDate?: Date;
    toDischargeDate?: Date;
    admissionDoctorId?: number;
    guarantorId?: number;
    admissionStatus?: number;
    pageIndex: number;

    patientFullName?: string;
    dealingType?: number;
    patientLocation?: string;
    admissionDoctorFullName?: string;
    providerName?: string;
    filterAdmissionStatus: number;

    admissionReasonId?: number;

    constructor() {
    }
}
