import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { UserSection } from '../../../models/core/user-section';
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
@Injectable()
export class UserSectionService extends ServiceBase {

    private actionUrl: string;
    private headers: Headers;
    private _url: string;
    private _getByStaffId: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.userSection.url;
        this._getByStaffId = this.actionUrl + _configuration.userSection.getByStaffId;
    }

    getAll = (): Observable<UserSection[]> => {
        return this._http.get<any>(this._url);
    }

    getByStaffId = (staffId: number): Observable<UserSection[]> => {
        return this._http.get<any>(this._getByStaffId + '?staffId=' + staffId);
    }

    insert = (data: UserSection): Observable<string> => {
        return this._http.post<any>(this._url, data);
    }

    update = (data: UserSection): Observable<string> => {
        return this._http.put<any>(this._url, data);
    }

    delete(id: number) {
        return this._http.delete(this._url + '?id=' + id);
    }
}
