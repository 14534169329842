import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DateRangeValidator {

    public static validate = (range: Array<Date>): ValidatorFn => {
        return (control: AbstractControl): {[key: string]: any} => {
            let v: Date = control.value;
            if (v) {
                return v >= range[0] && v > range[1] ? null : { range: true };
            }
            else {
                return null;
            }
        };
      }
}